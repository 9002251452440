import React from 'react';
import { Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import SearchAlerts from '../..';
import './styles.css';

const SearchAlertsContainer = (props) => {
  const intl = useIntl();

  return (
    <div className="search-alerts-container" data-testid="search-alerts-container">
      <SearchAlerts
        match={ props.match }
        intl={ intl }
        eventCategory={ props.eventCategory }
        active={ props.active }
        makeModel={ props.makeModel }
        seoMakeInfo={ props.seoMakeInfo }
        isBranded={ props.isBranded }
      />
    </div>
  );
};

export default SearchAlertsContainer;

SearchAlertsContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object
  }),
  eventCategory: PropTypes.string,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  active: PropTypes.shape({
    /** Selected condition */
    condition: PropTypes.oneOf(['new', 'used']),
    /** Selected engine type */
    engine: PropTypes.string,
    /** Selected seller type */
    forSale: PropTypes.oneOf(['dealer', 'owner']),
    /** Selected fuel types */
    fuelType: PropTypes.arrayOf(PropTypes.string),
    /** Selected hull materials */
    hullMaterial: PropTypes.arrayOf(PropTypes.string),
    /** Selected length range */
    length: PropTypes.shape({
      max: PropTypes.string,
      min: PropTypes.string
    }),
    /** Selected makes and models */
    makeModel: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    /** Selected types and classes */
    multiFacetedBoatTypeClass: PropTypes.shape({
      power: PropTypes.arrayOf(PropTypes.string),
      sail: PropTypes.arrayOf(PropTypes.string),
      unpowered: PropTypes.arrayOf(PropTypes.string)
    }),
    /** Selected radius: exact | number */
    radius: PropTypes.string,
    /** Selected sort: distance:asc, length:desc, etc. */
    sort: PropTypes.string,
    /** Selected subdivision initials */
    subdivision: PropTypes.string,
    /** Selected year range */
    year: PropTypes.shape({
      max: PropTypes.string,
      min: PropTypes.string
    })
  }),
  /** Available makes */
  makeModel: PropTypes.arrayOf(
    PropTypes.shape({
      /** Make formatted name */
      value: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      model: PropTypes.arrayOf(
        PropTypes.shape({
          /** Model formatted name */
          value: PropTypes.string.isRequired,
          count: PropTypes.number.isRequired
        })
      )
    })
  ),
  seoMakeInfo: PropTypes.shape({
    make: PropTypes.string.isRequired,
    seoMakeName: PropTypes.string.isRequired
  }),
  isBranded: PropTypes.bool
};
