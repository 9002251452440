import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
// import { getCurrentRoutes as rts } from '../../../../utils/language';

import { searchPathIsActive as boatsPathIsActive } from '../../../../utils/urlHelpers/boats';
import { searchPathIsActive as enginesPathIsActive } from '../../../../utils/urlHelpers/engines';

import './styles.css';
import {getRouteConstantsFromI18n} from '../../../../tppServices/translations/constants';
import { getMessages } from '../../../../tppServices/translations/messages';

class TypeChooser extends PureComponent {
  buildTab(tabData, index) {
    return tabData.isActive
      ? (<span key={index} className="tab active">{ tabData.text }</span>)
      : (<a key={index} href={tabData.route} className="tab">{ tabData.text }</a>);
  }

  render() {
    const routes = getRouteConstantsFromI18n();
    const messages = getMessages();
    const {
      intl: { formatMessage: t },
      url
    } = this.props;
    const tabs = [
      {
        isActive: boatsPathIsActive(url),
        text: t(messages.searchTypeBoats),
        route: routes.SEARCH_URL_ROOT
      },
      {
        isActive: enginesPathIsActive(url),
        text: t(messages.searchTypeEngines),
        route: routes.ENGINES_SEARCH_URL_ROOT
      }
    ];

    return (
      <div className="type-chooser">
        { tabs.map(this.buildTab) }
      </div>
    );
  }
}

TypeChooser.propTypes = {
  url: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(TypeChooser);
