import { calculateMonthlyPrice } from '../../../../utils/trident';
import { getConfig } from '../../../../config/portal';

import { listingPropTypes } from '../../../../utils/commonPropTypes';
import MiniListing from '../../../../components/MiniListing';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import './styles.css';
import {getCurrentLocale} from '../../../../utils/language';
import {getBoatConstantsFromI18n} from '../../../../tppServices/translations/constants';

const SponsoredListings = (props) => {
  const resolveMonthlyPrice = (listing) => {
    const loanAmount = parseFloat(listing.price?.type?.amount?.USD || 0);
    const termInMonths = loanAmount >= 50000 ? 240 : 180;

    return calculateMonthlyPrice(
      props?.tridentTeaserRate,
      termInMonths,
      loanAmount
    );
  };

  const renderSponsoredListings = () => {
    const { sponsoredListings, url } = props;
    const locale = getCurrentLocale();
    const supportsMonthlyPayment = get(
      getConfig(),
      `languages.${locale}.supports.monthlyPayment`,
      false
    );
    const { LISTING_SPONSORED } = getBoatConstantsFromI18n();
    return sponsoredListings.map((listing) => (
      <MiniListing
        key={listing.id}
        listing={listing}
        url={url}
        href={listing.href}
        listingType={LISTING_SPONSORED}
        monthlyPrice={resolveMonthlyPrice(listing)}
        supportsMonthlyPayment={supportsMonthlyPayment}
        isEngine={props.isEngine}
      />
    ));
  };

  const { sponsoredListings } = props;
  return (
    sponsoredListings.length > 0 && (
      <div className="sponsored-listings">
        <ul>{renderSponsoredListings()}</ul>
      </div>
    )
  );
};

SponsoredListings.propTypes = {
  sponsoredListings: PropTypes.arrayOf(listingPropTypes).isRequired,
  isLoading: PropTypes.bool,
  url: PropTypes.string,
  tridentTeaserRate: PropTypes.number,
  isEngine: PropTypes.bool
};

export default SponsoredListings;
