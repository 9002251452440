import get from 'lodash/get';
import keys from 'lodash/keys';
import identity from 'lodash/identity';
import concat from 'lodash/concat';
import map from 'lodash/map';
import reverse from 'lodash/reverse';
import pickBy from 'lodash/pickBy';

import { getType, getClass, translateMake, capitalizeString, unhyphenateUrlComponents } from '../../../utils/commonHelper';
import { handleLegacyAllType } from '../../../utils/classHelper';
import {
  getActiveParams,
  generateSearchPath
} from '../../../utils/urlHelpers/boats';
import { getPartySearchUrl } from '../../../utils/urlHelpers/party';

import { getConfig } from '../../../config/portal';
import { syncTranslationElements } from '../../../tppServices/translations/intlManager';

export const getBreadCrumb = (params, seoParams, ownerName = '', repName = '', seoMakeInfo = {}, config) => {
  // istanbul ignore next
  if (!config) {
    // TODO: remove this call when config is async
    config = getConfig();
  }

  const { formatMessage: t, messages } = syncTranslationElements();
  let seoElements = [];

  // Pick only active parameters that are not empty
  params = pickBy(params, identity);
  let active = getActiveParams(params);

  if (get(active, 'city')) {
    seoElements.push(setBreadCrumb('city', params));
    params.city = '';
  }
  if (get(active, 'subdivision')) {
    seoElements.push(setBreadCrumb('subdivision', params));
    params.subdivision = '';
  }
  if (get(active, 'region')) {
    seoElements.push(setBreadCrumb('region', params));
    params.region = '';
  }
  if (get(active, 'country')) {
    seoElements.push(setBreadCrumb('country', params));
    params.country = '';
  }
  if (get(active, 'worldRegion')) {
    seoElements.push(setBreadCrumb('worldRegion', params));
    params.worldRegion = '';
  }
  if (get(active, 'rep') && get(config, 'supports.useBrandedSrpBreadCrumbs', false)) {
    seoElements.push(setBreadCrumb('rep', params, repName));
    params.rep = '';
  }
  if (get(active, 'owner') && get(config, 'supports.useBrandedSrpBreadCrumbs', false)) {
    seoElements.push(setBreadCrumb('owner', params, ownerName));
    params.owner = '';
  }

  // Class - Make - Model order
  if (get(active, 'makeModel')) {
    map(setMultifacetedBreadCrumb('makeModel', params, seoParams, seoMakeInfo), (element) => {
      element.title = translateMake(element.title);
      seoElements.push(element);
    });
    params.makeModel = {};
  }
  if (get(active, 'multiFacetedBoatTypeClass')) {
    map(setMultifacetedBreadCrumb('multiFacetedBoatTypeClass', params), (element) => {
      seoElements.push(element);
    });
    params.multiFacetedBoatTypeClass = {};
  }

  if (get(active, 'owner') && get(config, 'supports.useDealerSearchBreadCrumb', false)) {
    seoElements.push({ title: t(messages.partySearch.SEO.breadcrumbs), link: getPartySearchUrl()});
  } else {
    seoElements.push(setBreadCrumb(t(messages.header.boatsForSale), params));
  }

  // Reverse the breadcrumbs order, giving more priority to SRP, Class,
  // Make & Model and less priority to the location. Remove last link.
  let breadCrumbs = concat(seoElements, [{ title: t(messages.header.home), link: '/' }]);
  delete breadCrumbs[0].link;
  return reverse(breadCrumbs);
};

const setBreadCrumb = (key, params, value = '', config) => {
  // istanbul ignore next
  if (!config) {
    // TODO: remove this call when config is async
    config = getConfig();
  }
  const { formatMessage: t, messages } = syncTranslationElements();
  let param = get(params, key);
  let link = generateSearchPath({}, params, true);
  const selectedCountry = get(params, 'country', get(config, 'country'));
  switch (key) {
  case 'worldRegion':
    return { title: t(messages.worldRegions[param]), link: link };
  case 'region':
    return { title: t(messages.countryRegions[param]), link: link };
  case 'subdivision':
    if (get(messages, `countrySubdivision.${selectedCountry}.${param}`)) {
      return { title: t(get(messages, `countrySubdivision.${selectedCountry}.${param}`)), link: link};
    }
    return { title: key, link };
  case 'country':
    return { title: t(messages.countries[param]), link: link};
  case 'city':
    return { title: capitalizeString(unhyphenateUrlComponents(param[0])), link: link};
  case 'owner':
    return { title: value,  link: link };
  case 'rep':
    return { title: value,  link: link };
  default:
    return { title: key, link: link };
  }
};

const setMultifacetedBreadCrumb = (key, params, seoParams, seoMakeInfo) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  let breadCrumbs = [];
  let items = get(params, key);
  let item = keys(items)[0];
  if (items[item]) {
    if (items[item][0]) {
      let subItem = items[item][0];
      if (subItem !== handleLegacyAllType(item, 'all')) {
        params[key] = { [item]: [subItem] };
        let subItemTitle = key === 'multiFacetedBoatTypeClass' ?
          t(messages.classFacetValues[get(getClass(subItem), 'value')]) :
          seoParams.model;
        breadCrumbs.push({ title: subItemTitle, link: generateSearchPath({}, params, true) });
      }
    }
    params[key] = key === 'multiFacetedBoatTypeClass' ?
      params[key] = { [item]: [handleLegacyAllType(item, 'all')] } :
      params[key] = { [item]: [] };
    let itemTitle = key === 'multiFacetedBoatTypeClass' ?
      t(messages[get(getType(item), 'value')]) : seoMakeInfo?.seoMakeName ? seoMakeInfo.seoMakeName : seoParams.make;
    breadCrumbs.push({ title: itemTitle, link: generateSearchPath({}, params, true)});
  }
  return breadCrumbs;
};
