import React from 'react';
import {ToolSetOptionsItem} from '../../../../../../../components/ToolSet';
import Link from '../../../../../../../components/SEO/Link';
import PropTypes from 'prop-types';

const RegionToolSetItem = ({region, onItemClick, linkHref, linkName, isSelected}) => {
  return (
    <ToolSetOptionsItem
      key={`Region-${region.value}`}
      id={`Region-${region.value}`}
      name={`Region-${region.value}`}
      value={region.value}
      selected={isSelected}
      onClick={onItemClick}
    >
      <Link
        className="region-type-link"
        href={linkHref}
        onClick={(e) => e.preventDefault()}
      >
        {linkName}
      </Link>
    </ToolSetOptionsItem>
  );
};

RegionToolSetItem.propTypes = {
  region: PropTypes.object,
  onItemClick: PropTypes.func,
  linkHref: PropTypes.string,
  linkName: PropTypes.string,
  isSelected: PropTypes.bool
};

export default RegionToolSetItem;
