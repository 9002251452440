import { HEADER_OFFSET } from '../constants';

//Adding { behavior: 'smooth' } will animate the scroll
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
export const scrollTo = (selector, options) => {
  const elementToWatch = document.querySelector(selector);
  if (elementToWatch) {
    elementToWatch.scrollIntoView(options);
    return;
  }
  // eslint-disable-next-line no-console
  console.error("TypeError: Cannot read property 'scrollIntoView' of null");
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const scrollToComponent = (selector) => {
  window.scrollTo({
    top: document.querySelector(selector)?.offsetTop - HEADER_OFFSET,
    behavior: 'smooth'
  });
};
