import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { NumberRangeForm } from '@dmm/lib-react-ui-components';
import { getMessages } from '../../../../../tppServices/translations/messages';
import {SupportedUoms} from '../FilterLength/index';
import { trackMinMaxValuesFromForm } from '../../RefineSearch/FilterHelpers/useSRPFilters';

const FilterLengthForm = ({ handleDataChange, min = '', max = '', cookies, customUom }) => {
  const { formatMessage: t } = useIntl();
  const messages = getMessages();

  return (
    <>
      <NumberRangeForm
        splitter={ t(messages.till) }
        min={{
          id: "min-length-input",
          label: "min-length-input",
          name: "min-length-input",
          placeholder: t(messages.quickSearch.min),
          value: min,
        }}
        max={{
          id: "max-length-input",
          label: "max-length-input",
          name: "max-length-input",
          placeholder: t(messages.quickSearch.max),
          value: max,
        }}
        buttonText={ t(messages.go) }
        validate={ ({ min, max }) => {
          const errors = {};
          if ((max !== '') && (+min > +max)) {
            errors.max = t(messages.lengthMaxError);
          }

          return errors;
        }}
        onSubmit={(values) => {
          handleDataChange('length', values);
          trackMinMaxValuesFromForm( values, 'length');
        }}
        buttonAttributes={{
          'data-reporting-click-internal-link-id': 'length filter',
          'data-reporting-click-internal-link-type': 'click'
        }}
      />
      <div className="search-filter length length-form">
        <SupportedUoms customUom={customUom} cookies={cookies} handleDataChange={handleDataChange} min={min} max={max} />
      </div>
    </>
  );
};

FilterLengthForm.propTypes = {
  max: PropTypes.string,
  min: PropTypes.string,
  handleDataChange: PropTypes.func,
  cookies: PropTypes.object,
  customUom: PropTypes.shape({
    length: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    weight: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    speed: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    capacity: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    distance: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    radius: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  }),
};

export default FilterLengthForm;

