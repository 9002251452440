import {defineMessages} from 'react-intl';
import {getDefaultTranslation} from '../../../../tppServices/translations/translationsLoader';

const defineExpertReviewMessages = (enDefaults) => {
  return defineMessages({
    condition: {
      id: 'app.search.filter.condition',
      defaultMessage: enDefaults['app.search.filter.condition']
    },
    price: {
      id: 'app.search.filter.price',
      defaultMessage: enDefaults['app.search.filter.price']
    },
    year: {
      id: 'app.boatDetails.details.year',
      defaultMessage: enDefaults['app.boatDetails.details.year']
    },
    typeClass: {
      id: 'app.search.category',
      defaultMessage: enDefaults['app.search.category']
    },
    hullMaterial: {
      id: 'app.boatDetails.details.hull-material',
      defaultMessage: enDefaults['app.boatDetails.details.hull-material']
    },
    fiberglass: {
      id: 'app.boatDetails.value.hull-material.fiberglass',
      defaultMessage: enDefaults['app.boatDetails.value.hull-material.fiberglass']
    },
    composite: {
      id: 'app.boatDetails.value.hull-material.composite',
      defaultMessage: enDefaults['app.boatDetails.value.hull-material.composite']
    },
    steel: {
      id: 'app.boatDetails.value.hull-material.steel',
      defaultMessage: enDefaults['app.boatDetails.value.hull-material.steel']
    },
    aluminum: {
      id: 'app.boatDetails.value.hull-material.aluminum',
      defaultMessage: enDefaults['app.boatDetails.value.hull-material.aluminum']
    },
    other: {
      id: 'app.boatDetails.value.drive-type.other',
      defaultMessage: enDefaults['app.boatDetails.value.drive-type.other']
    },
    wood: {
      id: 'app.boatDetails.value.hull-material.wood',
      defaultMessage: enDefaults['app.boatDetails.value.hull-material.wood']
    },
    hypalon: {
      id: 'app.boatDetails.value.hull-material.hypalon',
      defaultMessage: enDefaults['app.boatDetails.value.hull-material.hypalon']
    },
    pvc: {
      id: 'app.boatDetails.value.hull-material.pvc',
      defaultMessage: enDefaults['app.boatDetails.value.hull-material.pvc']
    },
    'ferro-cement': {
      id: 'app.boatDetails.value.hull-material.ferro-cement',
      defaultMessage: enDefaults['app.boatDetails.value.hull-material.ferro-cement']
    },
    roplene: {
      id: 'app.boatDetails.value.hull-material.roplene',
      defaultMessage: enDefaults['app.boatDetails.value.hull-material.roplene']
    },
    fuelType: {
      id: 'app.boatDetails.details.fuel-type',
      defaultMessage: enDefaults['app.boatDetails.details.fuel-type']
    },
    diesel: {
      id: 'app.boatDetails.value.fuel-type.diesel',
      defaultMessage: enDefaults['app.boatDetails.value.fuel-type.diesel']
    },
    gasoline: {
      id: 'app.boatDetails.value.fuel-type.gasoline',
      defaultMessage: enDefaults['app.boatDetails.value.fuel-type.gasoline']
    },
    electric: {
      id: 'app.boatDetails.value.fuel-type.electric',
      defaultMessage: enDefaults['app.boatDetails.value.fuel-type.electric']
    },
    lpg: {
      id: 'app.boatDetails.value.fuel-type.lpg',
      defaultMessage: enDefaults['app.boatDetails.value.fuel-type.lpg']
    },
    'dt-other': {
      id: 'app.boatDetails.value.fuel-type.other',
      defaultMessage: enDefaults['app.boatDetails.value.fuel-type.other']
    },
    numberOfEngines: {
      id: 'app.boatDetails.value.more-details-section.engines',
      defaultMessage: enDefaults['app.boatDetails.value.more-details-section.engines']
    },
    power: {
      id: 'app.types.power',
      defaultMessage: enDefaults['app.types.power']
    },
    'power-all': {
      id: 'app.facet.class.power-all',
      defaultMessage: enDefaults['app.facet.class.power-all']
    },
    'sail-all': {
      id: 'app.facet.class.sail-all',
      defaultMessage: enDefaults['app.facet.class.sail-all']
    },
    'unpowered-all': {
      id: 'app.facet.class.unpowered-all',
      defaultMessage: enDefaults['app.facet.class.unpowered-all']
    },
    sail: {
      id: 'app.types.sail',
      defaultMessage: enDefaults['app.types.sail']
    },
    unpowered: {
      id: 'app.types.unpowered',
      defaultMessage: enDefaults['app.types.unpowered']
    },
    class: {
      id: 'app.search.filter.class',
      defaultMessage: enDefaults['app.search.filter.class']
    },
    'power-motor': {
      id: 'app.facet.class.power-motor',
      defaultMessage: enDefaults['app.facet.class.power-motor']
    },
    'power-cruiser': {
      id: 'app.facet.class.power-cruiser',
      defaultMessage: enDefaults['app.facet.class.power-cruiser']
    },
    'power-expresscruiser': {
      id: 'app.facet.class.power-expresscruiser',
      defaultMessage: enDefaults['app.facet.class.power-expresscruiser']
    },
    'power-flybridge': {
      id: 'app.facet.class.power-flybridge',
      defaultMessage: enDefaults['app.facet.class.power-flybridge']
    },
    'power-center': {
      id: 'app.facet.class.power-center',
      defaultMessage: enDefaults['app.facet.class.power-center']
    },
    'power-saltfish': {
      id: 'app.facet.class.power-saltfish',
      defaultMessage: enDefaults['app.facet.class.power-saltfish']
    },
    'power-bowrider': {
      id: 'app.facet.class.power-bowrider',
      defaultMessage: enDefaults['app.facet.class.power-bowrider']
    },
    'power-sportfish': {
      id: 'app.facet.class.power-sportfish',
      defaultMessage: enDefaults['app.facet.class.power-sportfish']
    },
    'power-sportcruiser': {
      id: 'app.facet.class.power-sportcruiser',
      defaultMessage: enDefaults['app.facet.class.power-sportcruiser']
    },
    'power-trawler': {
      id: 'app.facet.class.power-trawler',
      defaultMessage: enDefaults['app.facet.class.power-trawler']
    },
    'power-mega': {
      id: 'app.facet.class.power-mega',
      defaultMessage: enDefaults['app.facet.class.power-mega']
    },
    'power-runabout': {
      id: 'app.facet.class.power-runabout',
      defaultMessage: enDefaults['app.facet.class.power-runabout']
    },
    'power-convertible': {
      id: 'app.facet.class.power-convertible',
      defaultMessage: enDefaults['app.facet.class.power-convertible']
    },
    'power-cuddy': {
      id: 'app.facet.class.power-cuddy',
      defaultMessage: enDefaults['app.facet.class.power-cuddy']
    },
    'power-highperf': {
      id: 'app.facet.class.power-highperf',
      defaultMessage: enDefaults['app.facet.class.power-highperf']
    },
    'power-pontoon': {
      id: 'app.facet.class.power-pontoon',
      defaultMessage: enDefaults['app.facet.class.power-pontoon']
    },
    'power-rib': {
      id: 'app.facet.class.power-rib',
      defaultMessage: enDefaults['app.facet.class.power-rib']
    },
    'power-other': {
      id: 'app.facet.class.power-other',
      defaultMessage: enDefaults['app.facet.class.power-other']
    },
    'power-pilot': {
      id: 'app.facet.class.power-pilot',
      defaultMessage: enDefaults['app.facet.class.power-pilot']
    },
    'power-aft': {
      id: 'app.facet.class.power-aft',
      defaultMessage: enDefaults['app.facet.class.power-aft']
    },
    'power-pwc': {
      id: 'app.facet.class.power-pwc',
      defaultMessage: enDefaults['app.facet.class.power-pwc']
    },
    'power-antique': {
      id: 'app.facet.class.power-antique',
      defaultMessage: enDefaults['app.facet.class.power-antique']
    },
    'power-downeast': {
      id: 'app.facet.class.power-downeast',
      defaultMessage: enDefaults['app.facet.class.power-downeast']
    },
    'power-skiwake': {
      id: 'app.facet.class.power-skiwake',
      defaultMessage: enDefaults['app.facet.class.power-skiwake']
    },
    'power-pcatamaran': {
      id: 'app.facet.class.power-pcatamaran',
      defaultMessage: enDefaults['app.facet.class.power-pcatamaran']
    },
    'power-deck': {
      id: 'app.facet.class.power-deck',
      defaultMessage: enDefaults['app.facet.class.power-deck']
    },
    'power-commercial': {
      id: 'app.facet.class.power-commercial',
      defaultMessage: enDefaults['app.facet.class.power-commercial']
    },
    'power-house': {
      id: 'app.facet.class.power-house',
      defaultMessage: enDefaults['app.facet.class.power-house']
    },
    'power-inflatable': {
      id: 'app.facet.class.power-inflatable',
      defaultMessage: enDefaults['app.facet.class.power-inflatable']
    },
    'power-bay': {
      id: 'app.facet.class.power-bay',
      defaultMessage: enDefaults['app.facet.class.power-bay']
    },
    'power-jet': {
      id: 'app.facet.class.power-jet',
      defaultMessage: enDefaults['app.facet.class.power-jet']
    },
    'power-dualconsole': {
      id: 'app.facet.class.power-dualconsole',
      defaultMessage: enDefaults['app.facet.class.power-dualconsole']
    },
    'power-tender': {
      id: 'app.facet.class.power-tender',
      defaultMessage: enDefaults['app.facet.class.power-tender']
    },
    'power-fresh': {
      id: 'app.facet.class.power-fresh',
      defaultMessage: enDefaults['app.facet.class.power-fresh']
    },
    'power-unspec': {
      id: 'app.facet.class.power-unspec',
      defaultMessage: enDefaults['app.facet.class.power-unspec']
    },
    'power-walk': {
      id: 'app.facet.class.power-walk',
      defaultMessage: enDefaults['app.facet.class.power-walk']
    },
    'power-motorsailer': {
      id: 'app.facet.class.power-motorsailer',
      defaultMessage: enDefaults['app.facet.class.power-motorsailer']
    },
    'power-cruiseship': {
      id: 'app.facet.class.power-cruiseship',
      defaultMessage: enDefaults['app.facet.class.power-cruiseship']
    },
    'power-passenger': {
      id: 'app.facet.class.power-passenger',
      defaultMessage: enDefaults['app.facet.class.power-passenger']
    },
    'power-rivercruiser': {
      id: 'app.facet.class.power-rivercruiser',
      defaultMessage: enDefaults['app.facet.class.power-rivercruiser']
    },
    'power-tug': {
      id: 'app.facet.class.power-tug',
      defaultMessage: enDefaults['app.facet.class.power-tug']
    },
    'power-util': {
      id: 'app.facet.class.power-util',
      defaultMessage: enDefaults['app.facet.class.power-util']
    },
    'power-aluminum': {
      id: 'app.facet.class.power-aluminum',
      defaultMessage: enDefaults['app.facet.class.power-aluminum']
    },
    'power-gulet': {
      id: 'app.facet.class.power-gulet',
      defaultMessage: enDefaults['app.facet.class.power-gulet']
    },
    'power-bass': {
      id: 'app.facet.class.power-bass',
      defaultMessage: enDefaults['app.facet.class.power-bass']
    },
    'power-barge': {
      id: 'app.facet.class.power-barge',
      defaultMessage: enDefaults['app.facet.class.power-barge']
    },
    'power-skifish': {
      id: 'app.facet.class.power-skifish',
      defaultMessage: enDefaults['app.facet.class.power-skifish']
    },
    'power-dive': {
      id: 'app.facet.class.power-dive',
      defaultMessage: enDefaults['app.facet.class.power-dive']
    },
    'power-flats': {
      id: 'app.facet.class.power-flats',
      defaultMessage: enDefaults['app.facet.class.power-flats']
    },
    'power-airboat': {
      id: 'app.facet.class.power-airboat',
      defaultMessage: enDefaults['app.facet.class.power-airboat']
    },
    'power-narrow': {
      id: 'app.facet.class.power-narrow',
      defaultMessage: enDefaults['app.facet.class.power-narrow']
    },
    'power-lobster': {
      id: 'app.facet.class.power-lobster',
      defaultMessage: enDefaults['app.facet.class.power-lobster']
    },
    'power-skiff': {
      id: 'app.facet.class.power-skiff',
      defaultMessage: enDefaults['app.facet.class.power-skiff']
    },
    'power-sloop': {
      id: 'app.facet.class.power-sloop',
      defaultMessage: enDefaults['app.facet.class.power-sloop']
    },
    'power-cargo': {
      id: 'app.facet.class.power-cargo',
      defaultMessage: enDefaults['app.facet.class.power-cargo']
    },
    'power-jon': {
      id: 'app.facet.class.power-jon',
      defaultMessage: enDefaults['app.facet.class.power-jon']
    },
    'power-troller': {
      id: 'app.facet.class.power-troller',
      defaultMessage: enDefaults['app.facet.class.power-troller']
    },
    'power-dinghy': {
      id: 'app.facet.class.power-dinghy',
      defaultMessage: enDefaults['app.facet.class.power-dinghy']
    },
    'power-dragger': {
      id: 'app.facet.class.power-dragger',
      defaultMessage: enDefaults['app.facet.class.power-dragger']
    },
    'sail-cruiser': {
      id: 'app.facet.class.sail-cruiser',
      defaultMessage: enDefaults['app.facet.class.sail-cruiser']
    },
    'sail-racercruiser': {
      id: 'app.facet.class.sail-racercruiser',
      defaultMessage: enDefaults['app.facet.class.sail-racercruiser']
    },
    'sail-sloop': {
      id: 'app.facet.class.sail-sloop',
      defaultMessage: enDefaults['app.facet.class.sail-sloop']
    },
    'sail-racer': {
      id: 'app.facet.class.sail-racer',
      defaultMessage: enDefaults['app.facet.class.sail-racer']
    },
    'sail-multihull': {
      id: 'app.facet.class.sail-multihull',
      defaultMessage: enDefaults['app.facet.class.sail-multihull']
    },
    'sail-day': {
      id: 'app.facet.class.sail-day',
      defaultMessage: enDefaults['app.facet.class.sail-day']
    },
    'sail-cutter': {
      id: 'app.facet.class.sail-cutter',
      defaultMessage: enDefaults['app.facet.class.sail-cutter']
    },
    'sail-motor': {
      id: 'app.facet.class.sail-motor',
      defaultMessage: enDefaults['app.facet.class.sail-motor']
    },
    'sail-antique': {
      id: 'app.facet.class.power-antique',
      defaultMessage: enDefaults['app.facet.class.power-antique']
    },
    'sail-ketch': {
      id: 'app.facet.class.sail-ketch',
      defaultMessage: enDefaults['app.facet.class.sail-ketch']
    },
    'sail-deck': {
      id: 'app.facet.class.sail-deck',
      defaultMessage: enDefaults['app.facet.class.sail-deck']
    },
    'sail-centercockpit': {
      id: 'app.facet.class.sail-centercockpit',
      defaultMessage: enDefaults['app.facet.class.sail-centercockpit']
    },
    'sail-pilot': {
      id: 'app.facet.class.power-pilot',
      defaultMessage: enDefaults['app.facet.class.power-pilot']
    },
    'sail-schooner': {
      id: 'app.facet.class.sail-schooner',
      defaultMessage: enDefaults['app.facet.class.sail-schooner']
    },
    'sail-gulet': {
      id: 'app.facet.class.power-gulet',
      defaultMessage: enDefaults['app.facet.class.power-gulet']
    },
    'sail-unspec': {
      id: 'app.facet.class.power-unspec',
      defaultMessage: enDefaults['app.facet.class.power-unspec']
    },
    'sail-trimaran': {
      id: 'app.facet.class.sail-trimaran',
      defaultMessage: enDefaults['app.facet.class.sail-trimaran']
    },
    'sail-yawl': {
      id: 'app.facet.class.sail-yawl',
      defaultMessage: enDefaults['app.facet.class.sail-yawl']
    },
    'sail-dinghy': {
      id: 'app.facet.class.sail-dinghy',
      defaultMessage: enDefaults['app.facet.class.sail-dinghy']
    },
    'sail-other': {
      id: 'app.facet.class.power-other',
      defaultMessage: enDefaults['app.facet.class.power-other']
    },
    'sail-beachcat': {
      id: 'app.facet.class.sail-beachcat',
      defaultMessage: enDefaults['app.facet.class.sail-beachcat']
    },
    'sail-catamaran': {
      id: 'app.facet.class.sail-catamaran',
      defaultMessage: enDefaults['app.facet.class.sail-catamaran']
    },
    'sail-commercial': {
      id: 'app.facet.class.power-commercial',
      defaultMessage: enDefaults['app.facet.class.power-commercial']
    },
    'sail-barge': {
      id: 'app.facet.class.power-barge',
      defaultMessage: enDefaults['app.facet.class.power-barge']
    },
    'sail-performance': {
      id: 'app.facet.class.sail-performance',
      defaultMessage: enDefaults['app.facet.class.sail-performance']
    },
    'unpowered-dinghy': {
      id: 'app.facet.class.unpowered-dinghy',
      defaultMessage: enDefaults['app.facet.class.unpowered-dinghy']
    },
    'unpowered-kayak': {
      id: 'app.facet.class.unpowered-kayak',
      defaultMessage: enDefaults['app.facet.class.unpowered-kayak']
    },
    'unpowered-tender': {
      id: 'app.facet.class.unpowered-tender',
      defaultMessage: enDefaults['app.facet.class.unpowered-tender']
    },
    country: {
      id: 'app.search.filter.country',
      defaultMessage: enDefaults['app.search.filter.country']
    },
    city: {
      id: 'app.search.filter.cities.in.country',
      defaultMessage: enDefaults['app.search.filter.cities.in.country']
    },
    subdivision: {
      id: 'app.search.filter.subdivision',
      defaultMessage: enDefaults['app.search.filter.subdivision']
    },
    make: {
      id: 'app.boatDetails.details.make',
      defaultMessage: enDefaults['app.boatDetails.details.make']
    },
    modelAll: {
      id: 'app.general.all',
      defaultMessage: enDefaults['app.general.all']
    },
    length: {
      id: 'app.boatDetails.details.length',
      defaultMessage: enDefaults['app.boatDetails.details.length']
    },
    new: {
      id: 'app.components.Pagination.filters.condition.new',
      defaultMessage: enDefaults['app.components.Pagination.filters.condition.new']
    },
    used: {
      id: 'app.components.Pagination.filters.condition.used',
      defaultMessage: enDefaults['app.components.Pagination.filters.condition.used']
    },
    AD: {
      id: 'app.countries.AD',
      defaultMessage: enDefaults['app.countries.AD']
    },
    AE: {
      id: 'app.countries.AE',
      defaultMessage: enDefaults['app.countries.AE']
    },
    AF: {
      id: 'app.countries.AF',
      defaultMessage: enDefaults['app.countries.AF']
    },
    AG: {
      id: 'app.countries.AG',
      defaultMessage: enDefaults['app.countries.AG']
    },
    AI: {
      id: 'app.countries.AI',
      defaultMessage: enDefaults['app.countries.AI']
    },
    AL: {
      id: 'app.countries.AL',
      defaultMessage: enDefaults['app.countries.AL']
    },
    AM: {
      id: 'app.countries.AM',
      defaultMessage: enDefaults['app.countries.AM']
    },
    AN: {
      id: 'app.countries.AN',
      defaultMessage: enDefaults['app.countries.AN']
    },
    AO: {
      id: 'app.countries.AO',
      defaultMessage: enDefaults['app.countries.AO']
    },
    AQ: {
      id: 'app.countries.AQ',
      defaultMessage: enDefaults['app.countries.AQ']
    },
    AR: {
      id: 'app.countries.AR',
      defaultMessage: enDefaults['app.countries.AR']
    },
    AS: {
      id: 'app.countries.AS',
      defaultMessage: enDefaults['app.countries.AS']
    },
    AT: {
      id: 'app.countries.AT',
      defaultMessage: enDefaults['app.countries.AT']
    },
    AU: {
      id: 'app.countries.AU',
      defaultMessage: enDefaults['app.countries.AU']
    },
    AW: {
      id: 'app.countries.AW',
      defaultMessage: enDefaults['app.countries.AW']
    },
    AX: {
      id: 'app.countries.AX',
      defaultMessage: enDefaults['app.countries.AX']
    },
    AZ: {
      id: 'app.countries.AZ',
      defaultMessage: enDefaults['app.countries.AZ']
    },
    BA: {
      id: 'app.countries.BA',
      defaultMessage: enDefaults['app.countries.BA']
    },
    BB: {
      id: 'app.countries.BB',
      defaultMessage: enDefaults['app.countries.BB']
    },
    BD: {
      id: 'app.countries.BD',
      defaultMessage: enDefaults['app.countries.BD']
    },
    BE: {
      id: 'app.countries.BE',
      defaultMessage: enDefaults['app.countries.BE']
    },
    BF: {
      id: 'app.countries.BF',
      defaultMessage: enDefaults['app.countries.BF']
    },
    BG: {
      id: 'app.countries.BG',
      defaultMessage: enDefaults['app.countries.BG']
    },
    BH: {
      id: 'app.countries.BH',
      defaultMessage: enDefaults['app.countries.BH']
    },
    BI: {
      id: 'app.countries.BI',
      defaultMessage: enDefaults['app.countries.BI']
    },
    BJ: {
      id: 'app.countries.BJ',
      defaultMessage: enDefaults['app.countries.BJ']
    },
    BL: {
      id: 'app.countries.BL',
      defaultMessage: enDefaults['app.countries.BL']
    },
    BM: {
      id: 'app.countries.BM',
      defaultMessage: enDefaults['app.countries.BM']
    },
    BN: {
      id: 'app.countries.BN',
      defaultMessage: enDefaults['app.countries.BN']
    },
    BO: {
      id: 'app.countries.BO',
      defaultMessage: enDefaults['app.countries.BO']
    },
    BQ: {
      id: 'app.countries.BQ',
      defaultMessage: enDefaults['app.countries.BQ']
    },
    BR: {
      id: 'app.countries.BR',
      defaultMessage: enDefaults['app.countries.BR']
    },
    BS: {
      id: 'app.countries.BS',
      defaultMessage: enDefaults['app.countries.BS']
    },
    BT: {
      id: 'app.countries.BT',
      defaultMessage: enDefaults['app.countries.BT']
    },
    BV: {
      id: 'app.countries.BV',
      defaultMessage: enDefaults['app.countries.BV']
    },
    BW: {
      id: 'app.countries.BW',
      defaultMessage: enDefaults['app.countries.BW']
    },
    BY: {
      id: 'app.countries.BY',
      defaultMessage: enDefaults['app.countries.BY']
    },
    BZ: {
      id: 'app.countries.BZ',
      defaultMessage: enDefaults['app.countries.BZ']
    },
    CA: {
      id: 'app.countries.CA',
      defaultMessage: enDefaults['app.countries.CA']
    },
    CC: {
      id: 'app.countries.CC',
      defaultMessage: enDefaults['app.countries.CC']
    },
    CD: {
      id: 'app.countries.CD',
      defaultMessage: enDefaults['app.countries.CD']
    },
    CF: {
      id: 'app.countries.CF',
      defaultMessage: enDefaults['app.countries.CF']
    },
    CG: {
      id: 'app.countries.CG',
      defaultMessage: enDefaults['app.countries.CG']
    },
    CH: {
      id: 'app.countries.CH',
      defaultMessage: enDefaults['app.countries.CH']
    },
    CI: {
      id: 'app.countries.CI',
      defaultMessage: enDefaults['app.countries.CI']
    },
    CK: {
      id: 'app.countries.CK',
      defaultMessage: enDefaults['app.countries.CK']
    },
    CL: {
      id: 'app.countries.CL',
      defaultMessage: enDefaults['app.countries.CL']
    },
    CM: {
      id: 'app.countries.CM',
      defaultMessage: enDefaults['app.countries.CM']
    },
    CN: {
      id: 'app.countries.CN',
      defaultMessage: enDefaults['app.countries.CN']
    },
    CO: {
      id: 'app.countries.CO',
      defaultMessage: enDefaults['app.countries.CO']
    },
    CR: {
      id: 'app.countries.CR',
      defaultMessage: enDefaults['app.countries.CR']
    },
    CV: {
      id: 'app.countries.CV',
      defaultMessage: enDefaults['app.countries.CV']
    },
    CW: {
      id: 'app.countries.CW',
      defaultMessage: enDefaults['app.countries.CW']
    },
    CX: {
      id: 'app.countries.CX',
      defaultMessage: enDefaults['app.countries.CX']
    },
    CY: {
      id: 'app.countries.CY',
      defaultMessage: enDefaults['app.countries.CY']
    },
    CZ: {
      id: 'app.countries.CZ',
      defaultMessage: enDefaults['app.countries.CZ']
    },
    DE: {
      id: 'app.countries.DE',
      defaultMessage: enDefaults['app.countries.DE']
    },
    DJ: {
      id: 'app.countries.DJ',
      defaultMessage: enDefaults['app.countries.DJ']
    },
    DK: {
      id: 'app.countries.DK',
      defaultMessage: enDefaults['app.countries.DK']
    },
    DM: {
      id: 'app.countries.DM',
      defaultMessage: enDefaults['app.countries.DM']
    },
    DO: {
      id: 'app.countries.DO',
      defaultMessage: enDefaults['app.countries.DO']
    },
    DZ: {
      id: 'app.countries.DZ',
      defaultMessage: enDefaults['app.countries.DZ']
    },
    EC: {
      id: 'app.countries.EC',
      defaultMessage: enDefaults['app.countries.EC']
    },
    EE: {
      id: 'app.countries.EE',
      defaultMessage: enDefaults['app.countries.EE']
    },
    EG: {
      id: 'app.countries.EG',
      defaultMessage: enDefaults['app.countries.EG']
    },
    EH: {
      id: 'app.countries.EH',
      defaultMessage: enDefaults['app.countries.EH']
    },
    ER: {
      id: 'app.countries.ER',
      defaultMessage: enDefaults['app.countries.ER']
    },
    ES: {
      id: 'app.countries.ES',
      defaultMessage: enDefaults['app.countries.ES']
    },
    ET: {
      id: 'app.countries.ET',
      defaultMessage: enDefaults['app.countries.ET']
    },
    FI: {
      id: 'app.countries.FI',
      defaultMessage: enDefaults['app.countries.FI']
    },
    FJ: {
      id: 'app.countries.FJ',
      defaultMessage: enDefaults['app.countries.FJ']
    },
    FK: {
      id: 'app.countries.FK',
      defaultMessage: enDefaults['app.countries.FK']
    },
    FM: {
      id: 'app.countries.FM',
      defaultMessage: enDefaults['app.countries.FM']
    },
    FO: {
      id: 'app.countries.FO',
      defaultMessage: enDefaults['app.countries.FO']
    },
    FR: {
      id: 'app.countries.FR',
      defaultMessage: enDefaults['app.countries.FR']
    },
    GA: {
      id: 'app.countries.GA',
      defaultMessage: enDefaults['app.countries.GA']
    },
    GB: {
      id: 'app.countries.GB',
      defaultMessage: enDefaults['app.countries.GB']
    },
    GD: {
      id: 'app.countries.GD',
      defaultMessage: enDefaults['app.countries.GD']
    },
    GE: {
      id: 'app.countries.GE',
      defaultMessage: enDefaults['app.countries.GE']
    },
    GF: {
      id: 'app.countries.GF',
      defaultMessage: enDefaults['app.countries.GF']
    },
    GG: {
      id: 'app.countries.GG',
      defaultMessage: enDefaults['app.countries.GG']
    },
    GH: {
      id: 'app.countries.GH',
      defaultMessage: enDefaults['app.countries.GH']
    },
    GI: {
      id: 'app.countries.GI',
      defaultMessage: enDefaults['app.countries.GI']
    },
    GL: {
      id: 'app.countries.GL',
      defaultMessage: enDefaults['app.countries.GL']
    },
    GM: {
      id: 'app.countries.GM',
      defaultMessage: enDefaults['app.countries.GM']
    },
    GN: {
      id: 'app.countries.GN',
      defaultMessage: enDefaults['app.countries.GN']
    },
    GP: {
      id: 'app.countries.GP',
      defaultMessage: enDefaults['app.countries.GP']
    },
    GQ: {
      id: 'app.countries.GQ',
      defaultMessage: enDefaults['app.countries.GQ']
    },
    GR: {
      id: 'app.countries.GR',
      defaultMessage: enDefaults['app.countries.GR']
    },
    GS: {
      id: 'app.countries.GS',
      defaultMessage: enDefaults['app.countries.GS']
    },
    GT: {
      id: 'app.countries.GT',
      defaultMessage: enDefaults['app.countries.GT']
    },
    GU: {
      id: 'app.countries.GU',
      defaultMessage: enDefaults['app.countries.GU']
    },
    GW: {
      id: 'app.countries.GW',
      defaultMessage: enDefaults['app.countries.GW']
    },
    GY: {
      id: 'app.countries.GY',
      defaultMessage: enDefaults['app.countries.GY']
    },
    HK: {
      id: 'app.countries.HK',
      defaultMessage: enDefaults['app.countries.HK']
    },
    HM: {
      id: 'app.countries.HM',
      defaultMessage: enDefaults['app.countries.HM']
    },
    HN: {
      id: 'app.countries.HN',
      defaultMessage: enDefaults['app.countries.HN']
    },
    HR: {
      id: 'app.countries.HR',
      defaultMessage: enDefaults['app.countries.HR']
    },
    HT: {
      id: 'app.countries.HT',
      defaultMessage: enDefaults['app.countries.HT']
    },
    HU: {
      id: 'app.countries.HU',
      defaultMessage: enDefaults['app.countries.HU']
    },
    ID: {
      id: 'app.countries.ID',
      defaultMessage: enDefaults['app.countries.ID']
    },
    IE: {
      id: 'app.countries.IE',
      defaultMessage: enDefaults['app.countries.IE']
    },
    IL: {
      id: 'app.countries.IL',
      defaultMessage: enDefaults['app.countries.IL']
    },
    IM: {
      id: 'app.countries.IM',
      defaultMessage: enDefaults['app.countries.IM']
    },
    IN: {
      id: 'app.countries.IN',
      defaultMessage: enDefaults['app.countries.IN']
    },
    IO: {
      id: 'app.countries.IO',
      defaultMessage: enDefaults['app.countries.IO']
    },
    IQ: {
      id: 'app.countries.IQ',
      defaultMessage: enDefaults['app.countries.IQ']
    },
    IS: {
      id: 'app.countries.IS',
      defaultMessage: enDefaults['app.countries.IS']
    },
    IT: {
      id: 'app.countries.IT',
      defaultMessage: enDefaults['app.countries.IT']
    },
    JE: {
      id: 'app.countries.JE',
      defaultMessage: enDefaults['app.countries.JE']
    },
    JM: {
      id: 'app.countries.JM',
      defaultMessage: enDefaults['app.countries.JM']
    },
    JO: {
      id: 'app.countries.JO',
      defaultMessage: enDefaults['app.countries.JO']
    },
    JP: {
      id: 'app.countries.JP',
      defaultMessage: enDefaults['app.countries.JP']
    },
    KE: {
      id: 'app.countries.KE',
      defaultMessage: enDefaults['app.countries.KE']
    },
    KG: {
      id: 'app.countries.KG',
      defaultMessage: enDefaults['app.countries.KG']
    },
    KH: {
      id: 'app.countries.KH',
      defaultMessage: enDefaults['app.countries.KH']
    },
    KI: {
      id: 'app.countries.KI',
      defaultMessage: enDefaults['app.countries.KI']
    },
    KM: {
      id: 'app.countries.KM',
      defaultMessage: enDefaults['app.countries.KM']
    },
    KN: {
      id: 'app.countries.KN',
      defaultMessage: enDefaults['app.countries.KN']
    },
    KR: {
      id: 'app.countries.KR',
      defaultMessage: enDefaults['app.countries.KR']
    },
    KW: {
      id: 'app.countries.KW',
      defaultMessage: enDefaults['app.countries.KW']
    },
    KY: {
      id: 'app.countries.KY',
      defaultMessage: enDefaults['app.countries.KY']
    },
    KZ: {
      id: 'app.countries.KZ',
      defaultMessage: enDefaults['app.countries.KZ']
    },
    LA: {
      id: 'app.countries.LA',
      defaultMessage: enDefaults['app.countries.LA']
    },
    LB: {
      id: 'app.countries.LB',
      defaultMessage: enDefaults['app.countries.LB']
    },
    LC: {
      id: 'app.countries.LC',
      defaultMessage: enDefaults['app.countries.LC']
    },
    LI: {
      id: 'app.countries.LI',
      defaultMessage: enDefaults['app.countries.LI']
    },
    LK: {
      id: 'app.countries.LK',
      defaultMessage: enDefaults['app.countries.LK']
    },
    LR: {
      id: 'app.countries.LR',
      defaultMessage: enDefaults['app.countries.LR']
    },
    LS: {
      id: 'app.countries.LS',
      defaultMessage: enDefaults['app.countries.LS']
    },
    LT: {
      id: 'app.countries.LT',
      defaultMessage: enDefaults['app.countries.LT']
    },
    LU: {
      id: 'app.countries.LU',
      defaultMessage: enDefaults['app.countries.LU']
    },
    LV: {
      id: 'app.countries.LV',
      defaultMessage: enDefaults['app.countries.LV']
    },
    LY: {
      id: 'app.countries.LY',
      defaultMessage: enDefaults['app.countries.LY']
    },
    MA: {
      id: 'app.countries.MA',
      defaultMessage: enDefaults['app.countries.MA']
    },
    MC: {
      id: 'app.countries.MC',
      defaultMessage: enDefaults['app.countries.MC']
    },
    MD: {
      id: 'app.countries.MD',
      defaultMessage: enDefaults['app.countries.MD']
    },
    ME: {
      id: 'app.countries.ME',
      defaultMessage: enDefaults['app.countries.ME']
    },
    MF: {
      id: 'app.countries.MF',
      defaultMessage: enDefaults['app.countries.MF']
    },
    MG: {
      id: 'app.countries.MG',
      defaultMessage: enDefaults['app.countries.MG']
    },
    MH: {
      id: 'app.countries.MH',
      defaultMessage: enDefaults['app.countries.MH']
    },
    MK: {
      id: 'app.countries.MK',
      defaultMessage: enDefaults['app.countries.MK']
    },
    ML: {
      id: 'app.countries.ML',
      defaultMessage: enDefaults['app.countries.ML']
    },
    MM: {
      id: 'app.countries.MM',
      defaultMessage: enDefaults['app.countries.MM']
    },
    MN: {
      id: 'app.countries.MN',
      defaultMessage: enDefaults['app.countries.MN']
    },
    MO: {
      id: 'app.countries.MO',
      defaultMessage: enDefaults['app.countries.MO']
    },
    MP: {
      id: 'app.countries.MP',
      defaultMessage: enDefaults['app.countries.MP']
    },
    MQ: {
      id: 'app.countries.MQ',
      defaultMessage: enDefaults['app.countries.MQ']
    },
    MR: {
      id: 'app.countries.MR',
      defaultMessage: enDefaults['app.countries.MR']
    },
    MS: {
      id: 'app.countries.MS',
      defaultMessage: enDefaults['app.countries.MS']
    },
    MT: {
      id: 'app.countries.MT',
      defaultMessage: enDefaults['app.countries.MT']
    },
    MU: {
      id: 'app.countries.MU',
      defaultMessage: enDefaults['app.countries.MU']
    },
    MV: {
      id: 'app.countries.MV',
      defaultMessage: enDefaults['app.countries.MV']
    },
    MW: {
      id: 'app.countries.MW',
      defaultMessage: enDefaults['app.countries.MW']
    },
    MX: {
      id: 'app.countries.MX',
      defaultMessage: enDefaults['app.countries.MX']
    },
    MY: {
      id: 'app.countries.MY',
      defaultMessage: enDefaults['app.countries.MY']
    },
    MZ: {
      id: 'app.countries.MZ',
      defaultMessage: enDefaults['app.countries.MZ']
    },
    NA: {
      id: 'app.countries.NA',
      defaultMessage: enDefaults['app.countries.NA']
    },
    NC: {
      id: 'app.countries.NC',
      defaultMessage: enDefaults['app.countries.NC']
    },
    NE: {
      id: 'app.countries.NE',
      defaultMessage: enDefaults['app.countries.NE']
    },
    NF: {
      id: 'app.countries.NF',
      defaultMessage: enDefaults['app.countries.NF']
    },
    NG: {
      id: 'app.countries.NG',
      defaultMessage: enDefaults['app.countries.NG']
    },
    NI: {
      id: 'app.countries.NI',
      defaultMessage: enDefaults['app.countries.NI']
    },
    NL: {
      id: 'app.countries.NL',
      defaultMessage: enDefaults['app.countries.NL']
    },
    NO: {
      id: 'app.countries.NO',
      defaultMessage: enDefaults['app.countries.NO']
    },
    NP: {
      id: 'app.countries.NP',
      defaultMessage: enDefaults['app.countries.NP']
    },
    NR: {
      id: 'app.countries.NR',
      defaultMessage: enDefaults['app.countries.NR']
    },
    NU: {
      id: 'app.countries.NU',
      defaultMessage: enDefaults['app.countries.NU']
    },
    NZ: {
      id: 'app.countries.NZ',
      defaultMessage: enDefaults['app.countries.NZ']
    },
    OM: {
      id: 'app.countries.OM',
      defaultMessage: enDefaults['app.countries.OM']
    },
    PA: {
      id: 'app.countries.PA',
      defaultMessage: enDefaults['app.countries.PA']
    },
    PE: {
      id: 'app.countries.PE',
      defaultMessage: enDefaults['app.countries.PE']
    },
    PF: {
      id: 'app.countries.PF',
      defaultMessage: enDefaults['app.countries.PF']
    },
    PG: {
      id: 'app.countries.PG',
      defaultMessage: enDefaults['app.countries.PG']
    },
    PH: {
      id: 'app.countries.PH',
      defaultMessage: enDefaults['app.countries.PH']
    },
    PK: {
      id: 'app.countries.PK',
      defaultMessage: enDefaults['app.countries.PK']
    },
    PL: {
      id: 'app.countries.PL',
      defaultMessage: enDefaults['app.countries.PL']
    },
    PM: {
      id: 'app.countries.PM',
      defaultMessage: enDefaults['app.countries.PM']
    },
    PN: {
      id: 'app.countries.PN',
      defaultMessage: enDefaults['app.countries.PN']
    },
    PR: {
      id: 'app.countries.PR',
      defaultMessage: enDefaults['app.countries.PR']
    },
    PS: {
      id: 'app.countries.PS',
      defaultMessage: enDefaults['app.countries.PS']
    },
    PT: {
      id: 'app.countries.PT',
      defaultMessage: enDefaults['app.countries.PT']
    },
    PW: {
      id: 'app.countries.PW',
      defaultMessage: enDefaults['app.countries.PW']
    },
    PY: {
      id: 'app.countries.PY',
      defaultMessage: enDefaults['app.countries.PY']
    },
    QA: {
      id: 'app.countries.QA',
      defaultMessage: enDefaults['app.countries.QA']
    },
    RE: {
      id: 'app.countries.RE',
      defaultMessage: enDefaults['app.countries.RE']
    },
    RO: {
      id: 'app.countries.RO',
      defaultMessage: enDefaults['app.countries.RO']
    },
    RS: {
      id: 'app.countries.RS',
      defaultMessage: enDefaults['app.countries.RS']
    },
    RW: {
      id: 'app.countries.RW',
      defaultMessage: enDefaults['app.countries.RW']
    },
    SA: {
      id: 'app.countries.SA',
      defaultMessage: enDefaults['app.countries.SA']
    },
    SB: {
      id: 'app.countries.SB',
      defaultMessage: enDefaults['app.countries.SB']
    },
    SC: {
      id: 'app.countries.SC',
      defaultMessage: enDefaults['app.countries.SC']
    },
    SE: {
      id: 'app.countries.SE',
      defaultMessage: enDefaults['app.countries.SE']
    },
    SG: {
      id: 'app.countries.SG',
      defaultMessage: enDefaults['app.countries.SG']
    },
    SH: {
      id: 'app.countries.SH',
      defaultMessage: enDefaults['app.countries.SH']
    },
    SI: {
      id: 'app.countries.SI',
      defaultMessage: enDefaults['app.countries.SI']
    },
    SJ: {
      id: 'app.countries.SJ',
      defaultMessage: enDefaults['app.countries.SJ']
    },
    SK: {
      id: 'app.countries.SK',
      defaultMessage: enDefaults['app.countries.SK']
    },
    SL: {
      id: 'app.countries.SL',
      defaultMessage: enDefaults['app.countries.SL']
    },
    SM: {
      id: 'app.countries.SM',
      defaultMessage: enDefaults['app.countries.SM']
    },
    SN: {
      id: 'app.countries.SN',
      defaultMessage: enDefaults['app.countries.SN']
    },
    SO: {
      id: 'app.countries.SO',
      defaultMessage: enDefaults['app.countries.SO']
    },
    SR: {
      id: 'app.countries.SR',
      defaultMessage: enDefaults['app.countries.SR']
    },
    SS: {
      id: 'app.countries.SS',
      defaultMessage: enDefaults['app.countries.SS']
    },
    ST: {
      id: 'app.countries.ST',
      defaultMessage: enDefaults['app.countries.ST']
    },
    SV: {
      id: 'app.countries.SV',
      defaultMessage: enDefaults['app.countries.SV']
    },
    SX: {
      id: 'app.countries.SX',
      defaultMessage: enDefaults['app.countries.SX']
    },
    SZ: {
      id: 'app.countries.SZ',
      defaultMessage: enDefaults['app.countries.SZ']
    },
    TC: {
      id: 'app.countries.TC',
      defaultMessage: enDefaults['app.countries.TC']
    },
    TD: {
      id: 'app.countries.TD',
      defaultMessage: enDefaults['app.countries.TD']
    },
    TF: {
      id: 'app.countries.TF',
      defaultMessage: enDefaults['app.countries.TF']
    },
    TG: {
      id: 'app.countries.TG',
      defaultMessage: enDefaults['app.countries.TG']
    },
    TH: {
      id: 'app.countries.TH',
      defaultMessage: enDefaults['app.countries.TH']
    },
    TJ: {
      id: 'app.countries.TJ',
      defaultMessage: enDefaults['app.countries.TJ']
    },
    TK: {
      id: 'app.countries.TK',
      defaultMessage: enDefaults['app.countries.TK']
    },
    TL: {
      id: 'app.countries.TL',
      defaultMessage: enDefaults['app.countries.TL']
    },
    TM: {
      id: 'app.countries.TM',
      defaultMessage: enDefaults['app.countries.TM']
    },
    TN: {
      id: 'app.countries.TN',
      defaultMessage: enDefaults['app.countries.TN']
    },
    TO: {
      id: 'app.countries.TO',
      defaultMessage: enDefaults['app.countries.TO']
    },
    TR: {
      id: 'app.countries.TR',
      defaultMessage: enDefaults['app.countries.TR']
    },
    TT: {
      id: 'app.countries.TT',
      defaultMessage: enDefaults['app.countries.TT']
    },
    TV: {
      id: 'app.countries.TV',
      defaultMessage: enDefaults['app.countries.TV']
    },
    TW: {
      id: 'app.countries.TW',
      defaultMessage: enDefaults['app.countries.TW']
    },
    TZ: {
      id: 'app.countries.TZ',
      defaultMessage: enDefaults['app.countries.TZ']
    },
    UA: {
      id: 'app.countries.UA',
      defaultMessage: enDefaults['app.countries.UA']
    },
    UG: {
      id: 'app.countries.UG',
      defaultMessage: enDefaults['app.countries.UG']
    },
    UM: {
      id: 'app.countries.UM',
      defaultMessage: enDefaults['app.countries.UM']
    },
    US: {
      id: 'app.countries.US',
      defaultMessage: enDefaults['app.countries.US']
    },
    UY: {
      id: 'app.countries.UY',
      defaultMessage: enDefaults['app.countries.UY']
    },
    UZ: {
      id: 'app.countries.UZ',
      defaultMessage: enDefaults['app.countries.UZ']
    },
    VA: {
      id: 'app.countries.VA',
      defaultMessage: enDefaults['app.countries.VA']
    },
    VC: {
      id: 'app.countries.VC',
      defaultMessage: enDefaults['app.countries.VC']
    },
    VE: {
      id: 'app.countries.VE',
      defaultMessage: enDefaults['app.countries.VE']
    },
    VG: {
      id: 'app.countries.VG',
      defaultMessage: enDefaults['app.countries.VG']
    },
    VI: {
      id: 'app.countries.VI',
      defaultMessage: enDefaults['app.countries.VI']
    },
    VN: {
      id: 'app.countries.VN',
      defaultMessage: enDefaults['app.countries.VN']
    },
    VU: {
      id: 'app.countries.VU',
      defaultMessage: enDefaults['app.countries.VU']
    },
    WF: {
      id: 'app.countries.WF',
      defaultMessage: enDefaults['app.countries.WF']
    },
    WS: {
      id: 'app.countries.WS',
      defaultMessage: enDefaults['app.countries.WS']
    },
    YE: {
      id: 'app.countries.YE',
      defaultMessage: enDefaults['app.countries.YE']
    },
    YT: {
      id: 'app.countries.YT',
      defaultMessage: enDefaults['app.countries.YT']
    },
    ZA: {
      id: 'app.countries.ZA',
      defaultMessage: enDefaults['app.countries.ZA']
    },
    ZM: {
      id: 'app.countries.ZM',
      defaultMessage: enDefaults['app.countries.ZM']
    },
    ZW: {
      id: 'app.countries.ZW',
      defaultMessage: enDefaults['app.countries.ZW']
    },
    australia: {
      id: 'app.countries.AU',
      defaultMessage: enDefaults['app.countries.AU']
    },
    unitedstates: {
      id: 'app.countries.US',
      defaultMessage: enDefaults['app.countries.US']
    },
    bahamas: {
      id: 'app.countries.BS',
      defaultMessage: enDefaults['app.countries.BS']
    },
    canada: {
      id: 'app.countries.CA',
      defaultMessage: enDefaults['app.countries.CA']
    },
    newzealand: {
      id: 'app.countries.NZ',
      defaultMessage: enDefaults['app.countries.NZ']
    },
    bermuda: {
      id: 'app.countries.BM',
      defaultMessage: enDefaults['app.countries.BM']
    },
    mexico: {
      id: 'app.countries.MX',
      defaultMessage: enDefaults['app.countries.MX']
    },
    japan: {
      id: 'app.countries.JP',
      defaultMessage: enDefaults['app.countries.JP']
    },
    antarctica: {
      id: 'app.countries.AQ',
      defaultMessage: enDefaults['app.countries.AQ']
    },
    micronesia: {
      id: 'app.countries.FM',
      defaultMessage: enDefaults['app.countries.FM']
    },
    ariaSearchMakes: {
      id: 'app.search.filter.make.placeholder',
      defaultMessage: enDefaults['app.search.filter.make.placeholder']
    },
    searchCountries: {
      id: 'app.search.filter.country.placeholder',
      defaultMessage: enDefaults['app.search.filter.country.placeholder']
    },
    model: {
      id: 'app.boatDetails.details.model',
      defaultMessage: enDefaults['app.boatDetails.details.model']
    },
    'subdivision-NA-AK': {
      id: 'app.countrySubdivision.US-AK',
      defaultMessage: enDefaults['app.countrySubdivision.US-AK']
    },
    'subdivision-NA-AL': {
      id: 'app.countrySubdivision.US-AL',
      defaultMessage: enDefaults['app.countrySubdivision.US-AL']
    },
    'subdivision-NA-AR': {
      id: 'app.countrySubdivision.US-AR',
      defaultMessage: enDefaults['app.countrySubdivision.US-AR']
    },
    'subdivision-NA-AZ': {
      id: 'app.countrySubdivision.US-AZ',
      defaultMessage: enDefaults['app.countrySubdivision.US-AZ']
    },
    'subdivision-NA-CA': {
      id: 'app.countrySubdivision.US-CA',
      defaultMessage: enDefaults['app.countrySubdivision.US-CA']
    },
    'subdivision-NA-CO': {
      id: 'app.countrySubdivision.US-CO',
      defaultMessage: enDefaults['app.countrySubdivision.US-CO']
    },
    'subdivision-NA-CT': {
      id: 'app.countrySubdivision.US-CT',
      defaultMessage: enDefaults['app.countrySubdivision.US-CT']
    },
    'subdivision-NA-DC': {
      id: 'app.countrySubdivision.US-DC',
      defaultMessage: enDefaults['app.countrySubdivision.US-DC']
    },
    'subdivision-NA-DE': {
      id: 'app.countrySubdivision.US-DE',
      defaultMessage: enDefaults['app.countrySubdivision.US-DE']
    },
    'subdivision-NA-FL': {
      id: 'app.countrySubdivision.US-FL',
      defaultMessage: enDefaults['app.countrySubdivision.US-FL']
    },
    'subdivision-NA-GA': {
      id: 'app.countrySubdivision.US-GA',
      defaultMessage: enDefaults['app.countrySubdivision.US-GA']
    },
    'subdivision-NA-HI': {
      id: 'app.countrySubdivision.US-HI',
      defaultMessage: enDefaults['app.countrySubdivision.US-HI']
    },
    'subdivision-NA-IA': {
      id: 'app.countrySubdivision.US-IA',
      defaultMessage: enDefaults['app.countrySubdivision.US-IA']
    },
    'subdivision-NA-ID': {
      id: 'app.countrySubdivision.US-ID',
      defaultMessage: enDefaults['app.countrySubdivision.US-ID']
    },
    'subdivision-NA-IL': {
      id: 'app.countrySubdivision.US-IL',
      defaultMessage: enDefaults['app.countrySubdivision.US-IL']
    },
    'subdivision-NA-IN': {
      id: 'app.countrySubdivision.US-IN',
      defaultMessage: enDefaults['app.countrySubdivision.US-IN']
    },
    'subdivision-NA-KS': {
      id: 'app.countrySubdivision.US-KS',
      defaultMessage: enDefaults['app.countrySubdivision.US-KS']
    },
    'subdivision-NA-KY': {
      id: 'app.countrySubdivision.US-KY',
      defaultMessage: enDefaults['app.countrySubdivision.US-KY']
    },
    'subdivision-NA-LA': {
      id: 'app.countrySubdivision.US-LA',
      defaultMessage: enDefaults['app.countrySubdivision.US-LA']
    },
    'subdivision-NA-MA': {
      id: 'app.countrySubdivision.US-MA',
      defaultMessage: enDefaults['app.countrySubdivision.US-MA']
    },
    'subdivision-NA-MD': {
      id: 'app.countrySubdivision.US-MD',
      defaultMessage: enDefaults['app.countrySubdivision.US-MD']
    },
    'subdivision-NA-ME': {
      id: 'app.countrySubdivision.US-ME',
      defaultMessage: enDefaults['app.countrySubdivision.US-ME']
    },
    'subdivision-NA-MI': {
      id: 'app.countrySubdivision.US-MI',
      defaultMessage: enDefaults['app.countrySubdivision.US-MI']
    },
    'subdivision-NA-MN': {
      id: 'app.countrySubdivision.US-MN',
      defaultMessage: enDefaults['app.countrySubdivision.US-MN']
    },
    'subdivision-NA-MO': {
      id: 'app.countrySubdivision.US-MO',
      defaultMessage: enDefaults['app.countrySubdivision.US-MO']
    },
    'subdivision-NA-MS': {
      id: 'app.countrySubdivision.US-MS',
      defaultMessage: enDefaults['app.countrySubdivision.US-MS']
    },
    'subdivision-NA-MT': {
      id: 'app.countrySubdivision.US-MT',
      defaultMessage: enDefaults['app.countrySubdivision.US-MT']
    },
    'subdivision-NA-NC': {
      id: 'app.countrySubdivision.US-NC',
      defaultMessage: enDefaults['app.countrySubdivision.US-NC']
    },
    'subdivision-NA-ND': {
      id: 'app.countrySubdivision.US-ND',
      defaultMessage: enDefaults['app.countrySubdivision.US-ND']
    },
    'subdivision-NA-NE': {
      id: 'app.countrySubdivision.US-NE',
      defaultMessage: enDefaults['app.countrySubdivision.US-NE']
    },
    'subdivision-NA-NH': {
      id: 'app.countrySubdivision.US-NH',
      defaultMessage: enDefaults['app.countrySubdivision.US-NH']
    },
    'subdivision-NA-NJ': {
      id: 'app.countrySubdivision.US-NJ',
      defaultMessage: enDefaults['app.countrySubdivision.US-NJ']
    },
    'subdivision-NA-NM': {
      id: 'app.countrySubdivision.US-NM',
      defaultMessage: enDefaults['app.countrySubdivision.US-NM']
    },
    'subdivision-NA-NV': {
      id: 'app.countrySubdivision.US-NV',
      defaultMessage: enDefaults['app.countrySubdivision.US-NV']
    },
    'subdivision-NA-NY': {
      id: 'app.countrySubdivision.US-NY',
      defaultMessage: enDefaults['app.countrySubdivision.US-NY']
    },
    'subdivision-NA-OH': {
      id: 'app.countrySubdivision.US-OH',
      defaultMessage: enDefaults['app.countrySubdivision.US-OH']
    },
    'subdivision-NA-OK': {
      id: 'app.countrySubdivision.US-OK',
      defaultMessage: enDefaults['app.countrySubdivision.US-OK']
    },
    'subdivision-NA-OR': {
      id: 'app.countrySubdivision.US-OR',
      defaultMessage: enDefaults['app.countrySubdivision.US-OR']
    },
    'subdivision-NA-PA': {
      id: 'app.countrySubdivision.US-PA',
      defaultMessage: enDefaults['app.countrySubdivision.US-PA']
    },
    'subdivision-NA-RI': {
      id: 'app.countrySubdivision.US-RI',
      defaultMessage: enDefaults['app.countrySubdivision.US-RI']
    },
    'subdivision-NA-SC': {
      id: 'app.countrySubdivision.US-SC',
      defaultMessage: enDefaults['app.countrySubdivision.US-SC']
    },
    'subdivision-NA-SD': {
      id: 'app.countrySubdivision.US-SD',
      defaultMessage: enDefaults['app.countrySubdivision.US-SD']
    },
    'subdivision-NA-TN': {
      id: 'app.countrySubdivision.US-TN',
      defaultMessage: enDefaults['app.countrySubdivision.US-TN']
    },
    'subdivision-NA-TX': {
      id: 'app.countrySubdivision.US-TX',
      defaultMessage: enDefaults['app.countrySubdivision.US-TX']
    },
    'subdivision-NA-UT': {
      id: 'app.countrySubdivision.US-UT',
      defaultMessage: enDefaults['app.countrySubdivision.US-UT']
    },
    'subdivision-NA-VA': {
      id: 'app.countrySubdivision.US-VA',
      defaultMessage: enDefaults['app.countrySubdivision.US-VA']
    },
    'subdivision-NA-VT': {
      id: 'app.countrySubdivision.US-VT',
      defaultMessage: enDefaults['app.countrySubdivision.US-VT']
    },
    'subdivision-NA-WA': {
      id: 'app.countrySubdivision.US-WA',
      defaultMessage: enDefaults['app.countrySubdivision.US-WA']
    },
    'subdivision-NA-WI': {
      id: 'app.countrySubdivision.US-WI',
      defaultMessage: enDefaults['app.countrySubdivision.US-WI']
    },
    'subdivision-NA-WV': {
      id: 'app.countrySubdivision.US-WV',
      defaultMessage: enDefaults['app.countrySubdivision.US-WV']
    },
    'subdivision-NA-WY': {
      id: 'app.countrySubdivision.US-WY',
      defaultMessage: enDefaults['app.countrySubdivision.US-WY']
    },
    'subdivision-NA-AB': {
      id: 'app.countrySubdivision.CA-AB',
      defaultMessage: enDefaults['app.countrySubdivision.CA-AB']
    },
    'subdivision-NA-BC': {
      id: 'app.countrySubdivision.CA-BC',
      defaultMessage: enDefaults['app.countrySubdivision.CA-BC']
    },
    'subdivision-NA-MB': {
      id: 'app.countrySubdivision.CA-MB',
      defaultMessage: enDefaults['app.countrySubdivision.CA-MB']
    },
    'subdivision-NA-NB': {
      id: 'app.countrySubdivision.CA-NB',
      defaultMessage: enDefaults['app.countrySubdivision.CA-NB']
    },
    'subdivision-NA-NL': {
      id: 'app.countrySubdivision.CA-NL',
      defaultMessage: enDefaults['app.countrySubdivision.CA-NL']
    },
    'subdivision-NA-NS': {
      id: 'app.countrySubdivision.CA-NS',
      defaultMessage: enDefaults['app.countrySubdivision.CA-NS']
    },
    'subdivision-NA-NT': {
      id: 'app.countrySubdivision.CA-NT',
      defaultMessage: enDefaults['app.countrySubdivision.CA-NT']
    },
    'subdivision-NA-NU': {
      id: 'app.countrySubdivision.CA-NU',
      defaultMessage: enDefaults['app.countrySubdivision.CA-NU']
    },
    'subdivision-NA-ON': {
      id: 'app.countrySubdivision.CA-ON',
      defaultMessage: enDefaults['app.countrySubdivision.CA-ON']
    },
    'subdivision-NA-PE': {
      id: 'app.countrySubdivision.CA-PE',
      defaultMessage: enDefaults['app.countrySubdivision.CA-PE']
    },
    'subdivision-NA-QC': {
      id: 'app.countrySubdivision.CA-QC',
      defaultMessage: enDefaults['app.countrySubdivision.CA-QC']
    },
    'subdivision-NA-SK': {
      id: 'app.countrySubdivision.CA-SK',
      defaultMessage: enDefaults['app.countrySubdivision.CA-SK']
    },
    'subdivision-NA-YT': {
      id: 'app.countrySubdivision.CA-YT',
      defaultMessage: enDefaults['app.countrySubdivision.CA-YT']
    },
    'subdivision-US-AK': {
      id: 'app.countrySubdivision.US-AK',
      defaultMessage: enDefaults['app.countrySubdivision.US-AK']
    },
    'subdivision-US-AL': {
      id: 'app.countrySubdivision.US-AL',
      defaultMessage: enDefaults['app.countrySubdivision.US-AL']
    },
    'subdivision-US-AR': {
      id: 'app.countrySubdivision.US-AR',
      defaultMessage: enDefaults['app.countrySubdivision.US-AR']
    },
    'subdivision-US-AZ': {
      id: 'app.countrySubdivision.US-AZ',
      defaultMessage: enDefaults['app.countrySubdivision.US-AZ']
    },
    'subdivision-US-CA': {
      id: 'app.countrySubdivision.US-CA',
      defaultMessage: enDefaults['app.countrySubdivision.US-CA']
    },
    'subdivision-US-CO': {
      id: 'app.countrySubdivision.US-CO',
      defaultMessage: enDefaults['app.countrySubdivision.US-CO']
    },
    'subdivision-US-CT': {
      id: 'app.countrySubdivision.US-CT',
      defaultMessage: enDefaults['app.countrySubdivision.US-CT']
    },
    'subdivision-US-DC': {
      id: 'app.countrySubdivision.US-DC',
      defaultMessage: enDefaults['app.countrySubdivision.US-DC']
    },
    'subdivision-US-DE': {
      id: 'app.countrySubdivision.US-DE',
      defaultMessage: enDefaults['app.countrySubdivision.US-DE']
    },
    'subdivision-US-FL': {
      id: 'app.countrySubdivision.US-FL',
      defaultMessage: enDefaults['app.countrySubdivision.US-FL']
    },
    'subdivision-US-GA': {
      id: 'app.countrySubdivision.US-GA',
      defaultMessage: enDefaults['app.countrySubdivision.US-GA']
    },
    'subdivision-US-HI': {
      id: 'app.countrySubdivision.US-HI',
      defaultMessage: enDefaults['app.countrySubdivision.US-HI']
    },
    'subdivision-US-IA': {
      id: 'app.countrySubdivision.US-IA',
      defaultMessage: enDefaults['app.countrySubdivision.US-IA']
    },
    'subdivision-US-ID': {
      id: 'app.countrySubdivision.US-ID',
      defaultMessage: enDefaults['app.countrySubdivision.US-ID']
    },
    'subdivision-US-IL': {
      id: 'app.countrySubdivision.US-IL',
      defaultMessage: enDefaults['app.countrySubdivision.US-IL']
    },
    'subdivision-US-IN': {
      id: 'app.countrySubdivision.US-IN',
      defaultMessage: enDefaults['app.countrySubdivision.US-IN']
    },
    'subdivision-US-KS': {
      id: 'app.countrySubdivision.US-KS',
      defaultMessage: enDefaults['app.countrySubdivision.US-KS']
    },
    'subdivision-US-KY': {
      id: 'app.countrySubdivision.US-KY',
      defaultMessage: enDefaults['app.countrySubdivision.US-KY']
    },
    'subdivision-US-LA': {
      id: 'app.countrySubdivision.US-LA',
      defaultMessage: enDefaults['app.countrySubdivision.US-LA']
    },
    'subdivision-US-MA': {
      id: 'app.countrySubdivision.US-MA',
      defaultMessage: enDefaults['app.countrySubdivision.US-MA']
    },
    'subdivision-US-MD': {
      id: 'app.countrySubdivision.US-MD',
      defaultMessage: enDefaults['app.countrySubdivision.US-MD']
    },
    'subdivision-US-ME': {
      id: 'app.countrySubdivision.US-ME',
      defaultMessage: enDefaults['app.countrySubdivision.US-ME']
    },
    'subdivision-US-MI': {
      id: 'app.countrySubdivision.US-MI',
      defaultMessage: enDefaults['app.countrySubdivision.US-MI']
    },
    'subdivision-US-MN': {
      id: 'app.countrySubdivision.US-MN',
      defaultMessage: enDefaults['app.countrySubdivision.US-MN']
    },
    'subdivision-US-MO': {
      id: 'app.countrySubdivision.US-MO',
      defaultMessage: enDefaults['app.countrySubdivision.US-MO']
    },
    'subdivision-US-MS': {
      id: 'app.countrySubdivision.US-MS',
      defaultMessage: enDefaults['app.countrySubdivision.US-MS']
    },
    'subdivision-US-MT': {
      id: 'app.countrySubdivision.US-MT',
      defaultMessage: enDefaults['app.countrySubdivision.US-MT']
    },
    'subdivision-US-NC': {
      id: 'app.countrySubdivision.US-NC',
      defaultMessage: enDefaults['app.countrySubdivision.US-NC']
    },
    'subdivision-US-ND': {
      id: 'app.countrySubdivision.US-ND',
      defaultMessage: enDefaults['app.countrySubdivision.US-ND']
    },
    'subdivision-US-NE': {
      id: 'app.countrySubdivision.US-NE',
      defaultMessage: enDefaults['app.countrySubdivision.US-NE']
    },
    'subdivision-US-NH': {
      id: 'app.countrySubdivision.US-NH',
      defaultMessage: enDefaults['app.countrySubdivision.US-NH']
    },
    'subdivision-US-NJ': {
      id: 'app.countrySubdivision.US-NJ',
      defaultMessage: enDefaults['app.countrySubdivision.US-NJ']
    },
    'subdivision-US-NM': {
      id: 'app.countrySubdivision.US-NM',
      defaultMessage: enDefaults['app.countrySubdivision.US-NM']
    },
    'subdivision-US-NV': {
      id: 'app.countrySubdivision.US-NV',
      defaultMessage: enDefaults['app.countrySubdivision.US-NV']
    },
    'subdivision-US-NY': {
      id: 'app.countrySubdivision.US-NY',
      defaultMessage: enDefaults['app.countrySubdivision.US-NY']
    },
    'subdivision-US-OH': {
      id: 'app.countrySubdivision.US-OH',
      defaultMessage: enDefaults['app.countrySubdivision.US-OH']
    },
    'subdivision-US-OK': {
      id: 'app.countrySubdivision.US-OK',
      defaultMessage: enDefaults['app.countrySubdivision.US-OK']
    },
    'subdivision-US-OR': {
      id: 'app.countrySubdivision.US-OR',
      defaultMessage: enDefaults['app.countrySubdivision.US-OR']
    },
    'subdivision-US-PA': {
      id: 'app.countrySubdivision.US-PA',
      defaultMessage: enDefaults['app.countrySubdivision.US-PA']
    },
    'subdivision-US-RI': {
      id: 'app.countrySubdivision.US-RI',
      defaultMessage: enDefaults['app.countrySubdivision.US-RI']
    },
    'subdivision-US-SC': {
      id: 'app.countrySubdivision.US-SC',
      defaultMessage: enDefaults['app.countrySubdivision.US-SC']
    },
    'subdivision-US-SD': {
      id: 'app.countrySubdivision.US-SD',
      defaultMessage: enDefaults['app.countrySubdivision.US-SD']
    },
    'subdivision-US-TN': {
      id: 'app.countrySubdivision.US-TN',
      defaultMessage: enDefaults['app.countrySubdivision.US-TN']
    },
    'subdivision-US-TX': {
      id: 'app.countrySubdivision.US-TX',
      defaultMessage: enDefaults['app.countrySubdivision.US-TX']
    },
    'subdivision-US-UT': {
      id: 'app.countrySubdivision.US-UT',
      defaultMessage: enDefaults['app.countrySubdivision.US-UT']
    },
    'subdivision-US-VA': {
      id: 'app.countrySubdivision.US-VA',
      defaultMessage: enDefaults['app.countrySubdivision.US-VA']
    },
    'subdivision-US-VT': {
      id: 'app.countrySubdivision.US-VT',
      defaultMessage: enDefaults['app.countrySubdivision.US-VT']
    },
    'subdivision-US-WA': {
      id: 'app.countrySubdivision.US-WA',
      defaultMessage: enDefaults['app.countrySubdivision.US-WA']
    },
    'subdivision-US-WI': {
      id: 'app.countrySubdivision.US-WI',
      defaultMessage: enDefaults['app.countrySubdivision.US-WI']
    },
    'subdivision-US-WV': {
      id: 'app.countrySubdivision.US-WV',
      defaultMessage: enDefaults['app.countrySubdivision.US-WV']
    },
    'subdivision-US-WY': {
      id: 'app.countrySubdivision.US-WY',
      defaultMessage: enDefaults['app.countrySubdivision.US-WY']
    },
    'subdivision-CA-AB': {
      id: 'app.countrySubdivision.CA-AB',
      defaultMessage: enDefaults['app.countrySubdivision.CA-AB']
    },
    'subdivision-CA-BC': {
      id: 'app.countrySubdivision.CA-BC',
      defaultMessage: enDefaults['app.countrySubdivision.CA-BC']
    },
    'subdivision-CA-MB': {
      id: 'app.countrySubdivision.CA-MB',
      defaultMessage: enDefaults['app.countrySubdivision.CA-MB']
    },
    'subdivision-CA-NB': {
      id: 'app.countrySubdivision.CA-NB',
      defaultMessage: enDefaults['app.countrySubdivision.CA-NB']
    },
    'subdivision-CA-NL': {
      id: 'app.countrySubdivision.CA-NL',
      defaultMessage: enDefaults['app.countrySubdivision.CA-NL']
    },
    'subdivision-CA-NS': {
      id: 'app.countrySubdivision.CA-NS',
      defaultMessage: enDefaults['app.countrySubdivision.CA-NS']
    },
    'subdivision-CA-NT': {
      id: 'app.countrySubdivision.CA-NT',
      defaultMessage: enDefaults['app.countrySubdivision.CA-NT']
    },
    'subdivision-CA-NU': {
      id: 'app.countrySubdivision.CA-NU',
      defaultMessage: enDefaults['app.countrySubdivision.CA-NU']
    },
    'subdivision-CA-ON': {
      id: 'app.countrySubdivision.CA-ON',
      defaultMessage: enDefaults['app.countrySubdivision.CA-ON']
    },
    'subdivision-CA-PE': {
      id: 'app.countrySubdivision.CA-PE',
      defaultMessage: enDefaults['app.countrySubdivision.CA-PE']
    },
    'subdivision-CA-QC': {
      id: 'app.countrySubdivision.CA-QC',
      defaultMessage: enDefaults['app.countrySubdivision.CA-QC']
    },
    'subdivision-CA-SK': {
      id: 'app.countrySubdivision.CA-SK',
      defaultMessage: enDefaults['app.countrySubdivision.CA-SK']
    },
    'subdivision-CA-YT': {
      id: 'app.countrySubdivision.CA-YT',
      defaultMessage: enDefaults['app.countrySubdivision.CA-YT']
    },
    'subdivision-AU-ACT': {
      id: 'app.countrySubdivision.AU-ACT',
      defaultMessage: enDefaults['app.countrySubdivision.AU-ACT']
    },
    'subdivision-AU-NSW': {
      id: 'app.countrySubdivision.AU-NSW',
      defaultMessage: enDefaults['app.countrySubdivision.AU-NSW']
    },
    'subdivision-AU-NT': {
      id: 'app.countrySubdivision.AU-NT',
      defaultMessage: enDefaults['app.countrySubdivision.AU-NT']
    },
    'subdivision-AU-QLD': {
      id: 'app.countrySubdivision.AU-QLD',
      defaultMessage: enDefaults['app.countrySubdivision.AU-QLD']
    },
    'subdivision-AU-SA': {
      id: 'app.countrySubdivision.AU-SA',
      defaultMessage: enDefaults['app.countrySubdivision.AU-SA']
    },
    'subdivision-AU-TAS': {
      id: 'app.countrySubdivision.AU-TAS',
      defaultMessage: enDefaults['app.countrySubdivision.AU-TAS']
    },
    'subdivision-AU-VIC': {
      id: 'app.countrySubdivision.AU-VIC',
      defaultMessage: enDefaults['app.countrySubdivision.AU-VIC']
    },
    'subdivision-AU-WA': {
      id: 'app.countrySubdivision.AU-WA',
      defaultMessage: enDefaults['app.countrySubdivision.AU-WA']
    },
    'subdivision-FR-01': {
      id: 'app.countrySubdivision.FR-01',
      defaultMessage: enDefaults['app.countrySubdivision.FR-01']
    },
    'subdivision-FR-02': {
      id: 'app.countrySubdivision.FR-02',
      defaultMessage: enDefaults['app.countrySubdivision.FR-02']
    },
    'subdivision-FR-03': {
      id: 'app.countrySubdivision.FR-03',
      defaultMessage: enDefaults['app.countrySubdivision.FR-03']
    },
    'subdivision-FR-04': {
      id: 'app.countrySubdivision.FR-04',
      defaultMessage: enDefaults['app.countrySubdivision.FR-04']
    },
    'subdivision-FR-05': {
      id: 'app.countrySubdivision.FR-05',
      defaultMessage: enDefaults['app.countrySubdivision.FR-05']
    },
    'subdivision-FR-06': {
      id: 'app.countrySubdivision.FR-06',
      defaultMessage: enDefaults['app.countrySubdivision.FR-06']
    },
    'subdivision-FR-07': {
      id: 'app.countrySubdivision.FR-07',
      defaultMessage: enDefaults['app.countrySubdivision.FR-07']
    },
    'subdivision-FR-08': {
      id: 'app.countrySubdivision.FR-08',
      defaultMessage: enDefaults['app.countrySubdivision.FR-08']
    },
    'subdivision-FR-09': {
      id: 'app.countrySubdivision.FR-09',
      defaultMessage: enDefaults['app.countrySubdivision.FR-09']
    },
    'subdivision-FR-10': {
      id: 'app.countrySubdivision.FR-10',
      defaultMessage: enDefaults['app.countrySubdivision.FR-10']
    },
    'subdivision-FR-11': {
      id: 'app.countrySubdivision.FR-11',
      defaultMessage: enDefaults['app.countrySubdivision.FR-11']
    },
    'subdivision-FR-12': {
      id: 'app.countrySubdivision.FR-12',
      defaultMessage: enDefaults['app.countrySubdivision.FR-12']
    },
    'subdivision-FR-13': {
      id: 'app.countrySubdivision.FR-13',
      defaultMessage: enDefaults['app.countrySubdivision.FR-13']
    },
    'subdivision-FR-14': {
      id: 'app.countrySubdivision.FR-14',
      defaultMessage: enDefaults['app.countrySubdivision.FR-14']
    },
    'subdivision-FR-15': {
      id: 'app.countrySubdivision.FR-15',
      defaultMessage: enDefaults['app.countrySubdivision.FR-15']
    },
    'subdivision-FR-16': {
      id: 'app.countrySubdivision.FR-16',
      defaultMessage: enDefaults['app.countrySubdivision.FR-16']
    },
    'subdivision-FR-17': {
      id: 'app.countrySubdivision.FR-17',
      defaultMessage: enDefaults['app.countrySubdivision.FR-17']
    },
    'subdivision-FR-18': {
      id: 'app.countrySubdivision.FR-18',
      defaultMessage: enDefaults['app.countrySubdivision.FR-18']
    },
    'subdivision-FR-19': {
      id: 'app.countrySubdivision.FR-19',
      defaultMessage: enDefaults['app.countrySubdivision.FR-19']
    },
    'subdivision-FR-20': {
      id: 'app.countrySubdivision.FR-20',
      defaultMessage: enDefaults['app.countrySubdivision.FR-20']
    },
    'subdivision-FR-21': {
      id: 'app.countrySubdivision.FR-21',
      defaultMessage: enDefaults['app.countrySubdivision.FR-21']
    },
    'subdivision-FR-22': {
      id: 'app.countrySubdivision.FR-22',
      defaultMessage: enDefaults['app.countrySubdivision.FR-22']
    },
    'subdivision-FR-23': {
      id: 'app.countrySubdivision.FR-23',
      defaultMessage: enDefaults['app.countrySubdivision.FR-23']
    },
    'subdivision-FR-24': {
      id: 'app.countrySubdivision.FR-24',
      defaultMessage: enDefaults['app.countrySubdivision.FR-24']
    },
    'subdivision-FR-25': {
      id: 'app.countrySubdivision.FR-25',
      defaultMessage: enDefaults['app.countrySubdivision.FR-25']
    },
    'subdivision-FR-26': {
      id: 'app.countrySubdivision.FR-26',
      defaultMessage: enDefaults['app.countrySubdivision.FR-26']
    },
    'subdivision-FR-27': {
      id: 'app.countrySubdivision.FR-27',
      defaultMessage: enDefaults['app.countrySubdivision.FR-27']
    },
    'subdivision-FR-28': {
      id: 'app.countrySubdivision.FR-28',
      defaultMessage: enDefaults['app.countrySubdivision.FR-28']
    },
    'subdivision-FR-29': {
      id: 'app.countrySubdivision.FR-29',
      defaultMessage: enDefaults['app.countrySubdivision.FR-29']
    },
    'subdivision-FR-30': {
      id: 'app.countrySubdivision.FR-30',
      defaultMessage: enDefaults['app.countrySubdivision.FR-30']
    },
    'subdivision-FR-31': {
      id: 'app.countrySubdivision.FR-31',
      defaultMessage: enDefaults['app.countrySubdivision.FR-31']
    },
    'subdivision-FR-32': {
      id: 'app.countrySubdivision.FR-32',
      defaultMessage: enDefaults['app.countrySubdivision.FR-32']
    },
    'subdivision-FR-33': {
      id: 'app.countrySubdivision.FR-33',
      defaultMessage: enDefaults['app.countrySubdivision.FR-33']
    },
    'subdivision-FR-34': {
      id: 'app.countrySubdivision.FR-34',
      defaultMessage: enDefaults['app.countrySubdivision.FR-34']
    },
    'subdivision-FR-35': {
      id: 'app.countrySubdivision.FR-35',
      defaultMessage: enDefaults['app.countrySubdivision.FR-35']
    },
    'subdivision-FR-36': {
      id: 'app.countrySubdivision.FR-36',
      defaultMessage: enDefaults['app.countrySubdivision.FR-36']
    },
    'subdivision-FR-37': {
      id: 'app.countrySubdivision.FR-37',
      defaultMessage: enDefaults['app.countrySubdivision.FR-37']
    },
    'subdivision-FR-38': {
      id: 'app.countrySubdivision.FR-38',
      defaultMessage: enDefaults['app.countrySubdivision.FR-38']
    },
    'subdivision-FR-39': {
      id: 'app.countrySubdivision.FR-39',
      defaultMessage: enDefaults['app.countrySubdivision.FR-39']
    },
    'subdivision-FR-40': {
      id: 'app.countrySubdivision.FR-40',
      defaultMessage: enDefaults['app.countrySubdivision.FR-40']
    },
    'subdivision-FR-41': {
      id: 'app.countrySubdivision.FR-41',
      defaultMessage: enDefaults['app.countrySubdivision.FR-41']
    },
    'subdivision-FR-42': {
      id: 'app.countrySubdivision.FR-42',
      defaultMessage: enDefaults['app.countrySubdivision.FR-42']
    },
    'subdivision-FR-43': {
      id: 'app.countrySubdivision.FR-43',
      defaultMessage: enDefaults['app.countrySubdivision.FR-43']
    },
    'subdivision-FR-44': {
      id: 'app.countrySubdivision.FR-44',
      defaultMessage: enDefaults['app.countrySubdivision.FR-44']
    },
    'subdivision-FR-45': {
      id: 'app.countrySubdivision.FR-45',
      defaultMessage: enDefaults['app.countrySubdivision.FR-45']
    },
    'subdivision-FR-46': {
      id: 'app.countrySubdivision.FR-46',
      defaultMessage: enDefaults['app.countrySubdivision.FR-46']
    },
    'subdivision-FR-47': {
      id: 'app.countrySubdivision.FR-47',
      defaultMessage: enDefaults['app.countrySubdivision.FR-47']
    },
    'subdivision-FR-48': {
      id: 'app.countrySubdivision.FR-48',
      defaultMessage: enDefaults['app.countrySubdivision.FR-48']
    },
    'subdivision-FR-49': {
      id: 'app.countrySubdivision.FR-49',
      defaultMessage: enDefaults['app.countrySubdivision.FR-49']
    },
    'subdivision-FR-50': {
      id: 'app.countrySubdivision.FR-50',
      defaultMessage: enDefaults['app.countrySubdivision.FR-50']
    },
    'subdivision-FR-51': {
      id: 'app.countrySubdivision.FR-51',
      defaultMessage: enDefaults['app.countrySubdivision.FR-51']
    },
    'subdivision-FR-52': {
      id: 'app.countrySubdivision.FR-52',
      defaultMessage: enDefaults['app.countrySubdivision.FR-52']
    },
    'subdivision-FR-53': {
      id: 'app.countrySubdivision.FR-53',
      defaultMessage: enDefaults['app.countrySubdivision.FR-53']
    },
    'subdivision-FR-54': {
      id: 'app.countrySubdivision.FR-54',
      defaultMessage: enDefaults['app.countrySubdivision.FR-54']
    },
    'subdivision-FR-55': {
      id: 'app.countrySubdivision.FR-55',
      defaultMessage: enDefaults['app.countrySubdivision.FR-55']
    },
    'subdivision-FR-56': {
      id: 'app.countrySubdivision.FR-56',
      defaultMessage: enDefaults['app.countrySubdivision.FR-56']
    },
    'subdivision-FR-57': {
      id: 'app.countrySubdivision.FR-57',
      defaultMessage: enDefaults['app.countrySubdivision.FR-57']
    },
    'subdivision-FR-58': {
      id: 'app.countrySubdivision.FR-58',
      defaultMessage: enDefaults['app.countrySubdivision.FR-58']
    },
    'subdivision-FR-59': {
      id: 'app.countrySubdivision.FR-59',
      defaultMessage: enDefaults['app.countrySubdivision.FR-59']
    },
    'subdivision-FR-60': {
      id: 'app.countrySubdivision.FR-60',
      defaultMessage: enDefaults['app.countrySubdivision.FR-60']
    },
    'subdivision-FR-61': {
      id: 'app.countrySubdivision.FR-61',
      defaultMessage: enDefaults['app.countrySubdivision.FR-61']
    },
    'subdivision-FR-62': {
      id: 'app.countrySubdivision.FR-62',
      defaultMessage: enDefaults['app.countrySubdivision.FR-62']
    },
    'subdivision-FR-63': {
      id: 'app.countrySubdivision.FR-63',
      defaultMessage: enDefaults['app.countrySubdivision.FR-63']
    },
    'subdivision-FR-64': {
      id: 'app.countrySubdivision.FR-64',
      defaultMessage: enDefaults['app.countrySubdivision.FR-64']
    },
    'subdivision-FR-65': {
      id: 'app.countrySubdivision.FR-65',
      defaultMessage: enDefaults['app.countrySubdivision.FR-65']
    },
    'subdivision-FR-66': {
      id: 'app.countrySubdivision.FR-66',
      defaultMessage: enDefaults['app.countrySubdivision.FR-66']
    },
    'subdivision-FR-67': {
      id: 'app.countrySubdivision.FR-67',
      defaultMessage: enDefaults['app.countrySubdivision.FR-67']
    },
    'subdivision-FR-68': {
      id: 'app.countrySubdivision.FR-68',
      defaultMessage: enDefaults['app.countrySubdivision.FR-68']
    },
    'subdivision-FR-69': {
      id: 'app.countrySubdivision.FR-69',
      defaultMessage: enDefaults['app.countrySubdivision.FR-69']
    },
    'subdivision-FR-70': {
      id: 'app.countrySubdivision.FR-70',
      defaultMessage: enDefaults['app.countrySubdivision.FR-70']
    },
    'subdivision-FR-71': {
      id: 'app.countrySubdivision.FR-71',
      defaultMessage: enDefaults['app.countrySubdivision.FR-71']
    },
    'subdivision-FR-72': {
      id: 'app.countrySubdivision.FR-72',
      defaultMessage: enDefaults['app.countrySubdivision.FR-72']
    },
    'subdivision-FR-73': {
      id: 'app.countrySubdivision.FR-73',
      defaultMessage: enDefaults['app.countrySubdivision.FR-73']
    },
    'subdivision-FR-74': {
      id: 'app.countrySubdivision.FR-74',
      defaultMessage: enDefaults['app.countrySubdivision.FR-74']
    },
    'subdivision-FR-75': {
      id: 'app.countrySubdivision.FR-75',
      defaultMessage: enDefaults['app.countrySubdivision.FR-75']
    },
    'subdivision-FR-76': {
      id: 'app.countrySubdivision.FR-76',
      defaultMessage: enDefaults['app.countrySubdivision.FR-76']
    },
    'subdivision-FR-77': {
      id: 'app.countrySubdivision.FR-77',
      defaultMessage: enDefaults['app.countrySubdivision.FR-77']
    },
    'subdivision-FR-78': {
      id: 'app.countrySubdivision.FR-78',
      defaultMessage: enDefaults['app.countrySubdivision.FR-78']
    },
    'subdivision-FR-79': {
      id: 'app.countrySubdivision.FR-79',
      defaultMessage: enDefaults['app.countrySubdivision.FR-79']
    },
    'subdivision-FR-80': {
      id: 'app.countrySubdivision.FR-80',
      defaultMessage: enDefaults['app.countrySubdivision.FR-80']
    },
    'subdivision-FR-81': {
      id: 'app.countrySubdivision.FR-81',
      defaultMessage: enDefaults['app.countrySubdivision.FR-81']
    },
    'subdivision-FR-82': {
      id: 'app.countrySubdivision.FR-82',
      defaultMessage: enDefaults['app.countrySubdivision.FR-82']
    },
    'subdivision-FR-83': {
      id: 'app.countrySubdivision.FR-83',
      defaultMessage: enDefaults['app.countrySubdivision.FR-83']
    },
    'subdivision-FR-84': {
      id: 'app.countrySubdivision.FR-84',
      defaultMessage: enDefaults['app.countrySubdivision.FR-84']
    },
    'subdivision-FR-85': {
      id: 'app.countrySubdivision.FR-85',
      defaultMessage: enDefaults['app.countrySubdivision.FR-85']
    },
    'subdivision-FR-86': {
      id: 'app.countrySubdivision.FR-86',
      defaultMessage: enDefaults['app.countrySubdivision.FR-86']
    },
    'subdivision-FR-87': {
      id: 'app.countrySubdivision.FR-87',
      defaultMessage: enDefaults['app.countrySubdivision.FR-87']
    },
    'subdivision-FR-88': {
      id: 'app.countrySubdivision.FR-88',
      defaultMessage: enDefaults['app.countrySubdivision.FR-88']
    },
    'subdivision-FR-89': {
      id: 'app.countrySubdivision.FR-89',
      defaultMessage: enDefaults['app.countrySubdivision.FR-89']
    },
    'subdivision-FR-90': {
      id: 'app.countrySubdivision.FR-90',
      defaultMessage: enDefaults['app.countrySubdivision.FR-90']
    },
    'subdivision-FR-91': {
      id: 'app.countrySubdivision.FR-91',
      defaultMessage: enDefaults['app.countrySubdivision.FR-91']
    },
    'subdivision-FR-92': {
      id: 'app.countrySubdivision.FR-92',
      defaultMessage: enDefaults['app.countrySubdivision.FR-92']
    },
    'subdivision-FR-93': {
      id: 'app.countrySubdivision.FR-93',
      defaultMessage: enDefaults['app.countrySubdivision.FR-93']
    },
    'subdivision-FR-94': {
      id: 'app.countrySubdivision.FR-94',
      defaultMessage: enDefaults['app.countrySubdivision.FR-94']
    },
    'subdivision-FR-95': {
      id: 'app.countrySubdivision.FR-95',
      defaultMessage: enDefaults['app.countrySubdivision.FR-95']
    },
    'subdivision-GB-E00': {
      id: 'app.countrySubdivision.GB-E00',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E00']
    },
    'subdivision-GB-E01': {
      id: 'app.countrySubdivision.GB-E01',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E01']
    },
    'subdivision-GB-E02': {
      id: 'app.countrySubdivision.GB-E02',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E02']
    },
    'subdivision-GB-E03': {
      id: 'app.countrySubdivision.GB-E03',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E03']
    },
    'subdivision-GB-E04': {
      id: 'app.countrySubdivision.GB-E04',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E04']
    },
    'subdivision-GB-E05': {
      id: 'app.countrySubdivision.GB-E05',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E05']
    },
    'subdivision-GB-E06': {
      id: 'app.countrySubdivision.GB-E06',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E06']
    },
    'subdivision-GB-E07': {
      id: 'app.countrySubdivision.GB-E07',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E07']
    },
    'subdivision-GB-E08': {
      id: 'app.countrySubdivision.GB-E08',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E08']
    },
    'subdivision-GB-E09': {
      id: 'app.countrySubdivision.GB-E09',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E09']
    },
    'subdivision-GB-E10': {
      id: 'app.countrySubdivision.GB-E10',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E10']
    },
    'subdivision-GB-E11': {
      id: 'app.countrySubdivision.GB-E11',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E11']
    },
    'subdivision-GB-E12': {
      id: 'app.countrySubdivision.GB-E12',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E12']
    },
    'subdivision-GB-E13': {
      id: 'app.countrySubdivision.GB-E13',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E13']
    },
    'subdivision-GB-E14': {
      id: 'app.countrySubdivision.GB-E14',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E14']
    },
    'subdivision-GB-E15': {
      id: 'app.countrySubdivision.GB-E15',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E15']
    },
    'subdivision-GB-E16': {
      id: 'app.countrySubdivision.GB-E16',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E16']
    },
    'subdivision-GB-E17': {
      id: 'app.countrySubdivision.GB-E17',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E17']
    },
    'subdivision-GB-E18': {
      id: 'app.countrySubdivision.GB-E18',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E18']
    },
    'subdivision-GB-E19': {
      id: 'app.countrySubdivision.GB-E19',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E19']
    },
    'subdivision-GB-E20': {
      id: 'app.countrySubdivision.GB-E20',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E20']
    },
    'subdivision-GB-E201': {
      id: 'app.countrySubdivision.GB-E201',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E201']
    },
    'subdivision-GB-E21': {
      id: 'app.countrySubdivision.GB-E21',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E21']
    },
    'subdivision-GB-E22': {
      id: 'app.countrySubdivision.GB-E22',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E22']
    },
    'subdivision-GB-E23': {
      id: 'app.countrySubdivision.GB-E23',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E23']
    },
    'subdivision-GB-E24': {
      id: 'app.countrySubdivision.GB-E24',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E24']
    },
    'subdivision-GB-E25': {
      id: 'app.countrySubdivision.GB-E25',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E25']
    },
    'subdivision-GB-E26': {
      id: 'app.countrySubdivision.GB-E26',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E26']
    },
    'subdivision-GB-E27': {
      id: 'app.countrySubdivision.GB-E27',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E27']
    },
    'subdivision-GB-E28': {
      id: 'app.countrySubdivision.GB-E28',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E28']
    },
    'subdivision-GB-E29': {
      id: 'app.countrySubdivision.GB-E29',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E29']
    },
    'subdivision-GB-E30': {
      id: 'app.countrySubdivision.GB-E30',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E30']
    },
    'subdivision-GB-E31': {
      id: 'app.countrySubdivision.GB-E31',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E31']
    },
    'subdivision-GB-E031': {
      id: 'app.countrySubdivision.GB-E031',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E031']
    },
    'subdivision-GB-E32': {
      id: 'app.countrySubdivision.GB-E32',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E32']
    },
    'subdivision-GB-E33': {
      id: 'app.countrySubdivision.GB-E33',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E33']
    },
    'subdivision-GB-E34': {
      id: 'app.countrySubdivision.GB-E34',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E34']
    },
    'subdivision-GB-E35': {
      id: 'app.countrySubdivision.GB-E35',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E35']
    },
    'subdivision-GB-E36': {
      id: 'app.countrySubdivision.GB-E36',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E36']
    },
    'subdivision-GB-E37': {
      id: 'app.countrySubdivision.GB-E37',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E37']
    },
    'subdivision-GB-E38': {
      id: 'app.countrySubdivision.GB-E38',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E38']
    },
    'subdivision-GB-E39': {
      id: 'app.countrySubdivision.GB-E39',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E39']
    },
    'subdivision-GB-E40': {
      id: 'app.countrySubdivision.GB-E40',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E40']
    },
    'subdivision-GB-E41': {
      id: 'app.countrySubdivision.GB-E41',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E41']
    },
    'subdivision-GB-E42': {
      id: 'app.countrySubdivision.GB-E42',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E42']
    },
    'subdivision-GB-E43': {
      id: 'app.countrySubdivision.GB-E43',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E43']
    },
    'subdivision-GB-E44': {
      id: 'app.countrySubdivision.GB-E44',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E44']
    },
    'subdivision-GB-E45': {
      id: 'app.countrySubdivision.GB-E45',
      defaultMessage: enDefaults['app.countrySubdivision.GB-E45']
    },
    'subdivision-GB-N00': {
      id: 'app.countrySubdivision.GB-N00',
      defaultMessage: enDefaults['app.countrySubdivision.GB-N00']
    },
    'subdivision-GB-N01': {
      id: 'app.countrySubdivision.GB-N01',
      defaultMessage: enDefaults['app.countrySubdivision.GB-N01']
    },
    'subdivision-GB-N02': {
      id: 'app.countrySubdivision.GB-N02',
      defaultMessage: enDefaults['app.countrySubdivision.GB-N02']
    },
    'subdivision-GB-N03': {
      id: 'app.countrySubdivision.GB-N03',
      defaultMessage: enDefaults['app.countrySubdivision.GB-N03']
    },
    'subdivision-GB-N04': {
      id: 'app.countrySubdivision.GB-N04',
      defaultMessage: enDefaults['app.countrySubdivision.GB-N04']
    },
    'subdivision-GB-N05': {
      id: 'app.countrySubdivision.GB-N05',
      defaultMessage: enDefaults['app.countrySubdivision.GB-N05']
    },
    'subdivision-GB-N06': {
      id: 'app.countrySubdivision.GB-N06',
      defaultMessage: enDefaults['app.countrySubdivision.GB-N06']
    },
    'subdivision-GB-N011': {
      id: 'app.countrySubdivision.GB-N011',
      defaultMessage: enDefaults['app.countrySubdivision.GB-N011']
    },
    'subdivision-GB-N012': {
      id: 'app.countrySubdivision.GB-N012',
      defaultMessage: enDefaults['app.countrySubdivision.GB-N012']
    },
    'subdivision-GB-N021': {
      id: 'app.countrySubdivision.GB-N021',
      defaultMessage: enDefaults['app.countrySubdivision.GB-N021']
    },
    'subdivision-GB-N041': {
      id: 'app.countrySubdivision.GB-N041',
      defaultMessage: enDefaults['app.countrySubdivision.GB-N041']
    },
    'subdivision-GB-N042': {
      id: 'app.countrySubdivision.GB-N042',
      defaultMessage: enDefaults['app.countrySubdivision.GB-N042']
    },
    'subdivision-GB-N051': {
      id: 'app.countrySubdivision.GB-N051',
      defaultMessage: enDefaults['app.countrySubdivision.GB-N051']
    },
    'subdivision-GB-S00': {
      id: 'app.countrySubdivision.GB-S00',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S00']
    },
    'subdivision-GB-S01': {
      id: 'app.countrySubdivision.GB-S01',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S01']
    },
    'subdivision-GB-S02': {
      id: 'app.countrySubdivision.GB-S02',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S02']
    },
    'subdivision-GB-S03': {
      id: 'app.countrySubdivision.GB-S03',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S03']
    },
    'subdivision-GB-S04': {
      id: 'app.countrySubdivision.GB-S04',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S04']
    },
    'subdivision-GB-S05': {
      id: 'app.countrySubdivision.GB-S05',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S05']
    },
    'subdivision-GB-S06': {
      id: 'app.countrySubdivision.GB-S06',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S06']
    },
    'subdivision-GB-S07': {
      id: 'app.countrySubdivision.GB-S07',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S07']
    },
    'subdivision-GB-S08': {
      id: 'app.countrySubdivision.GB-S08',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S08']
    },
    'subdivision-GB-S09': {
      id: 'app.countrySubdivision.GB-S09',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S09']
    },
    'subdivision-GB-S10': {
      id: 'app.countrySubdivision.GB-S10',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S10']
    },
    'subdivision-GB-S11': {
      id: 'app.countrySubdivision.GB-S11',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S11']
    },
    'subdivision-GB-S12': {
      id: 'app.countrySubdivision.GB-S12',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S12']
    },
    'subdivision-GB-S13': {
      id: 'app.countrySubdivision.GB-S13',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S13']
    },
    'subdivision-GB-S14': {
      id: 'app.countrySubdivision.GB-S14',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S14']
    },
    'subdivision-GB-S15': {
      id: 'app.countrySubdivision.GB-S15',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S15']
    },
    'subdivision-GB-S16': {
      id: 'app.countrySubdivision.GB-S16',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S16']
    },
    'subdivision-GB-S17': {
      id: 'app.countrySubdivision.GB-S17',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S17']
    },
    'subdivision-GB-S18': {
      id: 'app.countrySubdivision.GB-S18',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S18']
    },
    'subdivision-GB-S19': {
      id: 'app.countrySubdivision.GB-S19',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S19']
    },
    'subdivision-GB-S20': {
      id: 'app.countrySubdivision.GB-S20',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S20']
    },
    'subdivision-GB-S21': {
      id: 'app.countrySubdivision.GB-S21',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S21']
    },
    'subdivision-GB-S22': {
      id: 'app.countrySubdivision.GB-S22',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S22']
    },
    'subdivision-GB-S23': {
      id: 'app.countrySubdivision.GB-S23',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S23']
    },
    'subdivision-GB-S24': {
      id: 'app.countrySubdivision.GB-S24',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S24']
    },
    'subdivision-GB-S25': {
      id: 'app.countrySubdivision.GB-S25',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S25']
    },
    'subdivision-GB-S26': {
      id: 'app.countrySubdivision.GB-S26',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S26']
    },
    'subdivision-GB-S27': {
      id: 'app.countrySubdivision.GB-S27',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S27']
    },
    'subdivision-GB-S28': {
      id: 'app.countrySubdivision.GB-S28',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S28']
    },
    'subdivision-GB-S29': {
      id: 'app.countrySubdivision.GB-S29',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S29']
    },
    'subdivision-GB-S30': {
      id: 'app.countrySubdivision.GB-S30',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S30']
    },
    'subdivision-GB-S31': {
      id: 'app.countrySubdivision.GB-S31',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S31']
    },
    'subdivision-GB-S141': {
      id: 'app.countrySubdivision.GB-S141',
      defaultMessage: enDefaults['app.countrySubdivision.GB-S141']
    },
    'subdivision-GB-W00': {
      id: 'app.countrySubdivision.GB-W00',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W00']
    },
    'subdivision-GB-W01': {
      id: 'app.countrySubdivision.GB-W01',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W01']
    },
    'subdivision-GB-W02': {
      id: 'app.countrySubdivision.GB-W02',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W02']
    },
    'subdivision-GB-W03': {
      id: 'app.countrySubdivision.GB-W03',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W03']
    },
    'subdivision-GB-W04': {
      id: 'app.countrySubdivision.GB-W04',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W04']
    },
    'subdivision-GB-W05': {
      id: 'app.countrySubdivision.GB-W05',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W05']
    },
    'subdivision-GB-W06': {
      id: 'app.countrySubdivision.GB-W06',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W06']
    },
    'subdivision-GB-W07': {
      id: 'app.countrySubdivision.GB-W07',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W07']
    },
    'subdivision-GB-W08': {
      id: 'app.countrySubdivision.GB-W08',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W08']
    },
    'subdivision-GB-W09': {
      id: 'app.countrySubdivision.GB-W09',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W09']
    },
    'subdivision-GB-W10': {
      id: 'app.countrySubdivision.GB-W10',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W10']
    },
    'subdivision-GB-W11': {
      id: 'app.countrySubdivision.GB-W11',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W11']
    },
    'subdivision-GB-W12': {
      id: 'app.countrySubdivision.GB-W12',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W12']
    },
    'subdivision-GB-W13': {
      id: 'app.countrySubdivision.GB-W13',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W13']
    },
    'subdivision-GB-W14': {
      id: 'app.countrySubdivision.GB-W14',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W14']
    },
    'subdivision-GB-W15': {
      id: 'app.countrySubdivision.GB-W15',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W15']
    },
    'subdivision-GB-W16': {
      id: 'app.countrySubdivision.GB-W16',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W16']
    },
    'subdivision-GB-W17': {
      id: 'app.countrySubdivision.GB-W17',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W17']
    },
    'subdivision-GB-W18': {
      id: 'app.countrySubdivision.GB-W18',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W18']
    },
    'subdivision-GB-W19': {
      id: 'app.countrySubdivision.GB-W19',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W19']
    },
    'subdivision-GB-W20': {
      id: 'app.countrySubdivision.GB-W20',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W20']
    },
    'subdivision-GB-W21': {
      id: 'app.countrySubdivision.GB-W21',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W21']
    },
    'subdivision-GB-W22': {
      id: 'app.countrySubdivision.GB-W22',
      defaultMessage: enDefaults['app.countrySubdivision.GB-W22']
    },
    keyword: {
      id: 'app.partySearch.routes.item.keyword.param',
      defaultMessage: enDefaults['app.partySearch.routes.item.keyword.param']
    },
    noFiltersSelected: {
      id: 'app.search.filter.noSelected',
      defaultMessage: enDefaults['app.search.filter.noSelected']
    }
  });
};

const prepareExpertReviewMessages = () => {
  let expertReviewMessages;
  const getExpertReviewMessages = () => {
    if (expertReviewMessages) {
      return expertReviewMessages;
    }
    // this will throw if we don't have the default translation loaded
    const enDefaults = getDefaultTranslation();
    expertReviewMessages = defineExpertReviewMessages(enDefaults);
    return expertReviewMessages;
  };
  return getExpertReviewMessages;
};

export const getExpertReviewMessages = prepareExpertReviewMessages();
