import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './light.css';
import './styles.css';
import {useIntl} from 'react-intl';
import { getMessages } from '../../../../../tppServices/translations/messages';

const FilterHeaderContainer = (props) => {
  return (
    <div className="filter-header-container">
      <div className={classnames('filter-header', { sticky: props.sticky, [`filter-header-${props.type}`]: props.type } ) }>
        {props.children}
      </div>
    </div>
  );
};

FilterHeaderContainer.propTypes = {
  children: PropTypes.node,
  sticky: PropTypes.bool,
  type: PropTypes.string,
};

const FilterButton = (props) => {
  const intl = useIntl();
  const messages = getMessages();
  const t = intl.formatMessage;
  return (
    <button className="filter-button" onClick={props.onClick}>
      {t(messages.filter)}
      <span className="count">{props.count}</span>
    </button>
  );
};

FilterButton.propTypes = {
  count: PropTypes.number,
  onClick: PropTypes.func,
};

export {
  FilterHeaderContainer,
  FilterButton
};
