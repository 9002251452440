import React from 'react';
import PropTypes from 'prop-types';
import Schema from '..';
import { getAggregateOfferSchema } from '../../../utils/metaContentHelper';
import { listingPropTypes } from '../../../utils/commonPropTypes';
import { withLanguageContext } from '../../../utils/language';

const VehicleSchema = (props) => {
  const schemaData = getAggregateOfferSchema(
    props.listing,
    props.totalListings,
    props.url,
    props.makeModel,
    props.metaName,
    props.metaDescription,
    props.minMaxPrices,
    props.languageContext?.host
  );

  return <Schema data={schemaData} />;
};

VehicleSchema.propTypes = {
  listing: listingPropTypes,
  makeModel: PropTypes.array,
  metaDescription: PropTypes.string,
  metaName: PropTypes.string,
  minMaxPrices: PropTypes.array,
  totalListings: PropTypes.number,
  url: PropTypes.string,
  languageContext: PropTypes.object
};

export default withLanguageContext(VehicleSchema);
