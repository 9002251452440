import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import LoadingPlaceholder from '../LoadingPlaceholder';
import { isOverlapped } from '../../utils';
import { RenderHTML } from '@dmm/react-common-components';

import './styles.css';
import { getMessages } from '../../tppServices/translations/messages';

const DescriptionBox = ({
  data,
  dataName,
  isLoading,
  multiItem,
  showMore: initialShowMore,
  small,
  tracking,
  customContact,
  intl: { formatMessage: t }
}) => {
  const [showMore, setShowMore] = useState(initialShowMore);
  const [overlap, setOverlap] = useState(false);
  const detailBoxRef = useRef(null);
  const messages = getMessages();
  useEffect(() => {
    const { current } = detailBoxRef;
    if (current && isOverlapped(current)) {
      setOverlap(true);
    }
  }, []);

  const toggleShowMore = () => {
    if (showMore && tracking) {
      tracking('show more');
    }
    setShowMore(!showMore);
  };

  const renderDescription = (descData) => (
    <>
      <RenderHTML html={descData} />
      {customContact && (
        <div className="custom-contact-information">{customContact}</div>
      )}
      <div className="more-less">
        <span className="toggle" tabIndex="0" onClick={toggleShowMore}>
          {showMore ? t(messages.showMore) : t(messages.showLess)}
        </span>
      </div>
    </>
  );

  const renderMultipleDescription = (descData) =>
    descData.map((description, index) => (
      <div className="description-item" key={index}>
        <div className="title">
          <h3>{description.title}</h3>
        </div>
        <RenderHTML html={description.value} />
      </div>
    ));

  const renderLoading = () => (
    <>
      <LoadingPlaceholder type="text" />
      <LoadingPlaceholder type="text" />
    </>
  );

  return (
    <div
      id="description-box"
      ref={detailBoxRef}
      className={classnames('detail-description', {
        [dataName]: dataName,
        small,
        overlap,
        more: showMore,
        less: !showMore
      })}
    >
      {isLoading || !data
        ? renderLoading()
        : multiItem
        ? renderMultipleDescription(data)
        : renderDescription(data)}
    </div>
  );
};

DescriptionBox.defaultProps = {
  dataName: '',
  isLoading: false,
  multiItem: false,
  showMore: false,
  small: false,
  tracking: null
};

DescriptionBox.propTypes = {
  /** Data HTML to render */
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ),
    PropTypes.string
  ]).isRequired,
  /** Data CSS class name */
  dataName: PropTypes.string,
  /** Translations */
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  /** Flag for loading */
  isLoading: PropTypes.bool,
  /** Flag for multiple data items or a single item */
  multiItem: PropTypes.bool,
  /** Show more flag initial state */
  showMore: PropTypes.bool,
  /** Sets a smaller size of content to show when reading more is active */
  small: PropTypes.bool,
  /** Analytics tracking helper */
  tracking: PropTypes.func,
  customContact: PropTypes.string
};

export default injectIntl(DescriptionBox);
