import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { NumberRangeForm } from '@dmm/lib-react-ui-components';
import { getMessages } from '../../../../../tppServices/translations/messages';
import { useCurrency, trackMinMaxValuesFromForm } from '../../RefineSearch/FilterHelpers/useSRPFilters';


const FilterPriceForm = ({ handleDataChange, min = '', max = '', userCurrency }) => {
  const messages = getMessages();
  const { formatMessage: t } = useIntl();
  const srpCurrency =  useSelector( state => state?.app?.currency || '' );

  useEffect(() => {
    srpCurrency && handleDataChange('currency', srpCurrency);
  }, [srpCurrency]);

  const { selectedCurrency } = useCurrency(userCurrency, min, max);
  const currency = userCurrency || selectedCurrency;

  return (
    <NumberRangeForm
      key={ currency }
      splitter={ t(messages.till) }
      price={ true }
      currency={ currency }
      currencyPrefix={ true }
      min={{
        id: "min-price-input",
        label: "min-price-input",
        name: "min-price-input",
        placeholder: t(messages.quickSearch.min),
        value: min,
      }}
      max={{
        id: "max-price-input",
        label: "max-price-input",
        name: "max-price-input",
        placeholder: t(messages.quickSearch.max),
        value: max,
      }}
      buttonText={ t(messages.go) }
      validate={ ({ min, max }) => {
        const errors = {};
        if ((max !== '') && (+min > +max)) {
          errors.max = t(messages.priceMaxError);
        }

        return errors;
      }}
      onSubmit={(values) => {
        handleDataChange('price', values);
        trackMinMaxValuesFromForm(values, 'price');
      }}
      buttonAttributes={{
        'data-reporting-click-internal-link-id': 'price filter',
        'data-reporting-click-internal-link-type': 'click'
      }}
    />
  );
};

FilterPriceForm.propTypes = {
  max: PropTypes.string,
  min: PropTypes.string,
  handleDataChange: PropTypes.func,
  userCurrency: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};

export default FilterPriceForm;
