import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const BrokerSpotlightCard = ({
  brokerInfo: {
    brokerCardMode,
    brokerImageUrl,
    brokerageLogoUrl,
    brokerageUrl,
    brokerName,
    parentName,
    contactButtonText = 'Contact',
    listingsButtonText = 'See My Listings',
    brokerListingsUrl,
    contactButtonClick,
    listingsButtonClick = () => { window.open(brokerListingsUrl, '_blank'); },
    listingsButtonToHref = false,
    premiumBroker
  }
}) => {
  const [brokerImage, setBrokerImage] = useState(brokerImageUrl);
  const [brokerageLogo, setBrokerageLogo] = useState(brokerageLogoUrl);
  const getContactButton = () => {
    return <button id="broker-spotlight-contact-button" onClick={contactButtonClick}>{contactButtonText}</button>;
  };
  const getListingsButton = () => {
    if (listingsButtonToHref) {
      return <a href={brokerListingsUrl} title={listingsButtonText} target="_blank" rel="noreferrer">{listingsButtonText}</a>;
    }
    return <button onClick={listingsButtonClick}>{listingsButtonText}</button>;
  };

  return <div className="broker-card">
    <div className="broker-image">
      {brokerImage && <img src={brokerImage} alt="Broker Profile Image" onError={() => setBrokerImage(undefined)}/>}
      {!brokerImage && <div alt="Broker Without Profile Image" className="no-profile-image" />}
      <div className="broker-image-gradient"></div>
    </div>
    <div className="broker-card-content">
      <div className="broker-card-header">
        <div className="broker-card-title">
          <span className="broker-name">{brokerName}</span>
          <a href={brokerageUrl} className="broker-parent-name">{parentName}</a>
          { premiumBroker &&
          <div className="premium-broker">
            <span className="premium-broker-specialized-in">{premiumBroker.specializedInText} {premiumBroker.specializedIn}</span>
            <span className="premium-broker-markets-served">{premiumBroker.marketsServedText} {premiumBroker.marketsServed}</span>
          </div>
          }
        </div>
        {brokerageLogo && <div className="brand-logo-divider"></div>}
        {brokerageLogo && <div className="brand-logo">
          <img src={brokerageLogo} alt="Brand Logo" onError={() => setBrokerageLogo(null)} />
        </div>}
      </div>
      <div className="broker-card-action">
        <div className="broker-card-button">{getContactButton()}</div>
        {brokerCardMode !== 'listing' && <div className="broker-card-listings">{getListingsButton()}</div>}
      </div>
    </div>
  </div>;
};

BrokerSpotlightCard.displayName = 'BrokerSpotlightCard';
BrokerSpotlightCard.propTypes = {
  brokerInfo: PropTypes.shape({
    brokerCardMode: PropTypes.oneOf(['contact', 'listing', 'ad']).isRequired,
    brokerImageUrl: PropTypes.string,
    brokerageLogoUrl: PropTypes.string,
    brokerName: PropTypes.string.isRequired,
    brokerageUrl: PropTypes.string,
    contactButtonText: PropTypes.string,
    listingsButtonText: PropTypes.string,
    brokerListingsUrl: PropTypes.string,
    contactButtonClick: PropTypes.func,
    listingsButtonClick: PropTypes.func,
    listingsButtonToHref: PropTypes.bool,
    parentName: PropTypes.string,
    brokerListings: PropTypes.arrayOf(PropTypes.shape({
      listingId: PropTypes.number,
      listingDetailUrl: PropTypes.string,
      listingImageUrl: PropTypes.string
    })),
    premiumBroker: PropTypes.shape({
      marketsServedText: PropTypes.string,
      marketsServed: PropTypes.string,
      specializedInText: PropTypes.string,
      specializedIn: PropTypes.string
    })
  }).isRequired,
};

export default BrokerSpotlightCard;
