import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ToolSetOptions, ToolSetOptionsItem } from '../../../../../../components/ToolSet';
import { FilterTypeAhead } from '../..';
import { generateSearchPath } from '../../../../../../utils/urlHelpers/boats';
import Link from '../../../../../../components/SEO/Link';

import {getBoatConstantsFromI18n} from '../../../../../../tppServices/translations/constants';
import {slugify} from '../../../../../../utils/urlHelpers/shared';
import { getMessages } from '../../../../../../tppServices/translations/messages';

class FilterCountry extends PureComponent {
  state = { country: undefined };

  updateMultiInput() {
    if (get(this.props, 'selectedCountry')) {
      this.setState({ country: this.props.selectedCountry });
    } else {
      this.setState({ country: 'all' });
    }
  }

  componentDidMount() {
    this.updateMultiInput();
  }

  componentDidUpdate() {
    this.updateMultiInput();
  }

  handleDataChange(countryCode){
    if (this.props.tracking) {
      this.props.tracking.facetAdded({ 'country': countryCode });
    }
    this.props.handleDataChange('country', countryCode);
  }

  render() {
    const messages = getMessages();
    const boatConstants = getBoatConstantsFromI18n();
    const { countries, maxCountriesCount = boatConstants.MAX_COUNTRIES_COUNT, params, position} = this.props;
    const { intl: { formatMessage: t } } = this.props;
    const selectedCountry = get(this.state, 'country', {});
    const showAllCountries = get(params, 'modal', []).includes('country');
    const countriesWithNameTranslation = countries.map(country =>
      ({ value: country.value, filterValue: t(messages.countries[country.value])}));

    return <div className="search-filter countries">
      <FilterTypeAhead
        items={countriesWithNameTranslation}
        selectedItems={[slugify(selectedCountry)]}
        id={`country-type-ahead-${position}`}
        placeholder={t(messages.countryPlaceholder)}
        moreMessage="moreCountries"
        max={maxCountriesCount}
        url={generateSearchPath(params) + 'modal-country/'}
        showAll={showAllCountries}
        render={(items, selectionCallback) =>
          <ToolSetOptions>
            { items.map(country => {
              const countryName = t(messages.countries[country.value]);

              return <ToolSetOptionsItem
                key={`Country-${country.value}`}
                id={`Country-${country.value}`}
                name={`Country-${country.value}`}
                value={countryName}
                selected={ selectedCountry === country.value}
                onClick={(value) => {
                  this.handleDataChange(country.value);
                  selectionCallback(value);
                }}
              >
                <Link className="country-type-link" href={generateSearchPath({country: country.value, city: []}, params, true)} onClick={(e) => e.preventDefault()}>
                  {countryName}
                </Link>
              </ToolSetOptionsItem>;
            })
            }
          </ToolSetOptions>
        }
        renderStatic={() =>
          <ToolSetOptions>
            <ToolSetOptionsItem
              key="country-all"
              id="country-all"
              name="country-all"
              value="all"
              selected={selectedCountry === 'all'}
              onClick={() => {
                this.handleDataChange('');
              }}
            >
              <Link className="country-type-link" href={generateSearchPath({country: '', city: []}, params, true)} onClick={(e) => e.preventDefault()}>
                {t(messages.allCountries)}
              </Link>
            </ToolSetOptionsItem>
          </ToolSetOptions>}/>
    </div>;
  }
}

FilterCountry.propTypes = {
  selectedCountry: PropTypes.string,
  countries: PropTypes.array,
  maxCountriesCount: PropTypes.number,
  params: PropTypes.object,
  position: PropTypes.string,
  handleDataChange: PropTypes.func,
  tracking: PropTypes.object,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired
};

export default injectIntl(FilterCountry);
