import React from 'react';
import { useSelector } from 'react-redux';
import {MinMaxInput} from '../FilterSections/MinMaxInput';
import {
  SRP_LITERALS,
  useMinMaxLabels, useMinMaxTypers, defaultGetMinMax, useDelayedTyping
} from '../../RefineSearch/FilterHelpers/useSRPFilters';
import { filtersLoading } from '../utils';
import PropTypes from 'prop-types';
import { BREAKPOINTS } from '../../../../../utils/commonHelper';
import { getMessages } from '../../../../../tppServices/translations/messages';

const {MIN, MAX} = SRP_LITERALS;

const FilterYear = ({handleDataChange, isThreeColumnLayout, min = '', max = '', position }) => {
  const messages = getMessages();
  const filterNameKey = 'year';
  const {placeholderMin, placeholderMax, titleMin, titleMax, messageTo} = useMinMaxLabels(isThreeColumnLayout, messages, filterNameKey);
  const {onType, onTypeEnd, minRef, maxRef, disabledMinMax} = useMinMaxTypers(handleDataChange, filterNameKey, defaultGetMinMax, min, max);
  const {onStopTyping} = useDelayedTyping(onType, onTypeEnd);

  const isDesktop = position === BREAKPOINTS.desktop;

  const isWorking =  useSelector( state => state?.app?.isWorking || false );
  const componentWorking = useSelector( state => state?.app?.componentWorking || []);
  const isLoading = filtersLoading(isWorking, componentWorking);

  return (
    <div className="search-filter year">
      <div className="search-filter-group">
        <MinMaxInput
          filterName={filterNameKey}
          prefix={MIN}
          placeholder={placeholderMin}
          value={min}
          title={titleMin}
          cssClass={'year-min'}
          onChange={onStopTyping}
          ref={minRef}
          disabled={ isDesktop && (disabledMinMax === MAX || isLoading) }
        />
        <span>{messageTo}</span>
        <MinMaxInput
          filterName={filterNameKey}
          prefix={MAX}
          placeholder={placeholderMax}
          value={max}
          title={titleMax}
          cssClass={'year-max'}
          onChange={onStopTyping}
          ref={maxRef}
          disabled={ isDesktop && (disabledMinMax === 'min' || isLoading) }
        />
      </div>
    </div>
  );
};

FilterYear.propTypes = {
  isThreeColumnLayout: PropTypes.bool,
  max: PropTypes.string,
  min: PropTypes.string,
  cookies: PropTypes.object,
  customUom: PropTypes.shape({
    length: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    weight: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    speed: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    capacity: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    distance: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    radius: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  }),
  handleDataChange: PropTypes.func,
  position: PropTypes.string,
};

export default FilterYear;
