import React from 'react';
import {generateSearchPath} from '../../../../../../../utils/urlHelpers/boats';
import RegionToolSetItem from './RegionToolSetItem';
import PropTypes from 'prop-types';

const Region = ({
  region, selectedRegion, params, intl, translations, onItemClick,
  urlGenerator = generateSearchPath}) => {
  const messages = translations;
  const t = intl.formatMessage;
  const pathArgs = {region: region.value};
  const linkHref = urlGenerator(pathArgs, params, true);
  const linkName = region.value === 'all' ? t(messages.allRegions) : t(messages.countryRegions[region.value]);
  return (
    <RegionToolSetItem
      region={region}
      onItemClick={onItemClick}
      isSelected={selectedRegion === region.value}
      linkHref={linkHref}
      linkName={linkName}
    />
  );
};

Region.propTypes = {
  region: PropTypes.object,
  selectedRegion: PropTypes.string,
  chosenRegion: PropTypes.string,
  params: PropTypes.object,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  translations: PropTypes.object,
  onItemClick: PropTypes.func,
  urlGenerator: PropTypes.func
};

export default Region;
