import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import { ToolSetTextInput } from '../../../../../components/ToolSet';
import { injectIntl } from 'react-intl';

import { normalizeString } from '../../../../../utils/commonHelper';
import {slugify} from '../../../../../utils/urlHelpers/shared';
import { getMessages } from '../../../../../tppServices/translations/messages';

class FilterTypeAhead extends PureComponent {
  state={
    typeAheadText: '',
    showAll: this.props.showAll || false
  }

  typeAheadMatchesValue = (typeAheadText, item) => {
    const filterValue = item.filterValue || item.value;
    return normalizeString(filterValue).toLowerCase().includes(normalizeString(typeAheadText).toLowerCase());
  };

  handleTypeAhead(value){
    this.setState({ typeAheadText: value });
  }
  handleShowAll(){
    this.setState({ showAll: true });
  }
  handleSelection(){
    this.setState({ typeAheadText: '' });
  }
  render(){
    const {
      items = [],
      selectedItems = [],
      render,
      renderStatic,
      id,
      moreMessage,
      placeholder,
      max,
      url,
      intl: { formatMessage: t },
      otherItemsSortBy = 'value'
    } = this.props;
    const messages = getMessages();
    const { typeAheadText, showAll } = this.state;
    const cleanedItems = items.filter(item => item.value.length >= 1 && !/^[^a-zA-Z0-9]+$/.test(item.value));
    const topItems = cleanedItems
      .filter((item, index) => index < max && !typeAheadText);
    const selectedItemsNotTopItems = cleanedItems
      .filter(item => !typeAheadText
        && find(selectedItems, (selectedItem) => selectedItem === slugify(item.value), false)
        && !find(topItems, { value: item.value }));
    const filteredItems = cleanedItems
      .filter(item => typeAheadText
        && this.typeAheadMatchesValue(typeAheadText, item))
      .slice(0, max);
    const otherItems = sortBy(cleanedItems
      .filter(item =>
        typeAheadText ?
          !this.typeAheadMatchesValue(typeAheadText, item) :
          !find(selectedItemsNotTopItems, { value: item.value }))
      .filter((item, index) => index >= max), otherItemsSortBy);
    return <div className="filter-typeahead">
      <ToolSetTextInput
        id={id}
        name={id}
        icon={true}
        placeholder={placeholder}
        value={typeAheadText}
        onChange={this.handleTypeAhead.bind(this)} />
        <div className="filter-typeahead-items filter-typeahead-part">
          {renderStatic && renderStatic()}
          { !!selectedItemsNotTopItems.length && render(selectedItemsNotTopItems, (value) => this.handleSelection(value)) }
          { !!filteredItems.length && render(filteredItems, (value) => this.handleSelection(value)) }
          { !!topItems.length && render(topItems, (value) => this.handleSelection(value)) }
        </div>
      {
        <div className={classnames('show-more', { show: showAll, 'filter-typeahead-part': showAll })}>
          {
            showAll && render(otherItems, null, false)
          }
        </div>
      }
      <div className="filter-more">{ !!otherItems.length && !showAll &&
          <a href={url} onClick={(e) => { e.preventDefault(); this.handleShowAll(); }}>
            {t(messages[moreMessage], {count: otherItems.length})}
          </a>
      }</div>
    </div>;
  }
}

FilterTypeAhead.propTypes = {
  id: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    filterValue: PropTypes.string,
  })),
  max: PropTypes.number,
  /** More... label */
  moreMessage: PropTypes.oneOf(['moreMakes', 'moreModels', 'moreSubdivisions','moreCities', 'moreCountries', 'moreRegions', 'more']).isRequired,
  placeholder: PropTypes.string,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  showAll: PropTypes.bool,
  render: PropTypes.func,
  renderStatic: PropTypes.func,
  url: PropTypes.string,
  otherItemsSortBy: PropTypes.string,
};

export default injectIntl(FilterTypeAhead);
