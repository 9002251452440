import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {injectIntl} from 'react-intl';
import {ToolSetOptions} from '../../../../../../../components/ToolSet';
import {CollapsibleContent} from '../../../../../../../components/Collapsible';
import {FilterTypeAhead} from '../../..';
import {generateSearchPath} from '../../../../../../../utils/urlHelpers/boats';

import {getConfig} from '../../../../../../../config/portal';
import {subdivisionsWithName} from '../../../../../utils/locations';
import Subdivision from './Subdivision';
import { yieldToMain } from '../../../../../../../utils';

import {useTPPServices} from '../../../../../../../tppServices/tppDIHooks';
import { getMessages } from '../../../../../../../tppServices/translations/messages';

const optionsRenderer = (intl, params, onItemClick, selectedSubdivision, chosenCountry, chosenRegion, translations) => {
  const renderer = (items) => {
    const subdivisionItems = items.map((subdivision, i) => {
      return (
        <Subdivision subdivision={subdivision}
          selectedSubdivision={selectedSubdivision}
          translations={translations}
          chosenCountry={chosenCountry}
          chosenRegion={chosenRegion}
          params={params}
          intl={intl}
          onItemClick={onItemClick}
          key={i}
        />
      );}
    );
    return (
      <div className="search-filter">
        <div className="subdivisions">
          <ToolSetOptions>
            {subdivisionItems}
          </ToolSetOptions>
        </div>
      </div>
    );
  };
  return renderer;
};

const FilterSubdivision = ({
  subdivisions, intl, position, tracking, handleDataChange, device,
  translations = getMessages(), params = {}, subdivision = '',
  chosenCountry = get(getConfig(), 'country'), chosenRegion,
  maxSubdivisionsCount,
  urlGenerator = generateSearchPath,
  sortSubdivisions = subdivisionsWithName
}) => {
  const [selectedSubdivision, setSelectedSubdivision] = useState(subdivision);
  const dispatch = useDispatch();
  const {boatsConstants} = useTPPServices();
  maxSubdivisionsCount = maxSubdivisionsCount ?? boatsConstants.MAX_SUBDIVISIONS_COUNT;
  useEffect(() => {
    setSelectedSubdivision(subdivision || 'all');
  }, [subdivision]);

  const onItemClick = async (value) => {
    if (tracking) {
      tracking.facetAdded({'subdivision': value});
    }
    await yieldToMain();
    if (device !== 'mobile') {
      dispatch({type: 'GET_DATA_LOADING'});
    }
    handleDataChange('subdivision', value);
  };

  const t = intl.formatMessage;
  const sortedSubdivisions = sortSubdivisions(subdivisions, chosenCountry, translations, intl);
  const showAll = get(params, 'modal', []).includes('subdivision');
  const rendererFunction =
    optionsRenderer(intl, params, onItemClick, selectedSubdivision, chosenCountry, chosenRegion, translations);
  return (
    <div className={'search-filter subdivisions'}>
      <CollapsibleContent initialState="open">
        <FilterTypeAhead
          items={sortedSubdivisions}
          selectedItems={[selectedSubdivision]}
          id={`Subdivision-${position}`}
          moreMessage="moreSubdivisions"
          placeholder={`${t(translations.subdivisionsPlaceholder)}`}
          max={maxSubdivisionsCount}
          render={rendererFunction}
          showAll={showAll}
          url={urlGenerator(params) + 'modal-subdivision/'}
          otherItemsSortBy="filterValue"
        />
      </CollapsibleContent>
    </div>
  );
};

FilterSubdivision.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  chosenCountry: PropTypes.string,
  device: PropTypes.string,
  chosenRegion: PropTypes.string,
  subdivision: PropTypes.string,
  subdivisions: PropTypes.array,
  maxSubdivisionsCount: PropTypes.number,
  params: PropTypes.object,
  position: PropTypes.string,
  handleDataChange: PropTypes.func,
  urlGenerator: PropTypes.func,
  tracking: PropTypes.shape({
    facetAdded: PropTypes.func
  }),
  translations: PropTypes.object,
  sortSubdivisions: PropTypes.func
};

export default injectIntl(FilterSubdivision);
