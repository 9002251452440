import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import SortBy from '../SortBy';
import { ToolSet, ToolSetInner } from '../../../../components/ToolSet';
import {
  generateSearchPath,
  getDefaultParams
} from '../../../../utils/urlHelpers/boats';
import {
  generateBrandedOemSearchPath,
  getDefaultParams as getDefaultOemParams
} from '../../../../utils/urlHelpers/oem';
import * as utils from '../../../../utils/scrollTo';
import './styles.css';

export default class SortSelector extends PureComponent {
  handleSortChange(data) {
    let urlParams = get(this.props, 'match.params', {});
    let params = this.props.isBranded
      ? getDefaultOemParams(urlParams)
      : getDefaultParams(urlParams);
    const urlGeneratorFunction = this.props.isBranded
      ? generateBrandedOemSearchPath
      : generateSearchPath;
    this.props.history.push(urlGeneratorFunction({ sort: data }, params, true));
    if (this.props.scrollTo) {
      utils.scrollTo(this.props.scrollTo, { behavior: 'smooth' });
    }
  }

  render() {
    return (
      <div id="sort" className="search-tools sort custom-select">
        <ToolSet>
          <ToolSetInner>
            <SortBy
              initialState="closed"
              closeOnOuterClick={true}
              closeOnInnerClick={true}
              {...this.props}
              onDataChange={(data) => this.handleSortChange(data)}
            />
          </ToolSetInner>
        </ToolSet>
      </div>
    );
  }
}

SortSelector.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  scrollTo: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.string]),
  isBranded: PropTypes.bool
};
