import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import pull from 'lodash/pull';

import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import keys from 'lodash/keys';
import slice from 'lodash/slice';

import head from 'lodash/head';
import replace from 'lodash/replace';
import capitalize from 'lodash/capitalize';
import reduce from 'lodash/reduce';


import { getDefaultParams, getActiveParams, generateSearchPath, hyphenateUrlComponents } from '../../../../utils/urlHelpers/boats';
import { unhyphenateUrlComponents } from '../../../../utils/commonHelper';
import { toggleSubFacet } from '../../../../utils/multiFacetHelper';
import { capitalizeEachWord, normalizeString } from '@dmm/lib-common/lib/formatting';

import { injectIntl } from 'react-intl';
import { getConfig } from '../../../../config/portal';

// Stylesheets
import './styles.css';
import { getMessages } from '../../../../tppServices/translations/messages';

const List = ({ title, link, count, t }) => {
  const messages = getMessages();
  return (
    <div className="related__list_item" key={title}>
      <a href={link} >{title}</a>
      <span>{t(messages.listings, {count})}</span>
    </div>
  );
};

List.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired
};

class RelatedContent extends PureComponent {
  constructor(props) {
    super(props);
    this.translationMessages = getMessages();
  }

  setNoPageType = () => {
    return {
      pageType: ''
    };
  }

  selectTypeOrClassPage = (classString, t) => {
    const messages = this.translationMessages;
    if (classString === 'power-all' || classString === 'sail-all' || classString === 'unpowered-all'){
      return {
        pageType: 'type',
        pageValue: replace(classString, '-all', ''),
        conditionTitle: t(messages[replace(classString, '-all', '')])
      };
    }
    return {
      pageType: 'class',
      pageValue: classString,
      conditionTitle: t(messages.classFacetValues[classString])
    };
  }

  selectMakeOrModelPage = (models) => {
    if (models.length === 1){
      return {
        pageType: 'model',
        pageValue: models[0],
        conditionTitle: capitalize(unhyphenateUrlComponents(models[0]))
      };
    } else if (models.length === 0) {
      return {
        pageType: 'make'
      };
    }
    return this.setNoPageType();
  }

  getPageType(t) {
    const { params } = this.props;
    const searchParams = omitBy(params, (value) => {return value === undefined;});
    let selectedParams = keys(searchParams);
    selectedParams = pull(selectedParams, 'make', 'model', 'makeModel', 'types', 'classes');
    if (selectedParams.length !== 0) {
      // If there is a param selected not related with type, class, model or make dont show the relatedContent component
      return this.setNoPageType();
    }
    const defaultParams = getDefaultParams(params);
    const currentParams = getActiveParams(defaultParams);
    const makeModel = get(currentParams, 'makeModel', {});
    const multiFacetedBoatTypeClass = get(currentParams, 'multiFacetedBoatTypeClass', {});
    const makeSelected = !isEmpty(makeModel);
    const typeSelected = !isEmpty(multiFacetedBoatTypeClass);
    if (makeSelected && typeSelected){
      return this.setNoPageType();
    } else if (typeSelected) {
      const multiFacetedBoatTypeClassKeys = Object.keys(multiFacetedBoatTypeClass);
      if (multiFacetedBoatTypeClassKeys.length === 1){
        //only one type
        const classes = multiFacetedBoatTypeClass[multiFacetedBoatTypeClassKeys[0]];
        if (classes.length === 1) {
          return this.selectTypeOrClassPage(classes[0],t);
        }
        return this.setNoPageType();

      }
      return this.setNoPageType();

    }
    const makeModelKeys = Object.keys(makeModel);
    if (makeModelKeys.length === 1){
      // only one make selected
      const models = makeModel[makeModelKeys[0]];
      return this.selectMakeOrModelPage(models);
    }
    return this.setNoPageType();


  }

  renderConditionListItem = (condition, make, contentOptions, t, defaultParams) => {
    const title = get(condition, 'value', '');
    const count = get(condition, 'count', '');
    const messages = this.translationMessages;

    const conditionTranslation = t(messages.relatedContent.boats.listingConditions[title]);
    const typeTanslation = contentOptions.conditionTitle;
    const listingConditionMessage = contentOptions.pageType === 'model' ?
      messages.relatedContent.boats.listingConditionMake :
      messages.relatedContent.boats.listingCondition;
    const link = generateSearchPath({'condition': title}, defaultParams, true);

    return (
      <div className="related__list_item" key={title}>
        <a href={link}>{t(listingConditionMessage, { condition: conditionTranslation, type: typeTanslation, make })}</a>
        <span>{t(messages.listings, { count })}</span>
      </div>
    );
  }

  renderTypeListItem = (type, make, defaultParams, t) => {
    const title = get(type, 'value', '');
    const count = get(type, 'count', '');
    const classAll = title + '-all';
    const messages = this.translationMessages;
    const link = generateSearchPath({'multiFacetedBoatTypeClass': {[title]: [classAll]}}, defaultParams, true);
    const titleTranslation = t(messages[title]);
    const makeTypeMessage = t(messages.relatedContent.boats.listingTypeMake, { make, type: titleTranslation });

    return (
      <List
        key={title}
        link={link}
        title={makeTypeMessage}
        count={count}
        t={t}
      />
    );
  }


  render () {
    const { facets, params, intl: { formatMessage: t } } = this.props;
    const messages = this.translationMessages;
    const defaultParams = getDefaultParams(params);
    let contentOptions = this.getPageType(t);
    if (contentOptions.pageType === 'make') {
      const makeModel = get(facets, 'makeModel', { value: ''});
      const allModels = head(makeModel);
      const title = omit(allModels, ['count', 'model']);
      const make = this.props.seoMake || get(title, 'value', '');
      if (make !== 'other'){
        contentOptions.pageValue = make;
        contentOptions.conditionTitle = make;
      } else {
        contentOptions.pageType = '';
      }
    }
    const makeModel = get(facets, 'makeModel', { value: ''});
    const types = get(facets, 'type', {});
    const conditions = get(facets, 'condition', {});
    const allModels = head(makeModel);
    const title = omit(allModels, ['count', 'model']);
    const allMakes = get(facets, 'make', []);
    const makes = allMakes.filter((obj) => obj.value !== 'other').slice(0, 10);
    const models = slice(get(allModels, 'model', []), 0, 10);
    const allModelsLower = get(allModels, 'modelRange', []).map( item => {
      return {
        value: item.value.toLowerCase(),
        count: item.count
      };
    });
    const modelRanges = slice(
      allModelsLower
        .filter( (modelRange, index) => modelRange.value !== 'other' &&
        map(allModelsLower,'value').indexOf(modelRange.value) === index),
      0,10);
    const allClasses = get(facets, 'class', []);
    const typeClasses = reduce(allClasses, (result, obj) => {
      if (obj.value.startsWith(contentOptions.pageValue)) {
        result.push(obj);
      }
      return result;
    },[]);
    const classes = slice(typeClasses, 0, 10);
    const titleMake = get(title, 'value', '');
    const make =  this.props.seoMake || titleMake;
    let slugMake = normalizeString(hyphenateUrlComponents(titleMake));
    const config = get(getConfig(), 'pages.searchResults.relatedContent', { condition: true, make: true, model: true, modelRange: true, type: true });

    return (
      <>
        {
          contentOptions.pageType !== '' &&
          <div className="related__content">
            {
              config.type && contentOptions.pageType === 'make' &&
              <div className="related__type">
                <h2 className="by-type">{t(messages.relatedContent.boats.headerType, { make })}</h2>

                {map(types, type => this.renderTypeListItem(type, make, defaultParams, t))}
              </div>
            }
            {
              config.condition &&
              <div className="related__condition">
                <h2 className="by-condition">
                  {t(
                    contentOptions.pageType === 'model' ? messages.relatedContent.boats.headerConditionMake : messages.relatedContent.boats.headerCondition, {
                      type: contentOptions.conditionTitle,
                      make: make
                    })}
                </h2>

                {
                  map(
                    conditions,
                    (condition) => this.renderConditionListItem(condition, make, contentOptions, t, defaultParams)
                  )
                }
              </div>
            }
            {
              config.model && contentOptions.pageType === 'type' &&
              <div className="related__class">
                <h2 className="by-class">{t(messages.relatedContent.boats.headerClasses, { type: t(messages[contentOptions.pageValue]).toLowerCase() })}</h2>

                {map(classes, classB => {
                  const value = get(classB, 'value', '');
                  const title = t(messages.classFacetValues[value]);
                  const count = get(classB, 'count', '');
                  const link = generateSearchPath({'multiFacetedBoatTypeClass': {[contentOptions.pageValue]: [value]}}, defaultParams, true);


                  return (
                    <List
                      key={title}
                      link={link}
                      title={`${make} ${title}`}
                      count={count}
                      t={t}
                    />
                  );
                })}
              </div>
            }
            {
              config.make && contentOptions.pageType === 'class' &&
              <div className="related__make">
                <h2 className="by-make">{t(messages.relatedContent.boats.headerMake, { make: t(messages.classFacetValues[contentOptions.pageValue]).toLowerCase() })}</h2>

                {map(makes, make => {
                  const title = get(make, 'value', '');
                  let slugMake = normalizeString(hyphenateUrlComponents(title));
                  const count = get(make, 'count', '');
                  const link = generateSearchPath({ 'makeModel': toggleSubFacet(slugMake, '', {}, true) }, {}, true);
                  return (
                    <List
                      key={title}
                      link={link}
                      title={`${title}`}
                      count={count}
                      t={t}
                    />
                  );
                })}
              </div>
            }
            {
              contentOptions.pageType === 'make' &&
              <>
                {
                  config.modelRange && <div className="related__model_range">
                    <h2 className="by-model">{t(messages.relatedContent.boats.headerModelRange, {make: make})}</h2>

                    {map(modelRanges, modelRange => {
                      const title = capitalizeEachWord(get(modelRange, 'value', ''));
                      let slugModelRange = hyphenateUrlComponents(title);
                      const count = get(modelRange, 'count', '');
                      const link = generateSearchPath({ 'modelRange': slugModelRange}, defaultParams, true);
                      return (
                        <List
                          key={title}
                          link={link}
                          title={`${make} ${title}`}
                          count={count}
                          t={t}
                        />
                      );
                    })}
                  </div>
                }
                {
                  config.model && <div className="related__model">
                    <h2 className="by-model">{t(messages.relatedContent.boats.headerModel, {make: make})}</h2>

                    {map(models, model => {
                      const modelName = get(model, 'value', '');
                      const nonSeoMake = get(makeModel, [0, 'value'], '');
                      let slugModel = hyphenateUrlComponents(modelName);
                      const count = get(model, 'count', '');
                      const link = generateSearchPath({ 'makeModel': toggleSubFacet(slugMake, slugModel, {}, true) }, defaultParams, true);
                      return (
                        <List
                          key={modelName}
                          link={link}
                          title={`${nonSeoMake} ${modelName}`}
                          count={count}
                          t={t}
                        />
                      );
                    })}
                  </div>
                }
              </>
            }
          </div>
        }
      </>
    );
  }
}

RelatedContent.propTypes = {
  facets: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  seoMake: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired
};

export default injectIntl(RelatedContent);
