import get from 'lodash/get';
import pull from 'lodash/pull';
import isEmpty from 'lodash/isEmpty';

export const isSingleTypeOrClassFilter = (activeParams) => {
  if (onlyOneFilterApplied(activeParams, 'multiFacetedBoatTypeClass')) {
    const typeClass = get(activeParams, 'multiFacetedBoatTypeClass', {});
    const typeClassKeys = Object.keys(typeClass);
    if (typeClassKeys.length === 1) {
      const classes = typeClass[typeClassKeys[0]];
      if (classes.length === 1) {
        return true;
      }
    }
  }
  return false;
};

export const isSingleMakeFilter = (activeParams) => {
  if (onlyOneFilterApplied(activeParams, 'makeModel')) {
    const makeModel = get(activeParams, 'makeModel', {});
    const makeModelKeys = Object.keys(makeModel);
    if (makeModelKeys.length === 1) {
      const models = makeModel[makeModelKeys[0]];
      if (models.length === 0) {
        return true;
      }
    }
  }
  return false;
};

export const isGeoFilter = (activeParams) => {
  const country = get(activeParams, 'country', undefined);
  const region = get(activeParams, 'region', undefined);
  const subdivision = get(activeParams, 'subdivision', undefined);
  const city = get(activeParams, 'city', []);

  if (city && city.length === 1) {
    return true;
  } else if (city && city.length > 1) {
    return false;
  } else if (subdivision || region || country) {
    return true;
  }

  return false;
};

export const onlyOneFilterApplied = (activeParams, filterName) => {
  activeParams = removeEmptyMultiFacetedBoatTypeClass(activeParams);
  let selectedParams = Object.keys(activeParams);
  selectedParams = pull(selectedParams, filterName);
  return selectedParams.length === 0;
};

const removeEmptyMultiFacetedBoatTypeClass = (activeParams) => {
  if (isEmpty(activeParams.multiFacetedBoatTypeClass)) {
    delete activeParams.multiFacetedBoatTypeClass;
  }
  return activeParams;
};
