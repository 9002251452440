import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
// import { getCurrentRoutes as rts } from '../../../../../utils/language';

import { searchBrandedPathIsActive as brandedBoatsPathIsActive } from '../../../../../utils/urlHelpers/boats';
import { searchBrandedPathIsActive as brandedEnginesPathIsActive } from '../../../../../utils/urlHelpers/engines';

import './styles.css';
import {getRouteConstantsFromI18n} from '../../../../../tppServices/translations/constants';
import { getMessages } from '../../../../../tppServices/translations/messages';

class TypeChooserBranded extends PureComponent {
  buildTab(tabData, index) {
    return tabData.isActive
      ? (<span key={index} className="tab active">{ tabData.text }</span>)
      : (<a key={index} rel="nofollow" href={tabData.route} className="tab">{ tabData.text }</a>);
  }

  render() {
    const routes = getRouteConstantsFromI18n();
    const messages = getMessages();
    const {
      intl: { formatMessage: t },
      url,
      owner
    } = this.props;
    const tabs = [
      {
        isActive: brandedBoatsPathIsActive(url),
        text: t(messages.searchTypeBoats),
        route: routes.BRANDED_SEARCH_URL_ROOT + owner
      },
      {
        isActive: brandedEnginesPathIsActive(url),
        text: t(messages.searchTypeEngines),
        route: routes.ENGINES_BRANDED_SEARCH_URL_ROOT + owner
      }
    ];
    return (
      <div className="type-chooser">
        { tabs.map(this.buildTab) }
      </div>
    );
  }
}

TypeChooserBranded.propTypes = {
  url: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(TypeChooserBranded);
