import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pull from 'lodash/pull';
import keys from 'lodash/keys';
import head from 'lodash/head';
import capitalize from 'lodash/capitalize';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';

import {
  getDefaultParams,
  getActiveParams
} from '../../../../utils/urlHelpers/boats';

import {
  formatNumber,
  formatPriceWithDecimal
} from '@dmm/lib-common/lib/formatting';
import { injectIntl } from 'react-intl';
import {
  removeDuplicatedWords,
  unhyphenateUrlComponents
} from '../../../../utils/commonHelper';
import { getPortalName } from '../../../../utils/language';
import { getFaqPageSchema } from '../../../../utils/metaContentHelper';
import {
  getCurrency,
  getLengthName,
  getLocale
} from '../../../../utils/uomHelper';
import { PortalConfigContext } from '../../../../config/portal';
import Schema from '../../../../components/Schema';
import './styles.css';
import { getMessages } from '../../../../tppServices/translations/messages';

class DynamicContent extends PureComponent {
  setNoPageType = () => {
    return {
      pageType: ''
    };
  };

  selectClassMakeOrModelPage = (type, model) => {
    const messages = getMessages();
    const { facets, count, params, seoMakeInfo } = this.props;
    const makeModel = get(facets, 'makeModel', []);
    const allModels = head(makeModel);
    const title = omit(allModels, ['count', 'model']);
    const makeName = get(title, 'value', '');
    const seoMakeName = seoMakeInfo?.seoMakeName;
    const makeBrandName =
      makeName.toLowerCase() !== 'other' ? get(title, 'value', '') : undefined;
    const make = seoMakeName && type === 'make' ? seoMakeName : makeBrandName;
    const modelsList = get(allModels, 'model', []).slice();
    const classes = get(facets, 'class', []);
    const condition = get(facets, 'condition', []);
    const numNew = condition.find((obj) => {
      return obj.value === 'new';
    });
    const numUsed = condition.find((obj) => {
      return obj.value === 'used';
    });
    const minYear = get(facets, 'minYear', []);
    const maxYear = get(facets, 'maxYear', []);
    const minMaxPercentilPrices = get(facets, 'minMaxPercentilPrices', []);
    const minPrice = minMaxPercentilPrices.find((obj) => {
      return obj.value === 'minPrice';
    });
    const maxPrice = minMaxPercentilPrices.find((obj) => {
      return obj.value === 'maxPrice';
    });
    const minLength = get(facets, 'minLengthPercentil', []);
    const maxLength = get(facets, 'maxLengthPercentil', []);
    const activities = get(facets, 'activities', []);
    if (activities.length === 0) {
      activities.push({ value: 'other' });
    }
    const hullShape = get(facets, 'hullShape', []).filter((obj) => {
      return obj.value !== 'other';
    });
    const enginesConfiguration = get(facets, 'enginesConfiguration', []).filter(
      (obj) => {
        return obj.value !== 'other';
      }
    );
    const fuelType = get(facets, 'fuelType', []).filter((obj) => {
      return obj.value !== 'other';
    });
    switch (type) {
      case 'model': {
        const modelName = unhyphenateUrlComponents(model);
        //If current model is included in the top five elements in model aggregation it is removed.
        modelsList.splice(
          modelsList.findIndex(
            (item) => item.value.toLowerCase() === modelName
          ),
          1
        );
        const modelListString = modelsList.slice(0, 5).map((modelObj) => {
          return modelObj.value;
        });
        return {
          pageType: 'model',
          model: capitalize(modelName),
          make: make,
          class: classes.length > 0 ? classes[0].name.toLowerCase() : '',
          count: count,
          numNew: numNew ? numNew.count : '',
          numUsed: numUsed ? numUsed.count : '',
          numModels: this.generateEnumString(modelListString),
          minYear: minYear.length > 0 ? minYear[0].count : '',
          maxYear: maxYear.length > 0 ? maxYear[0].count : '',
          minPrice: minPrice ? Math.round(minPrice.count) : '',
          maxPrice: maxPrice ? Math.round(maxPrice.count) : '',
          activities: this.generateEnumString(
            this.generateTranslatedMessagesArray(
              messages.boatDetails.value.activities,
              activities.slice(0, 5)
            )
          ),
          hullShapes: this.generateEnumString(
            this.generateTranslatedMessagesArray(
              messages.boatDetails.value.hullShape,
              hullShape.slice(0, 5)
            )
          ),
          enginesConfigurations: this.generateEnumString(
            this.generateTranslatedMessagesArray(
              messages.boatDetails.value.engineType,
              enginesConfiguration.slice(0, 5)
            )
          ),
          fuelTypes: this.generateEnumString(
            this.generateTranslatedMessagesArray(
              messages.boatDetails.value.fuelType,
              fuelType.slice(0, 5)
            )
          )
        };
      }
      case 'make': {
        const modelListString = modelsList.slice(0, 5).map((modelObj) => {
          return modelObj.value;
        });
        const minPower = get(facets, 'minTotalHorsepowerPercentil', []);
        const maxPower = get(facets, 'maxTotalHorsepowerPercentil', []);
        return {
          pageType: 'make',
          make: make,
          models: modelListString,
          class: classes.length > 0 ? classes[0].name.toLowerCase() : '',
          count: count,
          numNew: numNew ? numNew.count : '',
          numUsed: numUsed ? numUsed.count : '',
          numModels: this.generateEnumString(modelListString),
          minYear: minYear.length > 0 ? minYear[0].count : '',
          maxYear: maxYear.length > 0 ? maxYear[0].count : '',
          minPrice: minPrice ? Math.round(minPrice.count) : '',
          maxPrice: maxPrice ? Math.round(maxPrice.count) : '',
          minLength: minLength.length > 0 ? minLength[0].count : '',
          maxLength: maxLength.length > 0 ? maxLength[0].count : '',
          minPower: minPower.length > 0 ? minPower[0].count : '',
          maxPower: maxPower.length > 0 ? maxPower[0].count : '',
          activities: this.generateEnumString(
            this.generateTranslatedMessagesArray(
              messages.boatDetails.value.activities,
              activities.slice(0, 5)
            )
          ),
          hullShapes: this.generateEnumString(
            this.generateTranslatedMessagesArray(
              messages.boatDetails.value.hullShape,
              hullShape.slice(0, 5)
            )
          ),
          enginesConfigurations: this.generateEnumString(
            this.generateTranslatedMessagesArray(
              messages.boatDetails.value.engineType,
              enginesConfiguration.slice(0, 5)
            )
          ),
          fuelTypes: this.generateEnumString(
            this.generateTranslatedMessagesArray(
              messages.boatDetails.value.fuelType,
              fuelType.slice(0, 5)
            )
          )
        };
      }
      case 'class': {
        const currentParams = getActiveParams(getDefaultParams(params));
        const typeClass = get(currentParams, 'multiFacetedBoatTypeClass', {});
        const typeClassKeys = Object.keys(typeClass);
        const avgTotalHorsepower = get(
          facets,
          'avgTotalHorsepowerPercentil',
          []
        );
        const makesList = get(facets, 'make', []);
        const makeListString = makesList.slice(0, 5).map((makeObj) => {
          return makeObj.value;
        });
        return {
          pageType: 'class',
          type: head(typeClassKeys),
          class: head(typeClass[head(typeClassKeys)]),
          activities: this.generateEnumString(
            this.generateTranslatedMessagesArray(
              messages.boatDetails.value.activities,
              activities.slice(0, 5)
            )
          ),
          avgTotalHorsepower:
            avgTotalHorsepower.length > 0 ? avgTotalHorsepower[0].count : '',
          minLength: minLength.length > 0 ? minLength[0].count : '',
          maxLength: maxLength.length > 0 ? maxLength[0].count : '',
          count: count,
          numNew: numNew ? numNew.count : '',
          numUsed: numUsed ? numUsed.count : '',
          minYear: minYear.length > 0 ? minYear[0].count : '',
          maxYear: maxYear.length > 0 ? maxYear[0].count : '',
          makes: this.generateEnumString(makeListString),
          numMakes: size(makesList),
          minPrice: minPrice ? Math.round(minPrice.count) : '',
          maxPrice: maxPrice ? Math.round(maxPrice.count) : '',
          hullShapes: this.generateEnumString(
            this.generateTranslatedMessagesArray(
              messages.boatDetails.value.hullShape,
              hullShape.slice(0, 5)
            )
          ),
          enginesConfigurations: this.generateEnumString(
            this.generateTranslatedMessagesArray(
              messages.boatDetails.value.engineType,
              enginesConfiguration.slice(0, 5)
            )
          ),
          fuelTypes: this.generateEnumString(
            this.generateTranslatedMessagesArray(
              messages.boatDetails.value.fuelType,
              fuelType.slice(0, 5)
            )
          )
        };
      }
    }
  };

  generateEnumString = (elements) => {
    const messages = getMessages();
    const {
      intl: { formatMessage: t }
    } = this.props;
    if (elements.length === 5) {
      return `${elements[0]}, ${elements[1]}, ${elements[2]}, ${elements[3]}
       ${t(messages.dynamicContent.descriptionAnd)} ${elements[4]}`;
    } else if (elements.length === 4) {
      return `${elements[0]}, ${elements[1]}, ${elements[2]}
       ${t(messages.dynamicContent.descriptionAnd)} ${elements[3]}`;
    } else if (elements.length === 3) {
      return `${elements[0]}, ${elements[1]} ${t(
        messages.dynamicContent.descriptionAnd
      )} ${elements[2]}`;
    } else if (elements.length === 2) {
      return `${elements[0]} ${t(messages.dynamicContent.descriptionAnd)} ${
        elements[1]
      }`;
    } else if (elements.length === 1) {
      return `${elements[0]}`;
    }
  };

  generateTranslatedMessagesArray = (key, elements) => {
    const {
      intl: { formatMessage: t }
    } = this.props;
    let translatedArray = [];
    forEach(elements, (element) => {
      translatedArray.push(t(key[element.value]).toLowerCase());
    });
    return translatedArray;
  };

  getPageType() {
    const { params, facets } = this.props;
    const searchParams = omitBy(params, (value) => {
      return value === undefined;
    });
    let selectedParams = keys(searchParams);
    selectedParams = pull(
      selectedParams,
      'make',
      'model',
      'makeModel',
      'types',
      'classes'
    );
    if (size(selectedParams) !== 0) {
      return this.setNoPageType();
    }
    if (isEmpty(facets)) {
      return this.setNoPageType();
    }
    const defaultParams = getDefaultParams(params);
    const currentParams = getActiveParams(defaultParams);
    const makeModel = get(currentParams, 'makeModel', {});
    const makeModelKeys = Object.keys(makeModel);
    const typeClass = get(currentParams, 'multiFacetedBoatTypeClass', {});
    const typeClassKeys = Object.keys(typeClass);
    if (makeModelKeys.length === 1 && typeClassKeys.length === 0) {
      const models = makeModel[makeModelKeys[0]];
      if (models.length === 1) {
        return this.selectClassMakeOrModelPage('model', models[0]);
      }

      if (models.length === 0 && makeModelKeys[0] !== 'other') {
        return this.selectClassMakeOrModelPage('make');
      }
    }
    if (typeClassKeys.length === 1 && makeModelKeys.length === 0) {
      const classes = typeClass[typeClassKeys[0]];
      if (classes.length === 1) {
        return this.selectClassMakeOrModelPage('class');
      }
    }
    return this.setNoPageType();
  }

  generateMakeContent(contentData, portal, t, customUom) {
    const messages = getMessages();
    return {
      makeParagraph2: {
        title: t(messages.dynamicContent.descriptionMakeParagraph2Section1, {
          make: contentData.make
        }),
        paragraphs: [
          t(messages.dynamicContent.descriptionMakeParagraph2Section2, {
            make: contentData.make,
            activities: contentData.activities
          }),
          contentData.hullShapes &&
            t(messages.dynamicContent.descriptionMakeParagraph2Section3, {
              make: contentData.make,
              hullTypes: contentData.hullShapes
            }),
          contentData.hullShapes &&
            contentData.enginesConfigurations &&
            t(messages.dynamicContent.descriptionMakeParagraph2Section4, {
              engines: contentData.enginesConfigurations
            }),
          contentData.hullShapes &&
            contentData.enginesConfigurations &&
            contentData.fuelTypes &&
            t(messages.dynamicContent.descriptionMakeParagraph2Section5, {
              fuelTypes: contentData.fuelTypes
            })
        ]
      },
      makeParagraph3: {
        title: t(messages.dynamicContent.descriptionMakeParagraph3Section1, {
          make: contentData.make
        }),
        paragraphs: [
          t(messages.dynamicContent.descriptionMakeParagraph3Section2, {
            make: contentData.make,
            classes: contentData.class
          }),
          t(messages.dynamicContent.descriptionMakeParagraph3Section3, {
            minLength: `${formatNumber(
              contentData.minLength,
              getLocale()
            )} ${getLengthName(customUom?.length)}`,
            maxLength: `${formatNumber(
              contentData.maxLength,
              getLocale()
            )} ${getLengthName(customUom?.length)}`
          })
        ]
      },
      makeParagraph4: {
        title: t(messages.dynamicContent.descriptionMakeParagraph4Section1, {
          make: contentData.make
        }),
        paragraphs: [
          t(messages.dynamicContent.descriptionMakeParagraph4Section2, {
            make: contentData.make,
            minPrice: formatPriceWithDecimal(
              contentData.minPrice,
              getCurrency(),
              getLocale()
            ),
            maxPrice: formatPriceWithDecimal(
              contentData.maxPrice,
              getCurrency(),
              getLocale()
            ),
            portal: portal
          }),
          contentData.minPower &&
            contentData.maxPower &&
            t(messages.dynamicContent.descriptionMakeParagraph4Section3, {
              minPower: formatNumber(contentData.minPower, getLocale()),
              maxPower: formatNumber(contentData.maxPower, getLocale())
            })
        ]
      },
      makeParagraph5: {
        title: t(messages.dynamicContent.descriptionMakeParagraph5Section1, {
          make: contentData.make
        }),
        paragraphs: [
          t(messages.dynamicContent.descriptionMakeParagraph5Section2, {
            make: contentData.make,
            models: contentData.numModels
          })
        ]
      }
    };
  }

  generateClassContent(contentData, portal, t, country) {
    const messages = getMessages();
    if (contentData.pageType === 'class') {
      const classType = t(messages.classFacetValues[contentData.class]);

      return {
        classParagraph2: {
          title: t(
            messages.dynamicContent.descriptionClassPowerParagraph2Section1,
            {
              class: classType,
              portal: portal
            }
          ),
          paragraphs: [
            t(messages.dynamicContent.descriptionClassPowerParagraph2Section2, {
              portal: portal,
              numListings: formatNumber(contentData.count, getLocale()),
              numNew: contentData.numNew
                ? `${formatNumber(contentData.numNew, getLocale())} ${t(
                    messages.dynamicContent.descriptionNewVessels
                  )}`
                : '',
              and:
                contentData.numNew && contentData.numUsed
                  ? t(messages.dynamicContent.descriptionAnd)
                  : '',
              numUsed: contentData.numUsed
                ? `${formatNumber(contentData.numUsed, getLocale())} ${t(
                    messages.dynamicContent.descriptionUsedBoats
                  )}`
                : '',
              class: classType,
              minYear: contentData.minYear,
              maxYear: contentData.maxYear,
              country: t(messages.countries[country])
            })
          ]
        },
        classParagraph3: {
          title: t(
            messages.dynamicContent.descriptionClassPowerParagraph3Section1,
            {
              class: classType
            }
          ),
          paragraphs: [
            t(messages.dynamicContent.descriptionClassPowerParagraph3Section2, {
              class: classType,
              makes: contentData.makes,
              numMakes: contentData.numMakes,
              portal: portal
            })
          ]
        },
        classParagraph4: {
          title: t(
            messages.dynamicContent.descriptionClassPowerParagraph4Section1,
            {
              class: classType
            }
          ),
          paragraphs: [
            t(messages.dynamicContent.descriptionClassPowerParagraph4Section2, {
              class: classType,
              portal: portal,
              minPrice: formatPriceWithDecimal(
                contentData.minPrice,
                getCurrency(),
                getLocale()
              ),
              maxPrice: formatPriceWithDecimal(
                contentData.maxPrice,
                getCurrency(),
                getLocale()
              )
            })
          ]
        }
      };
    }
  }

  render() {
    const {
      intl: { formatMessage },
      customUom
    } = this.props;
    const messages = getMessages();
    const t = (...args) => {
      return removeDuplicatedWords(formatMessage(...args));
    };
    const { country } = this.context;
    const portal = getPortalName(this.context);
    const contentData = this.getPageType();
    const supportsDynamicContent = get(
      this.context,
      'supports.dynamicContent',
      true
    );
    const dynamicMakeDisplayContent = this.generateMakeContent(
      contentData,
      portal,
      t,
      customUom
    );
    const dynamicClassDisplayContent = this.generateClassContent(
      contentData,
      portal,
      t,
      country
    );
    return (
      <>
        {supportsDynamicContent && contentData.pageType !== '' && (
          <div className="dynamic__content">
            {contentData.pageType === 'model' && (
              <div className="model_content">
                <h2 className="model">
                  {contentData.make} {contentData.model}
                </h2>
                <p>
                  {t(
                    messages.dynamicContent
                      .descriptionModelPowerParagraph1Section1,
                    {
                      model: contentData.model,
                      make: contentData.make,
                      class: contentData.class
                    }
                  )}
                  {contentData.make && (
                    <span>
                      {t(
                        messages.dynamicContent
                          .descriptionModelPowerParagraph1Section2,
                        {
                          model: contentData.model,
                          make: contentData.make,
                          class: contentData.class
                        }
                      )}
                    </span>
                  )}
                  <span>
                    {t(
                      messages.dynamicContent
                        .descriptionModelPowerParagraph1Section3,
                      {
                        model: contentData.model,
                        make: contentData.make,
                        class: contentData.class,
                        numListings: formatNumber(
                          contentData.count,
                          getLocale()
                        ),
                        numNew: contentData.numNew
                          ? `${formatNumber(
                              contentData.numNew,
                              getLocale()
                            )} ${t(
                              messages.dynamicContent.descriptionNewVessels
                            )}`
                          : '',
                        and:
                          contentData.numNew && contentData.numUsed
                            ? t(messages.dynamicContent.descriptionAnd)
                            : '',
                        numUsed: contentData.numUsed
                          ? `${formatNumber(
                              contentData.numUsed,
                              getLocale()
                            )} ${t(
                              messages.dynamicContent.descriptionUsedBoats
                            )}`
                          : '',
                        portal: portal
                      }
                    )}
                  </span>
                </p>
                <p>
                  {t(
                    messages.dynamicContent
                      .descriptionModelPowerParagraph1Section4,
                    {
                      minYear: contentData.minYear,
                      maxYear: contentData.maxYear,
                      minPrice: formatPriceWithDecimal(
                        contentData.minPrice,
                        getCurrency(),
                        getLocale()
                      ),
                      maxPrice: formatPriceWithDecimal(
                        contentData.maxPrice,
                        getCurrency(),
                        getLocale()
                      ),
                      portal: portal
                    }
                  )}
                </p>
                <p>
                  {t(
                    messages.dynamicContent
                      .descriptionModelPowerParagraph1Section5,
                    {
                      make: contentData.make,
                      models: contentData.numModels
                    }
                  )}
                </p>
                <h2>
                  {t(
                    messages.dynamicContent
                      .descriptionModelPowerParagraph2Section1,
                    {
                      make: contentData.make,
                      model: contentData.model
                    }
                  )}
                </h2>
                <p>
                  {t(
                    messages.dynamicContent
                      .descriptionModelPowerParagraph2Section2,
                    {
                      make: contentData.make,
                      model: contentData.model,
                      activities: contentData.activities
                    }
                  )}
                  {contentData.hullShapes && (
                    <span className="model-hull">
                      {t(
                        messages.dynamicContent
                          .descriptionModelPowerParagraph2Section3,
                        {
                          hullTypes: contentData.hullShapes
                        }
                      )}
                    </span>
                  )}
                  {contentData.hullShapes &&
                    contentData.enginesConfigurations && (
                      <span className="model-engine">
                        {t(
                          messages.dynamicContent
                            .descriptionModelPowerParagraph2Section4,
                          {
                            engines: contentData.enginesConfigurations
                          }
                        )}
                      </span>
                    )}
                  {contentData.hullShapes &&
                    contentData.enginesConfigurations &&
                    contentData.fuelTypes && (
                      <span className="model-fuel">
                        {t(
                          messages.dynamicContent
                            .descriptionModelPowerParagraph2Section5,
                          {
                            fuelTypes: contentData.fuelTypes
                          }
                        )}
                      </span>
                    )}
                </p>
              </div>
            )}
            {contentData.pageType === 'make' && (
              <div className="make_content">
                <Schema data={getFaqPageSchema(dynamicMakeDisplayContent)} />
                <h2 className="make">
                  {t(
                    messages.dynamicContent.descriptionMakeParagraph1Section0,
                    {
                      make: contentData.make
                    }
                  )}
                </h2>
                <p>
                  {t(
                    messages.dynamicContent.descriptionMakeParagraph1Section1,
                    {
                      make: contentData.make,
                      class: contentData.class,
                      numListings: formatNumber(contentData.count, getLocale()),
                      numNew: contentData.numNew
                        ? `${formatNumber(contentData.numNew, getLocale())} ${t(
                            messages.dynamicContent.descriptionNewVessels
                          )}`
                        : '',
                      and:
                        contentData.numNew && contentData.numUsed
                          ? t(messages.dynamicContent.descriptionAnd)
                          : '',
                      numUsed: contentData.numUsed
                        ? `${formatNumber(
                            contentData.numUsed,
                            getLocale()
                          )} ${t(messages.dynamicContent.descriptionUsedBoats)}`
                        : '',
                      portal: portal,
                      country: t(messages.countries[country])
                    }
                  )}
                </p>
                <p>
                  {t(
                    messages.dynamicContent.descriptionMakeParagraph1Section2,
                    {
                      minYear: contentData.minYear,
                      maxYear: contentData.maxYear,
                      portal: portal
                    }
                  )}
                </p>
                <h2>{dynamicMakeDisplayContent.makeParagraph2.title}</h2>
                <p>
                  {dynamicMakeDisplayContent.makeParagraph2.paragraphs[0]}
                  {contentData.hullShapes && (
                    <span className="make-hull">
                      {dynamicMakeDisplayContent.makeParagraph2.paragraphs[1]}
                    </span>
                  )}
                  {contentData.hullShapes &&
                    contentData.enginesConfigurations && (
                      <span className="make-engine">
                        {dynamicMakeDisplayContent.makeParagraph2.paragraphs[2]}
                      </span>
                    )}
                  {contentData.hullShapes &&
                    contentData.enginesConfigurations &&
                    contentData.fuelTypes && (
                      <span className="make-fuel">
                        {dynamicMakeDisplayContent.makeParagraph2.paragraphs[3]}
                      </span>
                    )}
                </p>
                <h2>{dynamicMakeDisplayContent.makeParagraph3.title}</h2>
                <p>
                  {dynamicMakeDisplayContent.makeParagraph3.paragraphs[0]}
                  {dynamicMakeDisplayContent.makeParagraph3.paragraphs[1]}
                </p>
                <h2>{dynamicMakeDisplayContent.makeParagraph4.title}</h2>
                <p>{dynamicMakeDisplayContent.makeParagraph4.paragraphs[0]}</p>
                {contentData.minPower && contentData.maxPower && (
                  <p>
                    {dynamicMakeDisplayContent.makeParagraph4.paragraphs[1]}
                  </p>
                )}
                <h2>{dynamicMakeDisplayContent.makeParagraph5.title}</h2>
                <p>{dynamicMakeDisplayContent.makeParagraph5.paragraphs[0]}</p>
              </div>
            )}
            {contentData.pageType === 'class' && (
              <div className="class_content">
                <Schema data={getFaqPageSchema(dynamicClassDisplayContent)} />
                <h2 className="class">
                  {t(
                    messages.dynamicContent
                      .descriptionClassPowerParagraph1Section1,
                    {
                      class: t(messages.classFacetValues[contentData.class])
                    }
                  )}
                </h2>
                <p>
                  {t(
                    messages.dynamicContent
                      .descriptionClassPowerParagraph1Section2,
                    {
                      class: t(messages.classFacetValues[contentData.class]),
                      activities: contentData.activities
                    }
                  )}
                  {contentData.type === 'power' && (
                    <span className="power_content">
                      {t(
                        messages.dynamicContent
                          .descriptionClassPowerParagraph1Section3,
                        {
                          avgTotalHorsepower: formatNumber(
                            contentData.avgTotalHorsepower,
                            getLocale()
                          ),
                          minLength: `${formatNumber(
                            contentData.minLength,
                            getLocale()
                          )} ${getLengthName(customUom?.length)}`,
                          maxLength: `${formatNumber(
                            contentData.maxLength,
                            getLocale()
                          )} ${getLengthName(customUom?.length)}`
                        }
                      )}
                    </span>
                  )}
                  {contentData.type === 'sail' && (
                    <span className="sail_content">
                      {t(
                        messages.dynamicContent
                          .descriptionClassSailParagraph1Section3,
                        {
                          minLength: `${formatNumber(
                            contentData.minLength,
                            getLocale()
                          )} ${getLengthName(customUom?.length)}`,
                          maxLength: `${formatNumber(
                            contentData.maxLength,
                            getLocale()
                          )} ${getLengthName(customUom?.length)}`
                        }
                      )}
                    </span>
                  )}
                  {contentData.hullShapes && contentData.type === 'power' && (
                    <span className="class-hull">
                      {t(
                        messages.dynamicContent
                          .descriptionClassPowerParagraph1Section4,
                        {
                          hullTypes: contentData.hullShapes
                        }
                      )}
                    </span>
                  )}
                  {contentData.hullShapes && contentData.type === 'sail' && (
                    <span className="class-hull">
                      {t(
                        messages.dynamicContent
                          .descriptionClassSailParagraph1Section4,
                        {
                          hullTypes: contentData.hullShapes
                        }
                      )}
                    </span>
                  )}
                  {contentData.hullShapes &&
                    contentData.enginesConfigurations &&
                    contentData.type === 'power' && (
                      <span className="class-engine">
                        {t(
                          messages.dynamicContent
                            .descriptionClassPowerParagraph1Section5,
                          {
                            engines: contentData.enginesConfigurations
                          }
                        )}
                      </span>
                    )}
                  {contentData.hullShapes &&
                    contentData.enginesConfigurations &&
                    contentData.fuelTypes &&
                    contentData.type === 'power' && (
                      <span className="class-fuel">
                        {t(
                          messages.dynamicContent
                            .descriptionClassPowerParagraph1Section6,
                          {
                            fuelTypes: contentData.fuelTypes
                          }
                        )}
                      </span>
                    )}
                </p>
                <h2>{dynamicClassDisplayContent.classParagraph2.title}</h2>
                <p>
                  {dynamicClassDisplayContent.classParagraph2.paragraphs[0]}
                </p>
                <h2>{dynamicClassDisplayContent.classParagraph3.title}</h2>
                <p>
                  {dynamicClassDisplayContent.classParagraph3.paragraphs[0]}
                </p>
                <h2>{dynamicClassDisplayContent.classParagraph4.title}</h2>
                <p>
                  {dynamicClassDisplayContent.classParagraph4.paragraphs[0]}
                </p>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

DynamicContent.propTypes = {
  facets: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  seoMakeInfo: PropTypes.object,
  count: PropTypes.number,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  customUom: PropTypes.shape({
    length: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    weight: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    speed: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    capacity: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    distance: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    radius: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  })
};

DynamicContent.contextType = PortalConfigContext;

export default injectIntl(DynamicContent);
