import React, {useContext, useMemo, useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import includes from 'lodash/includes';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FilterButton, FilterHeaderContainer } from '../Filter/FilterHeader';
import RefineSearch from '../RefineSearch';
import SortSelector from '../SortSelector';
import { ToolSet, ToolSetButton, ToolSetButtonTray, ToolSetInner, ToolSetHeader } from '../../../../components/ToolSet';
import { getFacets } from '../../../../store/actions';
import { setSearchTracking } from '../../../../store/actions/dataLayer';
import * as scroll from '../../../../utils/scrollTo';

import { generateSearchPath, getDefaultParams,
  getActiveParams, getFormattedParamsArray, getNotEmptyActiveParams } from '../../../../utils/urlHelpers/boats';
import { PortalConfigContext } from '../../../../config/portal';
import { useFeatureFlags } from '../../../../context/hooks/useFeatureFlags';
import SearchAlertsContainer from '../SearchAlerts/components/Boats';
import { Cookies } from 'react-cookie';
import { getCurrentLocale } from '../../../../utils/language';

import './styles.css';
import {asArray, yieldToMain} from '../../../../utils';
import get from 'lodash/get';
import { getMessages } from '../../../../tppServices/translations/messages';

const reducer = (prevState, updated) => ({
  ...prevState,
  ...updated
});

const MobileRefineSearch = (props) => {
  const [state, setState] = useReducer(reducer, { data: null });
  const [open, setOpen] = useState(false);
  const context = useContext(PortalConfigContext);
  const { intl, isThreeColumnLayout, match, history, seoMakeInfo, facets } = props;
  const loading = includes(get(props, 'componentWorking', []), 'facets');
  const urlParams = match?.params || {};
  const {urlCurrentState, activeParams, activeParamsCount, currentLanguage} = useMemo(() => {
    const urlCurrentState = getDefaultParams(urlParams);
    const activeParams = getActiveParams(urlCurrentState);
    const activeParamsCount = getFormattedParamsArray(activeParams).length;
    const currentLanguage = getCurrentLocale();
    return {urlCurrentState, activeParams, activeParamsCount, currentLanguage};
  },[urlParams]);
  const mobileParams = useMemo(() => {
    return state.data ? getNotEmptyActiveParams(state.data) : null;
  }, [state.data]);

  const { featureFlagEngineMakesFilter } = useFeatureFlags(props.cookies);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDataChange = async (data) => {
    setState({ data });
    await yieldToMain();

    let params = urlCurrentState;
    let path = generateSearchPath(data, params, true);
    props.getFacets(path, {}, featureFlagEngineMakesFilter);
    props.handleDataChangeMobile(data);
  };

  const handleSubmit = () => {
    requestData();
    props.setSearchTracking(state.data, 'group');
    setOpen(false);
    setState({data: null});
    scroll.scrollToTop();
  };

  const requestData = () => {
    history.push(generateSearchPath(state.data, urlCurrentState, true));
  };
  const messages = getMessages();
  const t = intl.formatMessage;
  const enableFilterRedesign = !!context.supports?.enableFilterRedesign;
  const models = asArray(facets?.makeModel);
  const searchAlerts = get(context.supports,'searchAlerts') &&
                         !get(context.languages[currentLanguage], 'disableSearchAlerts', false);

  return (
    <div className={classnames('mobile-search-filters', { 'filter-v2': enableFilterRedesign, loading, 'new-filter-bar': isThreeColumnLayout })}>
      <ToolSet open={open}>
      {enableFilterRedesign && <ToolSetHeader onClick={ handleClose } filterText={t(messages.filter)} />}
        <ToolSetInner>
          { loading && <div className="spinner-preloader" /> }
          <RefineSearch {...props} onDataChange={ handleDataChange } position="mobile" mobileParams={mobileParams}/>
        </ToolSetInner>
        <ToolSetButtonTray>
          { searchAlerts && <SearchAlertsContainer
            cookies={props.cookies}
            match={props.match}
            eventCategory="above search filter"
            active={mobileParams || activeParams}
            makeModel={models}
            seoMakeInfo={seoMakeInfo}
          /> }
          {!enableFilterRedesign &&
            <ToolSetButton name="cancel" hidden={state.data} onClick={ handleClose }>
              {t(messages.cancel)}
            </ToolSetButton>
          }
          <ToolSetButton name="submit" hidden={!state.data && !enableFilterRedesign} onClick={ handleSubmit }>
            {t(messages.searchTitle)}
          </ToolSetButton>
        </ToolSetButtonTray>
      </ToolSet>
      <div className="mobile-filter-anchor"></div>
      <div className={classnames('mobile-filter-header', { 'no-search-alert': !searchAlerts })}>
        <FilterHeaderContainer type="light">
          <FilterButton onClick={ handleOpen } count={activeParamsCount} />
          {
            searchAlerts &&
            <SearchAlertsContainer
              cookies={props.cookies}
              match={props.match}
              eventCategory="sort bar"
              active={mobileParams || activeParams}
              makeModel={models}
              seoMakeInfo={seoMakeInfo}
            />
          }
          <SortSelector scrollTo=".mobile-filter-anchor" {...props}/>
        </FilterHeaderContainer>
      </div>
    </div>
  );
};

MobileRefineSearch.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  getFacets: PropTypes.func.isRequired,
  setSearchTracking: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  customUom: PropTypes.shape({
    length: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    weight: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    speed: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    capacity: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    distance: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    radius: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  }),
  handleDataChangeMobile: PropTypes.func,
  isThreeColumnLayout: PropTypes.bool,
  facets: PropTypes.object,
  seoMakeInfo: PropTypes.object,
};

export default connect(null, dispatch => bindActionCreators({
  getFacets,
  setSearchTracking
}, dispatch))(injectIntl(MobileRefineSearch));
