import React, { PureComponent, Children } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import debounce from 'lodash/debounce';
import { ToolSetRadioGroup, ToolSetRadioItem } from './ToolSetRadio';

import './styles.css';
import { yieldToMain } from '../../utils';

class ToolSet extends PureComponent {
  render() {
    let { open, children } = this.props;

    return <div className={classnames('tool-set', { open })}>{children}</div>;
  }
}

ToolSet.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool
};

class ToolSetInner extends PureComponent {
  render() {
    let { children } = this.props;

    return <div className="inner">{children}</div>;
  }
}

ToolSetInner.propTypes = {
  children: PropTypes.node
};

class ToolSetOptions extends PureComponent {
  render() {
    let { children, filterText = '' } = this.props;
    return (
      <ul className="opts">
        {filterText
          ? Children.map(children, (child) => {
              //if there is filter text passed down as a prop
              //pass the filter value down to the ToolSetOptionsItems
              if (child.type.displayName === 'ToolSetOptionsItem') {
                let filtered = !child.props.value
                  .toLowerCase()
                  .includes(filterText.toLowerCase());
                return React.cloneElement(child, {
                  filtered
                });
              }
              return child;
            })
          : children}
      </ul>
    );
  }
}

ToolSetOptions.displayName = 'ToolSetOptions';

ToolSetOptions.propTypes = {
  children: PropTypes.node,
  filterText: PropTypes.string
};

class ToolSetOptionsItem extends PureComponent {
  render() {
    let {
      children,
      id,
      name,
      value,
      trackingData,
      trackingDataExtra,
      selected,
      filtered,
      type = 'radio',
      onClick
    } = this.props;
    return (
      <li className={classnames({ filtered, selected })}>
        <label id={`${name}-label`} className="visuallyhidden">
          <span className="txt">{name}</span>
        </label>
        <input
          id={id}
          type={type}
          checked={selected}
          value={value}
          name={name}
          aria-labelledby={`${name}-label`}
          data-tracking-data={trackingData}
          data-tracking-extra-data={trackingDataExtra}
          onChange={() => onClick(value)}
        />
        <div
          data-custom-option={id}
          className={classnames('custom-option', { selected })}
          tabIndex="-1"
          onClick={() => onClick(value)}
        >
          {children}
        </div>
      </li>
    );
  }
}

ToolSetOptionsItem.displayName = 'ToolSetOptionsItem';

ToolSetOptionsItem.propTypes = {
  children: PropTypes.node,
  filtered: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  trackingData: PropTypes.any,
  trackingDataExtra: PropTypes.any,
  type: PropTypes.string,
  value: PropTypes.string
};

class ToolSetTypeAhead extends PureComponent {
  state = {
    text: ''
  };

  handleChange(text) {
    this.setState({
      text
    });
  }

  render() {
    let { children } = this.props;
    return (
      <div className="type-ahead">
        {Children.map(children, (child) => {
          if (child.type.displayName === 'ToolSetOptions') {
            //if the child is a ToolSetOptions component,
            //pass down the filter text
            return React.cloneElement(child, {
              filterText: this.state.text
            });
          } else if (child.type.displayName === 'ToolSetTextInput') {
            //if the child is a ToolSetTextInput component,
            //add an onChange prop to detect the value of the filter text
            return React.cloneElement(child, {
              onChange: this.handleChange.bind(this)
            });
          }
          return child;
        })}
      </div>
    );
  }
}

ToolSetTypeAhead.propTypes = {
  children: PropTypes.node
};

class ToolSetTextInput extends PureComponent {
  state = {
    value: ''
  };
  handleDataChange(text) {
    if (this.props.debounce) {
      this.debouncedOnDataChange(text);
    } else {
      this.onChange(text);
    }
  }

  onChange(text) {
    this.props.onChange(text);
    this.setState({
      value: text
    });
  }

  static getDerivedStateFromProps(props) {
    let { value } = props;
    if (value !== undefined) {
      return { value };
    }
    return null;
  }

  debouncedOnDataChange = debounce((data) => this.onChange(data), 500);

  render() {
    let {
      id,
      name,
      type = 'text',
      min,
      max,
      placeholder = '',
      icon
    } = this.props;
    let { value } = this.state;
    return (
      <div className={classnames('filter-txt', { 'filter-txt-icon': icon })}>
        <input
          title={placeholder}
          className="filter-input"
          type={type}
          id={id}
          placeholder={placeholder}
          min={min}
          max={max}
          name={name}
          value={value}
          onChange={(e) => this.handleDataChange(e.target.value)}
        />
      </div>
    );
  }
}

ToolSetTextInput.displayName = 'ToolSetTextInput';

ToolSetTextInput.propTypes = {
  children: PropTypes.node,
  debounce: PropTypes.bool,
  icon: PropTypes.bool,
  id: PropTypes.string,
  max: PropTypes.any,
  min: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string
};

class ToolSetButtonTray extends PureComponent {
  render() {
    let { children, sticky, hidden } = this.props;
    return (
      <div className={classnames('buttons', { fixed: sticky, hidden })}>
        <div className="btn-container">{children}</div>
      </div>
    );
  }
}

ToolSetButtonTray.propTypes = {
  children: PropTypes.node,
  hidden: PropTypes.bool,
  sticky: PropTypes.bool
};

class ToolSetButton extends PureComponent {

  async handleClick(evt) {
    await yieldToMain();
    this.props.onClick(evt);
  }
  render() {
    let { children, name, hidden, onChange } = this.props;
    return (
      <button
        className={classnames('btn-action', { [name]: name, hidden })}
        type="button"
        onChange={onChange}
        onClick={this.handleClick.bind(this)}
      >
        {children}
      </button>
    );
  }
}

ToolSetButton.propTypes = {
  children: PropTypes.node,
  hidden: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func
};

const ToolSetHeader = (props) => {
  const {
    onClick,
    filterText,
  } = props;
  return (
    <>
      <div className="tool-set-header">
        <h2 className="tool-set-header-title">{filterText}</h2>
        <button className="tool-set-btn-close" type="button" aria-label="tool-set-header" onClick={onClick}></button>
      </div>
    </>
  );
};

ToolSetHeader.propTypes = {
  onClick: PropTypes.func,
  filterText: PropTypes.string
};

export {
  ToolSet,
  ToolSetInner,
  ToolSetOptions,
  ToolSetOptionsItem,
  ToolSetTypeAhead,
  ToolSetTextInput,
  ToolSetButtonTray,
  ToolSetButton,
  ToolSetRadioGroup,
  ToolSetRadioItem,
  ToolSetHeader
};
