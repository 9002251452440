import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import { ToolSetOptions, ToolSetOptionsItem } from '../../../../../../components/ToolSet';
import { FilterTypeAhead } from '../..';
import { singleHyphenateUrlComponents, generateSearchPath } from '../../../../../../utils/urlHelpers/boats';
import { injectIntl } from 'react-intl';
import Link from '../../../../../../components/SEO/Link';

import {getBoatConstantsFromI18n} from '../../../../../../tppServices/translations/constants';
import {slugify} from '../../../../../../utils/urlHelpers/shared';
import { getMessages } from '../../../../../../tppServices/translations/messages';

class FilterCity extends PureComponent {
  toggleCity(city, selectedCities) {
    const cityIsSelected = includes(selectedCities, city);
    if (cityIsSelected) {
      return filter(selectedCities, cityName => cityName !== city);
    }
    return [...selectedCities, city];
  }

  handleDataChange(type, value, slugCity, selected) {
    if (this.props.tracking) {
      if (selected) {
        this.props.tracking.facetRemoved(`city ${slugCity} removed`);
      } else {
        this.props.tracking.facetAdded({'city': slugCity});
      }
    }

    this.props.handleDataChange(type, value);
  }

  getCityName(cities, slugCity) {
    const city = cities.find(city => singleHyphenateUrlComponents(city.value) === slugCity);
    return city.value;
  }

  render()
  {
    const boatConstants = getBoatConstantsFromI18n();
    const { selectedCountry = '', selectedCities = [], cities = [], params } = this.props;
    const { maxCitiesCount = boatConstants.MAX_CITIES_COUNT, position, intl: { formatMessage: t } } = this.props;
    const messages = getMessages();
    const formattedCities = cities.map(city => ({value: singleHyphenateUrlComponents(city.value)}));
    const formattedSelectedCities = selectedCities.map(city => slugify(city));
    const showAllCities = get(params, 'modal', []).includes('city');
    return (
      <div className={'search-filter cities'}>
        <FilterTypeAhead
          items={formattedCities}
          selectedItems={formattedSelectedCities}
          id={`city-type-ahead-${position}`}
          placeholder={t(messages.cityPlaceholder, {country: selectedCountry})}
          moreMessage="moreCities"
          max={maxCitiesCount}
          url={generateSearchPath(params) + 'modal-city/'}
          showAll={showAllCities}
          render={(items, selectionCallback) =>
            <ToolSetOptions>
              { items.map(city => {
                const citySelected = includes(selectedCities, city.value);
                return <ToolSetOptionsItem
                  type="checkbox"
                  key={`City-${city.value}-all`}
                  id={`City-${city.value}-all`}
                  name={`City-${city.value}-${position}`}
                  value={city.value}
                  selected={citySelected}
                  onClick={(value) => {
                    this.handleDataChange('city', this.toggleCity(city.value, selectedCities), city.value, citySelected);
                    selectionCallback(value);
                  }}>
                  <Link className="city-type-link" href={generateSearchPath({ city: this.toggleCity(city.value, selectedCities) }, params, true)} onClick={(e) => e.preventDefault()}>
                    {this.getCityName(cities, city.value)}
                  </Link>
                </ToolSetOptionsItem>;
              }
              )}
            </ToolSetOptions>
          } />
      </div>);
  }
}

FilterCity.propTypes = {
  selectedCountry: PropTypes.string,
  selectedCities: PropTypes.array,
  cities: PropTypes.array,
  maxCitiesCount: PropTypes.number,
  params: PropTypes.object,
  position: PropTypes.string,
  handleDataChange: PropTypes.func,
  tracking: PropTypes.shape({
    facetAdded: PropTypes.func,
    facetRemoved: PropTypes.func,
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired
};

export default injectIntl(FilterCity);
