import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from '../SEO/Link';

import './styles.css';

const PaginationItem = ({ disabled, href, type, children, onClick, active, nofollow }) => {
  return (
    <li>
      {disabled ? (
        <a className={classnames({ disabled, [type]: type })}>{children}</a>
      ) : (
        <Link
          onClick={onClick}
          href={href}
          nofollow={nofollow}
          className={classnames({ disabled, [type]: type, active })}
        >
          {children}
        </Link>
      )}
    </li>
  );
};

PaginationItem.defaultProps = {
  active: false,
  disabled: false,
  href: '',
  type: '',
};

PaginationItem.propTypes = {
  /** Active CSS class */
  active: PropTypes.bool,
  /** Item to render */
  children: PropTypes.node.isRequired,
  /** Disables click functionality */
  disabled: PropTypes.bool,
  /** Link HREF */
  href: PropTypes.string,
  /** onClick event listener */
  onClick: PropTypes.func,
  /** Pagination item type CSS class */
  type: PropTypes.string,
  /** nofollow html value */
  nofollow: PropTypes.bool,
};

export default PaginationItem;


