import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import PaginationItem from '../../../../components/PaginationItem';
import {
  generateSearchPath,
  getDefaultParams
} from '../../../../utils/urlHelpers/boats';
import {
  generateBrandedOemSearchPath,
  getDefaultParams as getDefaultOemParams
} from '../../../../utils/urlHelpers/oem';
import get from 'lodash/get';
import { getPaginationData } from '../../../../utils/paginationHelper';
import * as scroll from '../../../../utils/scrollTo';
import { yieldToMain } from '../../../../utils';

const ResultsPagination = ({ maxPages, history, isBranded, search, match }) => {
  const handleDataChange = async (e, newUrl) => {
    e.preventDefault();
    await yieldToMain();
    history.push(newUrl);
    scroll.scrollTo('#root');
  };
  let count = get(search, 'count', 28);
  let pageParams = get(match, 'params', {});

  const [params, pagination] = useMemo(() => {
    let params = isBranded
      ? getDefaultOemParams(pageParams)
      : getDefaultParams(pageParams);
    let pagination = getPaginationData(params, count, maxPages, 5);
    return [params, pagination];
  }, [pageParams]);

  let pageSize = pagination.pageSize;

  const urlGenerationFunction = isBranded
    ? generateBrandedOemSearchPath
    : generateSearchPath;

  const previousPage = urlGenerationFunction(
    { page: pagination.page - 1, pageSize },
    params
  );

  const nextPage = urlGenerationFunction(
    { page: pagination.page + 1, pageSize },
    params
  );

  return (
    <ul className="pagination">
      <PaginationItem
        key="first"
        onClick={e => handleDataChange(e, previousPage)}
        disabled={pagination.isFirstPage}
        type="prev"
        href={!pagination.isFirstPage ? previousPage : null}
        nofollow={isBranded}
      >
        &lt;
      </PaginationItem>

      {pagination.visibleNumbers.map((value) => {
        const itemPage = urlGenerationFunction({ page: value, pageSize }, params);
        return (
          <PaginationItem
            key={value}
            onClick={e => handleDataChange(e, itemPage)}
            href={itemPage}
            active={pagination.page === value}
            nofollow={isBranded}
          >
            {value}
          </PaginationItem>
        );
      })}
      <PaginationItem
        key="last"
        onClick={e => handleDataChange(e, nextPage)}
        disabled={pagination.isLastPage}
        type="next"
        href={!pagination.isLastPage ? nextPage : null}
        nofollow={isBranded}
      >
        &gt;
      </PaginationItem>
    </ul>
  );
};

ResultsPagination.defaultProps = {
  maxPages: 357
};

ResultsPagination.propTypes = {
  maxPages: PropTypes.number,
  history: PropTypes.object,
  isBranded: PropTypes.bool,
  search: PropTypes.object,
  match: PropTypes.object
};

export default ResultsPagination;
