import React, {PureComponent} from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import './style.css';
import { getMessages } from '../../../../../../tppServices/translations/messages';



class ConfirmationModal extends PureComponent {

  hideModalTimeout = () => {
    const {close} = this.props;
    setTimeout(() => close(), 3000);
  }

  render () {
    const { show, close, intl: { formatMessage: t } } = this.props;
    const messages = getMessages();

    return (
      <Modal
        isOpen={show}
        className="save-search-confirmation-modal"
        overlayClassName="save-search-confirmation-modal-overlay"
        contentLabel="Confirmation modal"
        onAfterOpen={this.hideModalTimeout}
        ariaHideApp={false}
      >
        <div className="check">
          <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.6667 2L7.20833 13L2 8" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
        <div className="container">
          <div className="message">
            {t(messages.searchAlerts.confirmation)}
          </div>
          <button className="closeButton" onClick={() => close()}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.2929 1.41L8.05645 6.64645L7.70289 7L8.05645 7.35355L13.2929 12.59L12.59 13.2929L7.35355 8.05645L7 7.70289L6.64645 8.05645L1.41 13.2929L0.707107 12.59L5.94355 7.35355L6.29711 7L5.94355 6.64645L0.707107 1.41L1.41 0.707107L6.64645 5.94355L7 6.29711L7.35355 5.94355L12.59 0.707107L13.2929 1.41Z" fill="white" stroke="#2C272D"/>
            </svg>
          </button>
        </div>
      </Modal>
    );
  }
}

ConfirmationModal.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  show: PropTypes.bool,
  close: PropTypes.func,
};

export default ConfirmationModal;
