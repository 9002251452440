import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { generateSearchPath } from '../../../../../utils/urlHelpers/boats';
import Link from '../../../../../components/SEO/Link';

import { ToolSetOptions, ToolSetOptionsItem } from '../../../../../components/ToolSet';
import { getMessages } from '../../../../../tppServices/translations/messages';

class FilterForSaleBy extends PureComponent {

  state = { selectedForSale: 'all' };

  updateMultiInput() {
    if (this.props.selectedForSale !== undefined) {
      this.setState({ selectedForSale: this.props.selectedForSale });
    }
  }

  handleToggleForSale(forSale){
    this.setState({
      selectedForSale: forSale
    });
    if (this.props.tracking) {
      this.props.tracking.facetAdded({ 'forSale': forSale });
    }
    this.props.handleDataChange('forSale', forSale);
  }

  componentDidMount() {
    this.updateMultiInput();
  }

  componentDidUpdate() {
    this.updateMultiInput();
  }

  render() {
    const { selectedForSale } = this.state;
    const { intl: { formatMessage: t }, params } = this.props;
    const messages = getMessages();

    return <div className="search-filter for-sale-by">
      <div className="search-filter-group">
        <ToolSetOptions>
          <ToolSetOptionsItem
            id="by-all"
            name="for-sale-by-all"
            value="all"
            selected={selectedForSale === 'all'}
            onClick={(value) => this.handleToggleForSale(value)}
          >
            <Link
              className="for-sale-all-link"
              href={generateSearchPath({ forSale: 'all' }, params, true)}
              onClick={(e) => e.preventDefault()}>
              {t(messages.dealersAndPrivateSellers)}
            </Link>
          </ToolSetOptionsItem>
          <ToolSetOptionsItem
            id="by-dealers"
            name="for-sale-by-dealers"
            value="dealer"
            selected={selectedForSale === 'dealer'}
            onClick={(value) => this.handleToggleForSale(value)}
          >
            <Link
              className="for-sale-dealers-link"
              href={generateSearchPath({ forSale: 'dealer' }, params, true)}
              onClick={(e) => e.preventDefault()}>
              {t(messages.dealers)}
            </Link>
          </ToolSetOptionsItem>
          <ToolSetOptionsItem
            id="by-private"
            name="for-sale-by-private"
            value="owner"
            selected={selectedForSale === 'owner'}
            onClick={(value) => this.handleToggleForSale(value)}
          >
            <Link
              className="for-sale-private-link"
              href={generateSearchPath({ forSale: 'owner' }, params, true)}
              onClick={(e) => e.preventDefault()}>
              {t(messages.privateSellers)}
            </Link>
          </ToolSetOptionsItem>
        </ToolSetOptions>
      </div>
    </div>;
  }
}

FilterForSaleBy.propTypes = {
  handleDataChange: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  params: PropTypes.object,
  selectedForSale: PropTypes.oneOf(['all', 'dealer', 'owner']),
  tracking: PropTypes.shape({
    facetAdded: PropTypes.func.isRequired,
  }),
};

export default injectIntl(FilterForSaleBy);
