import React from 'react';
import {generateSearchPath} from '../../../../../../../utils/urlHelpers/boats';
import SubdivisionToolSetItem from './SubdivisionToolsetItem';
import PropTypes from 'prop-types';

const getRegion = (subdivision, chosenRegion) => {
  if (subdivision.region) {
    return subdivision.region;
  }

  if (subdivision.value === 'all' && chosenRegion) {
    return chosenRegion;
  }
  return '';
};

const Subdivision = ({
  subdivision, selectedSubdivision, chosenCountry, params, intl, translations, onItemClick,
  chosenRegion = '', urlGenerator = generateSearchPath}) => {
  const messages = translations;
  const t = intl.formatMessage;
  const pathArgs = {subdivision: subdivision.value, region: getRegion(subdivision, chosenRegion), city: []};
  const linkHref = urlGenerator(pathArgs, params, true);
  const linkName = subdivision.value === 'all' ? t(messages.allSubdivisions) : t(messages.countrySubdivision[chosenCountry][subdivision.value]);
  return (
    <SubdivisionToolSetItem
      subdivision={subdivision}
      onItemClick={onItemClick}
      isSelected={selectedSubdivision === subdivision.value}
      linkHref={linkHref}
      linkName={linkName}
    />
  );
};

Subdivision.propTypes = {
  subdivision: PropTypes.object,
  selectedSubdivision: PropTypes.string,
  chosenCountry: PropTypes.string,
  chosenRegion: PropTypes.string,
  params: PropTypes.object,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  translations: PropTypes.object,
  onItemClick: PropTypes.func,
  urlGenerator: PropTypes.func
};

export default Subdivision;
