import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.css';

class FormButtonArray extends Component {
  render() {
    const { buttons } = this.props;
    return (
      <ul className="form-button-array">
        {buttons.map((e, idx) => (
          <li key={`form-element-${idx}-${e.icon}`}>
            {e.isAnchor ? (
              <a
                href={e.href}
                onClick={e.onClick}
                className={classnames('form-button', e.class ?? 'primary') }
                target={e.target || '_blank'}
                rel="nofollow noopener noreferrer"
              >
                <div className={classnames('form-button-element', `form-button-element-${e.icon}`)}>
                  {e.message}
                </div>
              </a>
            ) :
              <button onClick={e.onClick} className={classnames('form-button', e.class ?? 'primary') }>
                <div className={classnames('form-button-element', `form-button-element-${e.icon}`)}>
                  {e.message}
                </div>
              </button>
            }
          </li>
        ))}
      </ul>
    );
  }
}

FormButtonArray.propTypes = {
  /** Definition of the array of buttons */
  buttons: PropTypes.arrayOf(PropTypes.shape({
    /** Icon name */
    icon: PropTypes.oneOf(['call', 'call-made', 'email', 'place', 'visit']).isRequired,
    /** Is true instead of a button will create an anchor used in combination with href prop */
    isAnchor: PropTypes.bool,
    /** The href of the anchor used in combination with isAnchor */
    href: PropTypes.string,
    /** Message text */
    message: PropTypes.string.isRequired,
    /** On Click function */
    onClick: PropTypes.func,
    /** Anchor target */
    target: PropTypes.oneOf(['_blank','_self','_parent','_top']),
    /** Type of button style */
    class: PropTypes.oneOf(['primary', 'secondary'])
  })),
};

export default FormButtonArray;
