import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { NumberRangeForm } from '@dmm/lib-react-ui-components';
import { getMessages } from '../../../../../tppServices/translations/messages';
import { trackMinMaxValuesFromForm } from '../../RefineSearch/FilterHelpers/useSRPFilters';

const FilterYearForm = ({ handleDataChange, min = '', max = '' }) => {
  const { formatMessage: t } = useIntl();
  const messages = getMessages();

  return (
    <NumberRangeForm
      splitter={ t(messages.till) }
      min={{
        id: "min-year-input",
        label: "min-year-input",
        name: "min-year-input",
        placeholder: t(messages.quickSearch.min),
        value: min,
      }}
      max={{
        id: "max-year-input",
        label: "max-year-input",
        name: "max-year-input",
        placeholder: t(messages.quickSearch.max),
        value: max,
      }}
      buttonText={ t(messages.go) }
      validate={ ({ min, max }) => {
        const errors = {};
        if ((max !== '') && (+min > +max)) {
          errors.max = t(messages.yearMaxError);
        }

        return errors;
      }}
      onSubmit={(values) => {
        handleDataChange('year', values);
        trackMinMaxValuesFromForm(values, 'year');
      }}
      buttonAttributes={{
        'data-reporting-click-internal-link-id': 'year filter',
        'data-reporting-click-internal-link-type': 'click'
      }}
    />
  );
};

FilterYearForm.propTypes = {
  max: PropTypes.string,
  min: PropTypes.string,
  handleDataChange: PropTypes.func,
};

export default FilterYearForm;

