import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { PortalConfigContext } from '../../../../../config/portal';
import { Collapsible, CollapsibleContent, CollapsibleHeader } from '../../../../../components/Collapsible';
import { ToolSetOptions, ToolSetOptionsItem } from '../../../../../components/ToolSet';
import Link from '../../../../../components/SEO/Link';
import get from 'lodash/get';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import values from 'lodash/values';
import omit from 'lodash/omit';

import * as urlHelpers from '../../../../../utils/urlHelpers/boats';

import { capitalizeString, toHTMLId } from '../../../../../utils/commonHelper';
import { injectIntl } from 'react-intl';

import { yieldToMain } from '../../../../../utils';

import {useTPPServices} from '../../../../../tppServices/tppDIHooks';
import { getMessages } from '../../../../../tppServices/translations/messages';


const FilterClass = ( props ) => {
  let { facets: propsFacets, multiFacetedBoatTypeClass: multiFacetedBoatTypeClassProps, tracking: trackingProps} = props;
  const [ facets, setFacets ] = useState(propsFacets ? propsFacets : []);
  const [ multiFacetedBoatTypeClass, setMultiFacetedBoatTypeClass ] = useState(multiFacetedBoatTypeClassProps ? multiFacetedBoatTypeClassProps : {});
  const context = useContext(PortalConfigContext);
  const {boatsConstants} = useTPPServices(context);
  const messages = getMessages();

  const updateMultiInput = () => {
    if (multiFacetedBoatTypeClassProps !== undefined) {
      setMultiFacetedBoatTypeClass(multiFacetedBoatTypeClassProps);
    }
    if (propsFacets !== undefined) {
      setFacets(propsFacets);
    }
  };

  const toggleType = (typeClass, filters) => {
    if ( filters ) {
      const typeClassKey = keys(typeClass)[0];
      const theKeyExists = includes(keys(filters.multiFacetedBoatTypeClass), typeClassKey); //is the key in the filters?
      if (theKeyExists){
        let arrayOfType = values(filters.multiFacetedBoatTypeClass[ typeClassKey ]);
        let newValue = values(typeClass)[0][0];
        let theValueExists = includes(arrayOfType, newValue);
        if ( theValueExists ) {
          let arrayWithoutNewValue = arrayOfType.filter((value) => {
            return value !== newValue;
          });
          return arrayWithoutNewValue.length !== 0 ?
            { multiFacetedBoatTypeClass: {
              ...omit(filters.multiFacetedBoatTypeClass, typeClassKey),
              [typeClassKey]: arrayWithoutNewValue
            }
            } :
            { multiFacetedBoatTypeClass: {
              ...omit(filters.multiFacetedBoatTypeClass, typeClassKey),
            }
            };

        } else if (boatsConstants.allTypes.includes(newValue)) {
          return { multiFacetedBoatTypeClass: {
            ...omit(filters.multiFacetedBoatTypeClass, typeClassKey),
            [typeClassKey]: [newValue]
          }
          };
        }
        return { multiFacetedBoatTypeClass: {
          ...omit(filters.multiFacetedBoatTypeClass, typeClassKey),
          [typeClassKey]: [...filters.multiFacetedBoatTypeClass[typeClassKey], newValue]
        }
        };

      }
      return { multiFacetedBoatTypeClass: {...filters.multiFacetedBoatTypeClass, ...typeClass} };

    }
    return { multiFacetedBoatTypeClass: {...typeClass} };
  };

  const toggleClass = async (type, newClass) => {
    let newClasses = [];
    let typeArray = get(multiFacetedBoatTypeClass, type, []);
    if (includes(typeArray, newClass) ) {
      if (trackingProps) {
        trackingProps.facetRemoved(`class removed - ${newClass}`);
      }
      newClasses = typeArray.filter( (oldclass) => { return oldclass !== newClass;});
    } else {
      if (trackingProps) {
        trackingProps.facetAdded({ ['multiFacetedBoatTypeClass']: {type: [newClass]} });
      }
      if (boatsConstants.allTypes.includes(newClass)) {
        newClasses = [newClass];
      } else {
        newClasses = [...typeArray, newClass];
      }
    }
    if (newClasses.length > 1 && newClasses.some( newClass => boatsConstants.allTypes.includes(newClass))) {
      newClasses = newClasses.filter( (oldclass) => { return !boatsConstants.allTypes.includes(oldclass);});
    }
    let updatedTypeClasses;
    if (newClasses.length) {
      updatedTypeClasses =  { ...multiFacetedBoatTypeClass, [type]: newClasses };
    } else {
      updatedTypeClasses = { ...multiFacetedBoatTypeClass};
      delete updatedTypeClasses[type];
    }

    setMultiFacetedBoatTypeClass(updatedTypeClasses);
    await yieldToMain();
    props.handleDataChange('multiFacetedBoatTypeClass', updatedTypeClasses);
  };

  useEffect(() => {
    updateMultiInput();
  }, [multiFacetedBoatTypeClassProps, propsFacets]);

  const renderSingleClassFilter = (boatType, key, name, value) => {
    const { position, params: filters, intl: { formatMessage: t } } = props;
    const boatTypeName = t(messages[boatType]);
    const boatTypeCapitalized = capitalizeString(toHTMLId(boatTypeName));
    const nameId = toHTMLId(name);

    return (
      <ToolSetOptionsItem
        type="checkbox"
        key={`Class-${key || name}`}
        id={`${boatTypeCapitalized}-${nameId}-${position}`}
        name={`${boatTypeCapitalized}-${nameId}`}
        value={value}
        selected={get(multiFacetedBoatTypeClass, boatType, []).includes(value)}
        onClick={val => toggleClass(boatType, val)}
      >
        <Link
          className={`class-${boatType}-link`}
          href={urlHelpers.generateSearchPath(
            toggleType({[boatType]: [value]}, filters),
            filters,
            true,
          )}
          onClick={(e) => e.preventDefault()}
        >
          {name}
        </Link>
      </ToolSetOptionsItem>
    );
  };

  const { boatTypes } = context;
  const { intl: { formatMessage: t } } = props;

  const facetsObject = facets.reduce((accumulator, currentValue) => {
    accumulator[currentValue.value] = currentValue;
    return accumulator;
  }, {});

  const collapsibles = Object.keys(boatTypes).map(boatType => {
    const allTypeFilter = renderSingleClassFilter(
      boatType,
      undefined,
      t(messages.allType, { boatType: t(messages[boatType]).toLowerCase() }),
      `${boatType}-all`,
    );

    const classFilters = boatTypes[boatType]
      .map((boatClass) => {
        return facetsObject[boatClass] || {};
      })
      .filter((facet) => {
        return facet.count || (get(multiFacetedBoatTypeClass, boatType, []).includes(facet.value));
      })
      .sort((facetA, facetB) => {
        const classNameA = t(messages.classFacetValues[facetA.value]);
        const classNameB = t(messages.classFacetValues[facetB.value]);

        if (classNameA > classNameB) {
          return 1;
        }

        return -1;
      })
      .map((facet) => {
        const className = t(messages.classFacetValues[facet.value]);
        return renderSingleClassFilter(boatType, facet.keyVal, className, facet.value);
      });

    return classFilters.length ? (
      <CollapsibleContent initialState={'closed'} key={boatType}>
        <CollapsibleHeader priority={0}>
          {t(messages[boatType])}
        </CollapsibleHeader>
        <Collapsible type="internal">
          <div className="collapsible-internal-content">
            <ToolSetOptions>
              {allTypeFilter}
              {classFilters}
            </ToolSetOptions>
          </div>
        </Collapsible>
      </CollapsibleContent>
    ) : null;
  });

  return (
    <div className="search-filter class">
      {collapsibles}
    </div>
  );
};

FilterClass.propTypes = {
  facets: PropTypes.arrayOf(PropTypes.shape({
    heading: PropTypes.string,
    name: PropTypes.string,
  })),
  handleDataChange: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  multiFacetedBoatTypeClass: PropTypes.shape({
    power: PropTypes.array,
    sail: PropTypes.array,
  }),
  params: PropTypes.shape({
    multiFacetedBoatTypeClass: PropTypes.object,
  }),
  position: PropTypes.oneOf(['desktop', 'mobile']),
  tracking: PropTypes.shape({
    facetAdded: PropTypes.func.isRequired,
    facetRemoved: PropTypes.func.isRequired,
  }),
};

export default injectIntl(FilterClass);
