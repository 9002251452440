import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import sortedUniq from 'lodash/sortedUniq';
import cloneDeep from 'lodash/cloneDeep';
import TextTransform from '../../utils/TextTransform';
import { getDefaultParams, getActiveParams } from '../../../../utils/urlHelpers/boats';
import './styles.css';
import {getExpertReviewMessages} from './expertReviewMessages';

export const noOtherFilterOnTopOfParam = (activeParams, param) => {
  let emptySelection = {};
  const activeParamsWithNoParam = omit(activeParams, param);
  const resultIsEqual = isEqual(activeParamsWithNoParam, emptySelection);
  return resultIsEqual;
};

export const filteredOnlyByOneMake = (activeParams, makeModel) => {
  if (!noOtherFilterOnTopOfParam(activeParams, 'makeModel')) {
    return '';
  }
  if (makeModel) {
    return get(makeModel, '0.value');
  }
  const makeModelParam = get(activeParams, 'makeModel');
  if (makeModelParam) {
    const makes = Object.keys(makeModelParam);
    if (makes.length > 1) {
      return '';
    }
    const make = makes[0];
    if (make) {
      return new TextTransform(make.replace(/-/g, ' '))
        .startCase()
        .spaceAtTheEnd()
        .toString();
    }
  }
  return '';
};

export const filteredOnlyByOneModel = activeParams => {
  if (!noOtherFilterOnTopOfParam(activeParams, 'makeModel')) {
    return '';
  }
  const makeModel = get(activeParams, 'makeModel');

  if (makeModel) {
    const makes = Object.keys(makeModel);
    if (makes.length > 1) {
      return '';
    }
    const make = makes[0];
    if (make) {
      if (makeModel[make].length > 1) {
        return '';
      }
      const model = makeModel[make][0];
      if (model) {
        return new TextTransform(model.replace(/-/g, ' '))
          .startCase()
          .spaceAtTheEnd()
          .toString();
      }
    }
  }
  return '';
};

export const filteredOnlyByType = (activeParams, t) => {
  if (!noOtherFilterOnTopOfParam(activeParams, 'multiFacetedBoatTypeClass')) {
    return '';
  }
  const multiFacetedBoatTypeClass = get(activeParams, 'multiFacetedBoatTypeClass');
  if (multiFacetedBoatTypeClass) {
    const types = Object.keys(multiFacetedBoatTypeClass);
    if (types.length > 1) {
      return '';
    }
    const type = types[0];
    if (type) {
      const reviewMessages = getExpertReviewMessages();
      return t(reviewMessages[type]);
    }
  }
  return '';
};

export const filteredOnlyByClass = (activeParams, t) => {
  if (!noOtherFilterOnTopOfParam(activeParams, 'multiFacetedBoatTypeClass')) {
    return '';
  }
  const multiFacetedBoatTypeClass = get(activeParams, 'multiFacetedBoatTypeClass');
  if (multiFacetedBoatTypeClass) {
    const types = Object.keys(multiFacetedBoatTypeClass);
    if (types.length > 1) {
      return '';
    }
    const type = types[0];
    if (type) {
      if (multiFacetedBoatTypeClass[type].length > 1) {
        return '';
      }
      const typeClass = multiFacetedBoatTypeClass[type][0];
      if (typeClass) {
        const reviewMessages = getExpertReviewMessages();
        return t(reviewMessages[typeClass]);
      }
    }
  }
  return '';
};

export const getExpertReviewsTitleComplement = (activeParams, t, makeModel) => {
  if (!activeParams) {
    return '';
  }

  const make = filteredOnlyByOneMake(activeParams, makeModel) || get(makeModel, '0.value');
  const model = filteredOnlyByOneModel(activeParams);
  const classParam = filteredOnlyByClass(activeParams, t);
  const type = !classParam ? filteredOnlyByType(activeParams, t) : '';

  return sortedUniq([
    type,
    classParam,
    make,
    model
  ]).join(' ').trim();
};

class ExpertReviewsTitle extends PureComponent {
  render() {
    const { t, titleI18n, searchParams, makeModel } = this.props;
    const activeParams = omitBy(getActiveParams(getDefaultParams(cloneDeep(searchParams))), isEmpty);
    const titleComplement = getExpertReviewsTitleComplement(activeParams, t, makeModel);

    if (titleComplement === '') {
      return null;
    }

    return <h3 className="expert_reviews_title">{t(titleI18n, { titleComplement })}</h3>;
  }
}

ExpertReviewsTitle.propTypes = {
  searchParams: PropTypes.object,
  t: PropTypes.func,
  titleI18n: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  makeModel: PropTypes.array
};

export default ExpertReviewsTitle;
