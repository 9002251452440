import { getRadiusName, getRadiusItemAll } from '../../../utils/uomHelper';

let radiuses;
const startRadiuses = () => {
    radiuses = [
      { label: getRadiusItemAll(), value: '0' },
      { label: `25 ${getRadiusName()}`, value: '25' },
      { label: `50 ${getRadiusName()}`, value: '50' },
      { label: `100 ${getRadiusName()}`, value: '100' },
      { label: `200 ${getRadiusName()}`, value: '200' },
      { label: `300 ${getRadiusName()}`, value: '300' },
      { label: `500 ${getRadiusName()}`, value: '500' },
      { label: `1000 ${getRadiusName()}`, value: '1000' },
      { label: `2000 ${getRadiusName()}`, value: '2000' },
      { label: `5000 ${getRadiusName()}`, value: '5000' }
    ];
  return radiuses;
};

export const getRadiuses = () => radiuses || startRadiuses();

export const locationParam = {
  country: '',
  region: '',
  subdivision: '',
  city: []
};
