import { useContext } from 'react';
import ABTestContext, { isFeatureFlagActive, FEATURE_FLAG_KEYS } from "../ABTestContext";

export const useFeatureFlags = (cookies) => {
  const abTestContext = useContext(ABTestContext);

  const featureFlagFuzzySponsored = isFeatureFlagActive(
    FEATURE_FLAG_KEYS.FUZZY_SPONSORED_BOATS,
    abTestContext?.featureFlags,
    cookies
  );

  const featureFlagRandomizedSponsored = isFeatureFlagActive(
    FEATURE_FLAG_KEYS.RANDOMIZED_SPONSORED,
    abTestContext?.featureFlags,
    cookies
  );

  const featureFlagListingImageCarousel = isFeatureFlagActive(
    FEATURE_FLAG_KEYS.LISTING_IMAGE_CAROUSEL,
    abTestContext?.featureFlags,
    cookies
  );

  const featureFlagEngineDetailsFilter = isFeatureFlagActive(
    FEATURE_FLAG_KEYS.ENGINE_DETAILS_FILTER,
    abTestContext?.featureFlags,
    cookies
  );

  const featureFlagEngineMakesFilter = isFeatureFlagActive(
    FEATURE_FLAG_KEYS.ENGINE_MAKES_FILTER,
    abTestContext?.featureFlags,
    cookies
  );

  const featureFlagFinanceVariant = isFeatureFlagActive(
    FEATURE_FLAG_KEYS.BOATS_BDP_FINANCE_V2,
    abTestContext?.featureFlags,
    cookies
  );

  const featureFlagAdButler = isFeatureFlagActive(
    FEATURE_FLAG_KEYS.AD_BUTLER,
    abTestContext?.featureFlags,
    cookies
  );

  return {
    featureFlagFuzzySponsored,
    featureFlagRandomizedSponsored,
    featureFlagListingImageCarousel,
    featureFlagEngineDetailsFilter,
    featureFlagEngineMakesFilter,
    featureFlagFinanceVariant,
    featureFlagAdButler
  };
};
