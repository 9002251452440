import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { ToolSetOptions, ToolSetOptionsItem } from '../../../../../../components/ToolSet';
import { CollapsibleContent } from '../../../../../../components/Collapsible';
import { FilterTypeAhead } from '../..';
import { generateSearchPath } from '../../../../../../utils/urlHelpers/boats';
import { injectIntl } from 'react-intl';
import Link from '../../../../../../components/SEO/Link';
import { getMessages } from '../../../../../../tppServices/translations/messages';


export class FilterSubdivision extends PureComponent {
  state = { subdivision: '' };
  constructor(props) {
    super(props);
    this.translationMessages = getMessages();
  }
  updateMultiInput() {
    if (get(this.props, 'subdivision')) {
      this.setState({ subdivision: this.props.subdivision });
    } else {
      this.setState({ subdivision: 'all' });
    }
  }

  componentDidMount() {
    this.updateMultiInput();
  }

  componentDidUpdate() {
    this.updateMultiInput();
  }

  getRegion(subdivision) {
    if (subdivision.region) {
      return subdivision.region;
    }

    if (subdivision.value === 'all' && this.props.chosenRegion) {
      return this.props.chosenRegion;
    }

    return '';
  }

  renderSubdivisions(items) {
    const messages = this.translationMessages;
    let { params, intl: { formatMessage: t } } = this.props;
    const selectedSubdivision = get(this.state, 'subdivision', '');
    return <div className="search-filter">
      <div className="subdivisions">
        <ToolSetOptions>
          { items.map(subdivision => {
            return <ToolSetOptionsItem
              key={`Subdivision-${subdivision.value}`}
              id={`Subdivision-${subdivision.value}`}
              name={`Subdivision-${subdivision.value}`}
              value={subdivision.value}
              selected={selectedSubdivision === subdivision.value}
              onClick={(value) => {
                if (this.props.tracking) {
                  this.props.tracking.facetAdded({ 'subdivision': value });
                }
                this.props.handleDataChange('subdivision', value);
              }}
            >
              <Link
                className="subdivision-type-link"
                href={generateSearchPath(
                  {subdivision: subdivision.value, region: this.getRegion(subdivision)},
                  params,
                  true
                )}
                onClick={(e) => e.preventDefault()}
              >
                {subdivision.value === 'all' ? t(messages.allSubdivisions) : t(messages.countrySubdivision[subdivision.value])}
              </Link>
            </ToolSetOptionsItem>;
          })
          }
        </ToolSetOptions>
      </div>
    </div>;
  }

  render() {
    const { params, position, intl: { formatMessage: t }  } = this.props;
    const selectedSubdivision = get(this.state, 'subdivision', '');
    const messages = this.translationMessages;
    const sortedSubdivisions = sortBy(this.props.subdivisions, 'value')
      .map(subdivision => ({
        ...subdivision,
        filterValue: t(messages.countrySubdivision[subdivision.value]),
      }));
    const subdivisions = [{value: 'all', count: 0}, ...sortedSubdivisions];

    return (
      <div className={'search-filter subdivisions'}>
        <CollapsibleContent initialState="open">
          <FilterTypeAhead
            items={subdivisions}
            selectedItems={[selectedSubdivision]}
            id={`Subdivision-${position}`}
            moreMessage="moreSubdivisions"
            placeholder={`${t(messages.subdivisionsPlaceholder)}`}
            max={subdivisions.length}
            render={(options, callback) => this.renderSubdivisions(options, callback)}
            showAll={true}
            url={generateSearchPath(params) + 'subdivision/'}/>
        </CollapsibleContent>
      </div>
    );
  }
}

FilterSubdivision.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  chosenRegion: PropTypes.string,
  subdivision: PropTypes.string,
  subdivisions: PropTypes.array,
  params: PropTypes.object,
  position: PropTypes.string,
  handleDataChange: PropTypes.func,
  tracking: PropTypes.shape({
    facetAdded: PropTypes.func
  })
};

export default injectIntl(FilterSubdivision);
