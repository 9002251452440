import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTitle, getPrice, getLocation } from '../../utils/listingHelper';
import { setProductImpression } from '../../store/actions/dataLayer';
import ListingImage from '../ListingImage';
import Link from '../SEO/Link';
import { setListingClick } from '../../store/actions/dataLayer';
import { PRODUCT_CATEGORY } from '../../constants/dataLayer';

const MiniListing = (props) => {
  useEffect(() => {
    const { listing, listingType } = props;
    const { id, model, make } = listing;
    props.setProductImpression(
      id,
      listingType,
      undefined,
      undefined,
      props.isEngine ? PRODUCT_CATEGORY.ENGINE : PRODUCT_CATEGORY.BOAT,
      null,
      null,
      model,
      getTitle(listing),
      make
    );
  }, []);

  const { listing, listingType, href, customPricingOptions } = props;
  const listingAnchorParams = {
    href,
    'data-reporting-click-product-id': listing.id,
    'data-reporting-click-listing-type': listingType,
    role: 'link',
    onClick: () => {
      setListingClick(listing.id, listingType, '', '');
    }
  };

  return (
    <li
      data-listing-id={listing.id}
      data-reporting-click-product-id={listing.id}
      data-reporting-click-listing-type={listingType}
    >
      <div className="boat-image">
        <Link {...listingAnchorParams}>
          <ListingImage
            listing={listing}
            resize={{ width: 200, height: 146 }}
            lazyLoad={false}
          />
        </Link>
      </div>
      <Link {...listingAnchorParams}>
        <div className="information">
          <div className="make-model">{getTitle(listing)}</div>
          <div className="price">{getPrice(listing, customPricingOptions)}</div>
          <div className="location">{getLocation(listing)}</div>
          <div className="seller-name">{listing.owner.name}</div>
        </div>
      </Link>
    </li>
  );
};

MiniListing.propTypes = {
  /** Boats Listing to be displayed */
  listing: PropTypes.shape({
    id: PropTypes.number,
    model: PropTypes.string,
    make: PropTypes.string,
    year: PropTypes.number,
    owner: PropTypes.shape({
      name: PropTypes.string
    })
  }),
  /**  Listing URL  */
  href: PropTypes.string,
  /** Listing type used for tracking and reporting */
  listingType: PropTypes.string,
  /** Analytics helper  */
  setProductImpression: PropTypes.func.isRequired,
  /** Custom pricing options used to get configurable currency symbols and number separators*/
  customPricingOptions: PropTypes.object,
  isEngine: PropTypes.bool
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      setProductImpression
    },
    dispatch
  )
)(MiniListing);
