import get from 'lodash/get';
import {asArray} from '../../../utils';
import {singleHyphenateUrlComponents} from '../../../utils/urlHelpers/boats';
import includes from 'lodash/includes';
import filter from 'lodash/filter';

const subdivisionsWithName = (subdivisions, chosenCountry, translations, intl) => {
  const t = intl.formatMessage;
  const sorted = asArray(subdivisions).sort((a, b) => a.value > b.value ? 1 : -1);
  const sortedSubdivisions = sorted.map(subdivision => {
    const translationKey = `countrySubdivision.${chosenCountry}.${subdivision.value}`;
    // Fast solution to fix the P1 issue, this logic will be improved as part of TPP-4663
    try {
      const filterValue = t(get(translations, translationKey));
      return {...subdivision, filterValue};
    } catch (error) {
      /* istanbul ignore next */
      return null;
    }
  }).filter(subdivision => subdivision !== null);

  return [{value: 'all', count: 0}, ...sortedSubdivisions];
};

const regionsWithName = (regions, translations, intl) => {
  const t = intl.formatMessage;
  const sorted = asArray(regions).sort((a, b) => a.value > b.value ? 1 : -1);
  const sortedSubdivisions = sorted.map(subdivision => {
    const translationKey = `countryRegions.${subdivision.value}`;
    const filterValue = t(get(translations, translationKey));
    return {...subdivision, filterValue};
  });
  return [{value: 'all', count: 0}, ...sortedSubdivisions];
};

const getCityName = (cities, slugCity) => {
  const city = cities.find(city => singleHyphenateUrlComponents(city.value) === slugCity);
  return city.value;
};

const toggleCity = (city, selectedCities) => {
  const cityIsSelected = includes(selectedCities, city);
  if (cityIsSelected) {
    return filter(selectedCities, cityName => cityName !== city);
  }
  return [...selectedCities, city];
};

export {
  subdivisionsWithName,
  regionsWithName,
  getCityName,
  toggleCity
};
