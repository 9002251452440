import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.css';

class ToolSetRadioGroup extends PureComponent {
  render() {
    let { children } = this.props;
    return <div className="tool-set-radio-group">
      {children}
    </div>;
  }
}

ToolSetRadioGroup.propTypes = {
  children: PropTypes.node,
};

class ToolSetRadioItem extends PureComponent {
  render() {
    let { name, id, value, checked, onChange, children } = this.props;
    return <div className={classnames('tool-set-radio-item', { checked })}>
      <input type="radio" id={id} name={name} value={value} checked={checked} className="visuallyhidden" onChange={onChange} />
      <label htmlFor={id}>{children}</label>
    </div>;
  }
}

ToolSetRadioItem.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export {
  ToolSetRadioGroup,
  ToolSetRadioItem
};
