import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

export default class AlertMessage extends React.PureComponent {
  render() {
    const { mainText, secondaryText } = this.props;

    return (
      <div className="alert">
        <div className="icon"></div>
        <div className="text">
          <span className="bold">{mainText}</span>
          <br />
          {secondaryText}
        </div>
      </div>
    );
  }
}

AlertMessage.defaultProps = {
  secondaryText: ''
};

AlertMessage.propTypes = {
  /** Main text of the alert */
  mainText: PropTypes.string.isRequired,
  /** Secondary text for further description */
  secondaryText: PropTypes.string
};
