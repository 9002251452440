import get from 'lodash/get';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import keys from 'lodash/keys';
import { adsHelper } from '@dmm/react-common-components';
import { getLegacyMappingFromBoatClass } from '../../../../utils/classHelper';
import { canonicalizeDataString, isValidCondition} from '../../../../utils/ads/adsUtils';
import {getBoatConstantsFromI18n} from '../../../../tppServices/translations/constants';
/* eslint-disable camelcase */
export const adsConfig = {
  'div-gpt-ad-box-1': {
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300, 600],
      [300, 250]
    ],
    targeting: {
      pos: 'box-1'
    }
  },
  'div-gpt-ad-box-2': {
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'box-2'
    }
  },
  'div-gpt-ad-box-3': {
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'box-3'
    },
    isLazy: true
  },
  'div-gpt-ad-leaderboard-alpha': {
    breakpoint: {
      min: 1116,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [728, 90],
    targeting: {
      pos: 'leaderboard-alpha'
    }
  },
  'div-gpt-ad-leaderboard-bottom': {
    breakpoint: {
      min: 728,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [728, 90]
    ],
    targeting: {
      pos: 'leaderboard-bottom'
    },
    isLazy: true
  },
  'div-gpt-ad-leaderboard-mid-1': {
    breakpoint: {
      min: 1116,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [728, 90],
    targeting: {
      pos: 'leaderboard-mid-1'
    }
  },
  'div-gpt-ad-leaderboard-mid-2': {
    breakpoint: {
      min: 1116,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [728, 90],
    targeting: {
      pos: 'leaderboard-mid-2'
    }
  },
  'div-gpt-ad-mobile-leaderboard-custom': {
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [
      [320, 50],
      [320, 80],
      [320, 100]
    ],
    targeting: {
      pos: 'mobile-leaderboard-custom'
    }
  },
  'div-gpt-ad-mobile-box-1': {
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'mobile-box-1'
    }
  },
  'div-gpt-ad-mobile-box-2': {
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'mobile-box-2'
    }
  }
};

export const getTargeting = (searchQuery, uom, dealer, i18nService) => {
  let lengthMin = get(searchQuery.length, 'min') !== '0' || get(searchQuery.length, 'max') ? get(searchQuery.length, 'min') : undefined;
  let lengthMax = get(searchQuery.length, 'max');
  const boatConstants = getBoatConstantsFromI18n(i18nService);
  const yearMin = get(searchQuery.year, 'min') !== boatConstants.DEFAULT_MIN_YEAR || get(searchQuery.year, 'max') ? get(searchQuery.year, 'min') : undefined;
  const yearMax = get(searchQuery.year, 'max');
  let legacyBoatClass;
  if (!isEmpty(searchQuery.multiFacetedBoatTypeClass)) {
    const boatClass = searchQuery.multiFacetedBoatTypeClass[keys(searchQuery.multiFacetedBoatTypeClass)[0]][0];
    legacyBoatClass = canonicalizeDataString(getLegacyMappingFromBoatClass(boatClass));
  }

  let targeting = {
    type: canonicalizeDataString(keys(searchQuery.multiFacetedBoatTypeClass)[0]),
    class: legacyBoatClass,
    condition: isValidCondition(searchQuery.condition) ? searchQuery.condition : undefined,
    length: adsHelper.getLengthRanges(lengthMin, lengthMax, uom),
    year: adsHelper.getYearRanges(yearMin, yearMax),
    make: searchQuery.makeModel ? canonicalizeDataString(keys(searchQuery.makeModel)[0]) : undefined,
    state: searchQuery.subdivision || undefined,
    zip_code: searchQuery.postalCode ? searchQuery.postalCode : undefined,
    page: 'boat-search'
  };

  if (dealer) {
    targeting.dealer_id = dealer.id;
    targeting.dealercity = get(dealer, 'dealerLocation.city');
    targeting.dealercountry = get(dealer, 'dealerLocation.country');
  }
  targeting = omitBy(targeting, isUndefined);

  return targeting;
};
