import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import LoadingPlaceholder from '../LoadingPlaceholder';
import { getCurrentLocale } from '../../utils/language';

import './styles.css';

const DataTable = ({ data, dataName, isLoading }) => {
  const locale = getCurrentLocale();
  return (
    <div className={classnames('detail-data-table', { [dataName]: dataName })}>
      {isLoading || data.length === 0
        ? renderLoading()
        : renderData(data, locale)}
    </div>
  );
};

const renderTitledTable = (data, locale) => {
  return data.map((category, index) => {
    return (
      <div className="datatable-category" key={index} id={category.title}>
        <h3 className="sub-title">{category.title}</h3>
        {renderDataItems(category.value, locale)}
      </div>
    );
  });
};

const renderDataItems = (data, locale) => {
  const tableClass = data.length > 10 ? 'data-table__xlarge' : '';
  return (
    <table
      className={classnames('datatable-section', `${tableClass}`)}
      id="render-data-items"
    >
      <tbody className={classnames('datatable-section', `${tableClass}`)}>
        {data.map((item, index) => {
          const value = item.value === true ? '\u2713' : item.value;
          return (
            <tr className="datatable-item" key={index}>
              <td className="datatable-title">
                {item.title + (locale === 'fr' ? ' ' : '')}
              </td>
              <td className="datatable-value">{value}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const renderData = (data, locale) => {
  if (Array.isArray(data[0].value)) {
    return renderTitledTable(data, locale);
  }
  return renderDataItems(data, locale);
};

const renderLoading = () => {
  return (
    <>
      <div className="item">
        <LoadingPlaceholder type="text" id="loading-placeholder" />
      </div>
      <div className="item">
        <LoadingPlaceholder type="text" />
      </div>
    </>
  );
};

DataTable.defaultProps = {
  data: [],
  dataName: '',
  isLoading: false
};

DataTable.propTypes = {
  /** Supports nested values  */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
          title: PropTypes.string,
          value: PropTypes.any
        }),
        PropTypes.any
      ])
    })
  ),
  /** To add custom styling */
  dataName: PropTypes.string,
  /** If true, displays a LoadingPlaceholder */
  isLoading: PropTypes.bool
};

export default DataTable;
