import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ToolSetOptions, ToolSetOptionsItem } from '../../../../../../components/ToolSet';
import { generateSearchPath } from '../../../../../../utils/urlHelpers/boats';
import Link from '../../../../../../components/SEO/Link';
import { getMessages } from '../../../../../../tppServices/translations/messages';


class FilterRegion extends PureComponent {
  state = { region: undefined };

  updateMultiInput() {
    if (get(this.props, 'region')) {
      this.setState({ region: this.props.region });
    } else {
      this.setState({ region: 'all' });
    }
  }

  componentDidMount() {
    this.updateMultiInput();
  }

  componentDidUpdate() {
    this.updateMultiInput();
  }

  render() {
    let { params, regions } = this.props;
    const { intl: { formatMessage: t } } = this.props;
    const messages = getMessages();
    const selectedRegion = get(this.state, 'region', {});
    return <div className="search-filter">
      <div className="regions">
        <ToolSetOptions>
          <ToolSetOptionsItem
            key="region-all"
            id="region-all"
            name="region-all"
            value="all"
            selected={selectedRegion === 'all'}
            onClick={(value) => {
              if (this.props.tracking) {
                this.props.tracking.facetAdded({ 'region': value });
              }
              this.props.handleDataChange('region', value);
            }}
          >
            <Link className="region-type-link"
              href={generateSearchPath({region: '', subdivision: ''}, params, true)}
              onClick={(e) => e.preventDefault()}
            >{t(messages.allRegions)}</Link>
          </ToolSetOptionsItem>
          { regions.map(region => {
            const regionName = t(messages.countryRegions[region.value]);
            return <ToolSetOptionsItem
              key={`Region-${region.value}`}
              id={`Region-${region.value}`}
              name={`Region-${region.value}`}
              value={regionName}
              selected={ selectedRegion === region.value}
              onClick={() => {
                if (this.props.tracking) {
                  this.props.tracking.facetAdded({ 'region': region.value });
                }
                this.props.handleDataChange('region', region.value);
              }}
            >
              <Link className="region-type-link"
                href={generateSearchPath({region: region.value, subdivision: ''}, params, true)}
                onClick={(e) => e.preventDefault()}
              >{regionName}</Link>
            </ToolSetOptionsItem>;
          })
          }
        </ToolSetOptions>
      </div>
    </div>;
  }
}

FilterRegion.propTypes = {
  region: PropTypes.string,
  regions: PropTypes.array,
  params: PropTypes.object,
  position: PropTypes.string,
  handleDataChange: PropTypes.func,
  tracking: PropTypes.object,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired
};

export default injectIntl(FilterRegion);
