import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ToolSetRadioGroup, ToolSetRadioItem } from '../../../../../components/ToolSet';
import { injectIntl } from 'react-intl';

import { generateSearchPath } from '../../../../../utils/urlHelpers/boats';
import Link from '../../../../../components/SEO/Link';
import { yieldToMain } from '../../../../../utils';
import { getMessages } from '../../../../../tppServices/translations/messages';

const  FilterCondition = (props) => {
  let { condition: propCondition, params, position = '', intl: { formatMessage: t } } = props;
  let dashPosition = position ? '-' + position : '';
  const [condition, setCondition] = useState(propCondition);
  const dispatch = useDispatch();
  const messages = getMessages();
  useEffect(() => {
    setCondition(propCondition);
  }, [propCondition]);

  const handleDataChange = async (value) => {
    if (props.tracking) {
      props.tracking.facetAdded({ ['condition']: value });
    }
    await yieldToMain();
    setCondition(value);
    if (position !== 'mobile'){
      dispatch({ type: 'GET_DATA_LOADING' });
      await yieldToMain();
    }
    props.handleDataChange('condition', value);
  };

  return (
      <div className="search-filter condition">
        <div className="search-filter-group">
          <ToolSetRadioGroup>
            <ToolSetRadioItem
              id={`condition-any${dashPosition}`}
              name={`condition${dashPosition}`}
              value="any"
              checked={condition === 'any'}
              onChange={(value) => handleDataChange(value.target.value)}
            >
              <Link className="filter-condition-link" href={generateSearchPath({condition: 'any'}, params, true)}>{t(messages.all)}</Link>
            </ToolSetRadioItem>
            <ToolSetRadioItem
              id={`condition-new${dashPosition}`}
              name={`condition${dashPosition}`}
              value="new"
              checked={condition === 'new'}
              onChange={(value) => handleDataChange(value.target.value)}
            >
              <Link className="filter-condition-link" href={generateSearchPath({condition: 'new'}, params, true)}>
                {t(messages.new)}
              </Link>
            </ToolSetRadioItem>
            <ToolSetRadioItem
              id={`condition-used${dashPosition}`}
              name={`condition${dashPosition}`}
              value="used"
              checked={condition === 'used'}
              onChange={(value) => handleDataChange(value.target.value)}
            >
              <Link className="filter-condition-link" href={generateSearchPath({condition: 'used'}, params, true)}>
                { t(messages.used) }
              </Link>
            </ToolSetRadioItem>
          </ToolSetRadioGroup>
        </div>
      </div>
    );
};

FilterCondition.propTypes = {
  condition: PropTypes.oneOf(['any', 'new', 'used']),
  handleDataChange: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  dispatch: PropTypes.func,
  params: PropTypes.object,
  position: PropTypes.string,
  tracking: PropTypes.shape({
    facetAdded: PropTypes.func.isRequired,
  }),
};

export default injectIntl(FilterCondition);
