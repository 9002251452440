import PropTypes from 'prop-types';
import {ToolSetOptionsItem} from '../../../../../../../components/ToolSet';
import Link from '../../../../../../../components/SEO/Link';
import React from 'react';

const CityToolSetItem = ({city, onItemClick, linkHref, linkName, isSelected, position}) => {
  return (
    <ToolSetOptionsItem
      type="checkbox"
      key={`City-${city.value}-all`}
      id={`City-${city.value}-all`}
      name={`City-${city.value}-${position}`}
      value={city.value}
      selected={isSelected}
      onClick={onItemClick}
    >
      <Link
        className="city-type-link"
        href={linkHref}
        onClick={(e) => e.preventDefault()}
      >
        {linkName}
      </Link>
    </ToolSetOptionsItem>
  );
};

CityToolSetItem.propTypes = {
  subdivision: PropTypes.object,
  onItemClick: PropTypes.func,
  linkHref: PropTypes.string,
  linkName: PropTypes.string,
  isSelected: PropTypes.bool,
  position: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  city: PropTypes.object
};

export default CityToolSetItem;
