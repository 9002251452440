import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { YoutubeVideos as YoutubeVideosLib } from '@dmm/lib-react-videos';
import { isMobileResolution, BREAKPOINTS } from '../../../../utils/commonHelper';

const checkPortal = () => isMobileResolution() ? BREAKPOINTS.mobile : BREAKPOINTS.desktop;

const YoutubeVideos = ({ videos = [], portal = '' }) => {
  const [device, setDevice] = useState(BREAKPOINTS.mobile);
  useEffect(() => {
    const resizeListener = () => {
      setDevice(checkPortal());
    };
    window.addEventListener('resize', resizeListener);
    // Required to bypass a SSR issue with window that breaks styles
    window.dispatchEvent(new Event('resize'));
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  });

  if (!videos || !videos.length) {
    return null;
  }

  const videoArgs = { videos, device, portal: portal?.toLowerCase() };

  return (
    <div className="expert-videos">
      <YoutubeVideosLib {...videoArgs} />
    </div>
  );
};

YoutubeVideos.propTypes = {
  videos: PropTypes.array,
  portal: PropTypes.string
};

export default YoutubeVideos;
