import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import includes from 'lodash/includes';
import FilterTypeAhead from '../../../FilterTypeAhead';

import CityToolSetItem from './CityToolsetItem';
import {getCityName, toggleCity} from '../../../../../utils/locations';
import {singleHyphenateUrlComponents, generateSearchPath} from '../../../../../../../utils/urlHelpers/boats';
import {ToolSetOptions} from '../../../../../../../components/ToolSet';
import { yieldToMain } from '../../../../../../../utils';

import {useTPPServices} from '../../../../../../../tppServices/tppDIHooks';
import {slugify} from '../../../../../../../utils/urlHelpers/shared';
import { getMessages } from '../../../../../../../tppServices/translations/messages';


const optionsRenderer = (cities, selectedCities, position, params, handleCityChange, urlGenerator) => {
  const renderer = (items, selectionCallback) => {
    const cityItems = items.map((city, i) => {
      const citySelected = includes(selectedCities, city.value);
      const toggledCities = toggleCity(city.value, selectedCities);
      const cityName = getCityName(cities, city.value);
      const linkHref = urlGenerator({ city: toggledCities }, params, true);
      const onCityClick = (value) => {
        handleCityChange('city', toggledCities, city.value, citySelected);
        selectionCallback(value);
      };
      return (<CityToolSetItem
        city={city}
        position={position}
        isSelected={citySelected}
        onItemClick={onCityClick}
        linkName={cityName}
        linkHref={linkHref}
        key={`${city}-${i}`}
      />);}
    );
    return ( <ToolSetOptions> {cityItems} </ToolSetOptions> );
  };
  return renderer;
};

const FilterCity = ({
  position, intl, tracking, handleDataChange, device,
  selectedCountry = '', selectedCities = [], cities = [], params = {},
  maxCitiesCount, translations = getMessages(), urlGenerator = generateSearchPath} ) => {
  const {boatsConstants} = useTPPServices();
  maxCitiesCount = maxCitiesCount ?? boatsConstants.MAX_CITIES_COUNT;
  const dispatch = useDispatch();
  const handleCityChange =  async (type, value, slugCity, selected) => {
    if (tracking) {
      if (selected) {
        tracking.facetRemoved(`city ${slugCity} removed`);
      } else {
        tracking.facetAdded({'city': slugCity});
      }
    }
    await yieldToMain();
    if (device !== 'mobile'){
      dispatch({ type: 'GET_DATA_LOADING' });
    }
    handleDataChange(type, value);
  };
  const t = intl.formatMessage;
  const formattedCities = cities.map(city => ({value: singleHyphenateUrlComponents(city.value)}));
  const formattedSelectedCities = selectedCities.map(city => slugify(city));
  const showAllCities = get(params, 'modal', []).includes('city');
  const rendererFunction = optionsRenderer(cities, selectedCities, position, params, handleCityChange, urlGenerator);

  return (
    <div className={'search-filter cities'}>
      <FilterTypeAhead
        items={formattedCities}
        selectedItems={formattedSelectedCities}
        id={`city-type-ahead-${position}`}
        placeholder={t(translations.cityPlaceholder, {country: selectedCountry})}
        moreMessage="moreCities"
        max={maxCitiesCount}
        url={urlGenerator(params) + 'modal-city/'}
        showAll={showAllCities}
        render={rendererFunction} />
    </div>
  );
};

FilterCity.propTypes = {
  device: PropTypes.string,
  selectedCountry: PropTypes.string,
  selectedCities: PropTypes.array,
  cities: PropTypes.array,
  maxCitiesCount: PropTypes.number,
  params: PropTypes.object,
  position: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  handleDataChange: PropTypes.func,
  tracking: PropTypes.shape({
    facetAdded: PropTypes.func,
    facetRemoved: PropTypes.func,
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  translations: PropTypes.object,
  urlGenerator: PropTypes.func,
};

export default injectIntl(FilterCity);
