import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';
import SortSelector from '../SortSelector';
import { getDefaultParams } from '../../../../utils/urlHelpers/boats';
import { getResultsCount } from '../../../../utils/paginationHelper';
import { getConfig } from '../../../../config/portal';
import FilterRadiusDropdown from '../FilterRadiusDropdown';
import './styles.css';

class ResultsHeader extends PureComponent {
  render() {
    let pageParams = get(this.props, 'match.params', {});
    let params = getDefaultParams(pageParams);
    let count = +get(this.props, 'search.count', 0);
    let seoMakeInfo = get(this.props, 'seoMakeInfo', {});
    const viewing = getResultsCount(
      params,
      count,
      undefined,
      seoMakeInfo,
      undefined,
      get(this.props, 'brand.name', '')
    );

    const isRadiusSearchEnabled = get(getConfig(), 'supports.radiusSearch.enabled', false);

    return (
      <div className="results-header">
        {!this.props.isBranded && isRadiusSearchEnabled && this.props.isMobile && (
          <div className="results-container">
            <div className="results-count">{viewing}</div>
            <FilterRadiusDropdown
              urlParams={params}
              history={this.props.history}
              filterState={this.props.filterState}
            />
          </div>
        )}
        {!this.props.isBranded && isRadiusSearchEnabled && !this.props.isMobile && (<div className="results-count">{viewing}</div>)}
        {!this.props.isBranded && !isRadiusSearchEnabled && ( <div className="results-count">{viewing}</div>)}

        {this.props.isBranded && <h2 className="results-count">{viewing}</h2>}
        {!this.props.isMobile && <SortSelector {...this.props} />}
      </div>
    );
  }
}

ResultsHeader.propTypes = {
  isMobile: PropTypes.bool,
  isBranded: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  filterState: PropTypes.object,
};

export default injectIntl(ResultsHeader);
