import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { generateSearchPath } from '../../../../utils/urlHelpers/boats';
import { getRadiuses, locationParam } from '../../utils/radiusSearch';
import './styles.css';

const FilterRadiusDropdown = ({ urlParams = {}, history, filterState = {} }) => {
  const [newRadius, setNewRadius] = useState(urlParams.radius);
  const radiuses = getRadiuses();

  useEffect(() => {
    setNewRadius(filterState.radius);
  }, [filterState.radius]);

  const handleOnChangeDistance = (e) => {
    const value = e.target.value;
    setNewRadius(value);

    const isValidfilterState = Object.keys(filterState).length > 0;
    const pathParams = isValidfilterState ? filterState : urlParams;
    const newPathParams = {
      ...pathParams,
      radius: value,
      ...locationParam
    };

    const url = generateSearchPath(newPathParams, pathParams, true);
    history.push(url);
  };

  return (
    <select
      className="filter-radius-dropdown"
      value={newRadius}
      onChange={handleOnChangeDistance}
    >
      {radiuses.map((obj) => (
        <option key={obj.value} value={obj.value}>
          {obj.label}
        </option>
      ))}
    </select>
  );
};

FilterRadiusDropdown.propTypes = {
  urlParams: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  filterState: PropTypes.object
};

export default FilterRadiusDropdown;
