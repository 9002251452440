import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const LoadingPlaceholder = (props) => {
  const renderTextLoader = () => {
    return <div className="loading-text" />;
  };

  const TEXT_TYPE = 'text';

  const { type } = props;
  switch (type) {
    case TEXT_TYPE:
      return renderTextLoader();
    default:
      return null;
  }
};

LoadingPlaceholder.defaultProps = {
  type: ''
};

LoadingPlaceholder.propTypes = {
  /** Placeholder type */
  type: PropTypes.string
};

export default LoadingPlaceholder;
