import React, { PureComponent } from 'react';
import Modal from 'react-modal';
import take from 'lodash/take';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import { formatNumber } from '@dmm/lib-common/lib/formatting';
import { getFormattedParams } from '../../../../../../utils/urlHelpers/boats';
import { getFormattedParams as getFormattedOemParams } from '../../../../../../utils/urlHelpers/oem';
import { SearchAlertsModal as SearchAlertsModalComponent } from '@dmm/lib-react-ui-components';
import validate from '../../../../../../utils/dataValidation';
import './style.css';
import ReactDOMServer from 'react-dom/server';


import { PortalConfigContext } from '../../../../../../config/portal';
import { getMessages } from '../../../../../../tppServices/translations/messages';

export const COUNT_FILTERS_TO_DISPLAY = 5;

class SearchAlertsModal extends PureComponent {
  constructor(props) {
    super(props);
  }

  state = {
    userEmail: '',
    invalidFormFields: []
  };

  componentDidUpdate() {
    const { show } = this.props;
    if (!show) {
      this.clearMail();
      // Clear invalid fields if the modal is closed
      if (this.state.invalidFormFields.length > 0) {
        this.setState({ invalidFormFields: [] });
      }
    }
  }

  clearMail() {
    this.setState({ userEmail: '' });
  }

  updateField(event) {
    this.setState({ userEmail: event.target.value });
  }

  doSubmit(event) {
    const { userEmail } = this.state;
    event.preventDefault();
    this.props.submit(userEmail);
  }

  handleSubmit(event, formData) {
    event.preventDefault();
    const isEmailValid = validate(formData.email, 'email');

    if (isEmailValid) {
      this.setState({ invalidFormFields: [] }, () => {
        this.props.submit(formData.email);
      });
    } else {
      this.setState({ invalidFormFields: ['email'] });
      // Without this the component re-renders and closes the modal
      event.preventDefault();
    }
  }

  groupByKey = (formattedParams, groupKey) => {
    const groupableKeys = [
      'makeModel',
      'city',
      'fuelType',
      'hullMaterial',
      'multiFacetedBoatTypeClass'
    ];
    if (!groupableKeys.includes(groupKey)) {
      return formattedParams;
    }
    const toBeGrouped = formattedParams.filter((item) => item.key === groupKey);
    if (toBeGrouped.length === 1) {
      return formattedParams;
    }

    let grouped = [];
    if (['multiFacetedBoatTypeClass', 'makeModel'].includes(groupKey)) {
      grouped = groupBy(toBeGrouped, (item) => {
        const value = get(item, 'value', '');
        const splittedValue = value.split(' - ');
        return splittedValue[0];
      });
    } else {
      grouped = groupBy(toBeGrouped, (item) => {
        return item.formattedKey;
      });
    }
    const groups = Object.keys(grouped).map((groupedKey) => {
      let groupName = '';
      const onlyValues = grouped[groupedKey].map(({ value, formattedKey }) => {
        if (value.includes('-')) {
          const splittedValue = value.split(' - ');
          groupName = splittedValue[0];
          const onlyValue = splittedValue[1];
          return onlyValue;
        }
        groupName = formattedKey;
        return value;
      });
      const { formattedKey, key, indexKey } = grouped[groupedKey][0];
      if (['multiFacetedBoatTypeClass', 'makeModel'].includes(groupKey)) {
        return {
          formattedKey,
          key: indexKey,
          value: [groupName, onlyValues.join(', ')].join(' - ')
        };
      }
      return {
        formattedKey,
        key,
        value: onlyValues.join(', ')
      };
    });
    const rest = formattedParams.filter((item) => item.key !== groupKey);
    return [...groups, ...rest];
  };

  getFormattedParams() {
    const { active = {}, makeModel, seoMakeInfo, isBranded } = this.props;
    let formattedParams = isBranded
      ? getFormattedOemParams(active, makeModel, seoMakeInfo)
      : getFormattedParams(active, makeModel, seoMakeInfo);
    const distinctKeys = uniq(formattedParams.map((item) => item.key));
    distinctKeys.forEach((key) => {
      formattedParams = this.groupByKey(formattedParams, key);
    });
    return formattedParams;
  }

  getFormattedParamValue(param, enableNewSaveSearch = false) {
    const { prefix, value, format, key, suffix } = param;
    const locale = this.context.locale;

    let formatValue = isObject(value)
      ? Object.entries(value)
          .filter(([subKey]) => subKey !== 'prefix' && subKey !== 'suffix')
          .map(([subKey, subValue], index) => (
            <span key={`${key}.${subKey}`}>
              {!!index && ' - '}
              {format === 'number' ? formatNumber(subValue, locale) : subValue}
            </span>
          ))
      : value;

    if (typeof formatValue === 'string') {
      const byDash = formatValue.split(' - ');
      const byComma = byDash.map((s) => s.split(',').map(startCase).join(', '));
      formatValue = byComma.join(' - ');
    }

    const formatValueWithTags = () => {
      return (
        <>
          {prefix}
          {formatValue}
          {suffix}
        </>
      );
    };

    // htmlString gets the HTML from the array of React elements
    const htmlString = ReactDOMServer.renderToStaticMarkup(<>{formatValue}</>);
    const textContent = htmlString.replace(/<[^>]*>/g, '');
    return enableNewSaveSearch ? `${prefix ?? ''}${textContent}${suffix ?? ''}` : formatValueWithTags();
  }

  render() {
    const {
      show,
      close,
      intl: { formatMessage: t },
      hasSearchCriteria
    } = this.props;
    const { userEmail } = this.state;
    const messages = getMessages();

    const paramsAsArray = this.getFormattedParams().filter(
      (param) => param.key !== 'page'
    );
    const firstFiveParams = take(paramsAsArray, COUNT_FILTERS_TO_DISPLAY);

    const enableNewSaveSearch = !!this.context?.supports?.enableNewSaveSearch;

    const searchCriterias = firstFiveParams.map((param) => {
      const value = this.getFormattedParamValue(param, enableNewSaveSearch);
      return {
        text: param.formattedKey,
        value: value
      };
    });

    return (
      <>
      {!enableNewSaveSearch && (<Modal
        isOpen={show}
        className="search-alerts-modal"
        overlayClassName="search-alerts-overlay"
        contentLabel="Search Alerts Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={() => close()}
      >
        <div className="container">
          <div className="header">
            <div className="title">{t(messages.searchAlerts.description)}</div>
            <button className="closeButton" onClick={() => close()}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <span className="visuallyhidden">
                  {t(messages.contactForm.close)}
                </span>
                <path
                  d="M13.2929 1.41L8.05645 6.64645L7.70289 7L8.05645 7.35355L13.2929 12.59L12.59 13.2929L7.35355 8.05645L7 7.70289L6.64645 8.05645L1.41 13.2929L0.707107 12.59L5.94355 7.35355L6.29711 7L5.94355 6.64645L0.707107 1.41L1.41 0.707107L6.64645 5.94355L7 6.29711L7.35355 5.94355L12.59 0.707107L13.2929 1.41Z"
                  fill="white"
                  stroke="#2C272D"
                />
              </svg>
            </button>
          </div>
          <form onSubmit={(e) => this.doSubmit(e)} className="content">
            <label htmlFor="search-alerts-email" id="search-alerts-email-label">
              {t(messages.searchAlerts.yourEmail)}
            </label>
            <ul id="first-five-params-ul">
              {firstFiveParams.map((param) => (
                <li key={param.indexKey || param.key}>
                  {param.formattedKey}: {this.getFormattedParamValue(param)}
                </li>
              ))}
              {paramsAsArray.length > COUNT_FILTERS_TO_DISPLAY && (
                <li>+{paramsAsArray.length - COUNT_FILTERS_TO_DISPLAY}</li>
              )}
            </ul>
            <div className="flexbox">
              <input
                id="search-alerts-email"
                type="email"
                aria-labelledby="search-alerts-email-label"
                required
                placeholder={t(messages.searchAlerts.email)}
                onChange={(e) => this.updateField(e)}
                value={userEmail}
              />
              <button type="submit">
                {t(messages.searchAlerts.subscribe)}
              </button>
            </div>
            <span>
              {t(messages.searchAlerts.termsAndConditions.disclaimer)}{' '}
              <a
                href={t(messages.searchAlerts.termsAndConditions.url)}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t(messages.searchAlerts.termsAndConditions.name)}
              </a>
            </span>
          </form>
        </div>
      </Modal>)}

      {enableNewSaveSearch && show && (
          <SearchAlertsModalComponent
            email={{
              label: t(messages.searchAlerts.email),
              id: 'email',
              name: 'email'
            }}
            modal={{
              onClose: () => close()
            }}
            title={ t(messages.searchAlerts.description) }
            textWarning={t(messages.searchAlerts.textWarning)}
            textFooter={t(messages.searchAlerts.termsAndConditions.disclaimer)}
            link={{
              url: t(messages.searchAlerts.termsAndConditions.url),
              text: t(messages.searchAlerts.termsAndConditions.name),
              target: '_blank',
              rel: 'noopener noreferrer'
            }}
            Label={{
              text: t(messages.searchAlerts.yourEmail),
              htmlFor: 'email'
            }}
            classNames={{
              warning: hasSearchCriteria,
            }}
            buttonDisabled={hasSearchCriteria}
            buttonLabel={t(messages.searchAlerts)}
            list={searchCriterias}
            onSubmit={this.handleSubmit.bind(this)}
            invalidFields={this.state.invalidFormFields}
          />
        )}
      </>
    );
  }
}

SearchAlertsModal.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  show: PropTypes.bool,
  close: PropTypes.func,
  submit: PropTypes.func /** Active filters */,
  active: PropTypes.shape({
    /** Selected condition */
    condition: PropTypes.oneOf(['new', 'used']),
    /** Selected engine type */
    engine: PropTypes.string,
    /** Selected seller type */
    forSale: PropTypes.oneOf(['dealer', 'owner']),
    /** Selected fuel types */
    fuelType: PropTypes.arrayOf(PropTypes.string),
    /** Selected hull materials */
    hullMaterial: PropTypes.arrayOf(PropTypes.string),
    /** Selected length range */
    length: PropTypes.shape({
      max: PropTypes.string,
      min: PropTypes.string
    }),
    /** Selected makes and models */
    makeModel: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    /** Selected types and classes */
    multiFacetedBoatTypeClass: PropTypes.shape({
      power: PropTypes.arrayOf(PropTypes.string),
      sail: PropTypes.arrayOf(PropTypes.string),
      unpowered: PropTypes.arrayOf(PropTypes.string)
    }),
    /** Selected radius: exact | number */
    radius: PropTypes.string,
    /** Selected sort: distance:asc, length:desc, etc. */
    sort: PropTypes.string,
    /** Selected subdivision initials */
    subdivision: PropTypes.string,
    /** Selected year range */
    year: PropTypes.shape({
      max: PropTypes.string,
      min: PropTypes.string
    })
  }),
  /** Available makes */
  makeModel: PropTypes.arrayOf(
    PropTypes.shape({
      /** Make formatted name */
      value: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      model: PropTypes.arrayOf(
        PropTypes.shape({
          /** Model formatted name */
          value: PropTypes.string.isRequired,
          count: PropTypes.number.isRequired
        })
      )
    })
  ),
  seoMakeInfo: PropTypes.shape({
    make: PropTypes.string.isRequired,
    seoMakeName: PropTypes.string.isRequired
  }),
  isBranded: PropTypes.bool,
  hasSearchCriteria: PropTypes.bool
};

SearchAlertsModal.contextType = PortalConfigContext;

export default SearchAlertsModal;
