import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import { PortalConfigContext } from '../../config/portal';
import FormButtonArray from '../FormButtonArray';
import LocationMap from '../LocationMap';
import { getAddress } from '../../utils/commonHelper';
import './styles.css';
import { getMessages } from '../../tppServices/translations/messages';

class AddressModal extends Component {
  state = {
    showPhone: false
  };
  constructor(props) {
    super(props);
    this.translationMessages = getMessages();
  }

  containerRef = React.createRef();

  getCallNowMessage = () => {
    const {
      address,
      intl: { formatMessage: t }
    } = this.props;
    const messages = this.translationMessages;
    return this.state.showPhone
      ? address.phone
      : t(messages.dealerContact.callNow);
  };

  onCallClick = () => {
    this.setState({ showPhone: true });
    this.props.onCallClick();
  };

  getButtons = () => {
    const {
      address,
      intl: { formatMessage: t },
      onContactDealerOpen,
      onVisitWebsiteClick
    } = this.props;
    const messages = this.translationMessages;
    const { phone, website } = address;
    let buttons = [];
    if (website) {
      buttons.push({
        icon: 'call-made',
        isAnchor: true,
        href: website,
        message: t(messages.dealerContact.visitWebsite),
        onClick: onVisitWebsiteClick
      });
    }
    buttons.push({
      icon: 'email',
      message: t(messages.dealerContact.contactDealer),
      onClick: onContactDealerOpen
    });
    if (phone) {
      buttons.push({
        icon: 'call',
        isAnchor: true,
        href: `tel:${phone}`,
        message: this.getCallNowMessage(),
        target: '_self',
        onClick: this.onCallClick
      });
    }
    return buttons;
  };

  handleInnerClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      address,
      leadId,
      intl: { formatMessage: t }
    } = this.props;
    const messages = this.translationMessages;
    const { coordinates } = address;

    return (
      <div
        id="address-modal"
        className={classnames('address-modal-container', {
          modal: this.props.modal
        })}
        onClick={this.props.onClose}
      >
        <div
          className={classnames('address-modal', { open: this.props.open })}
          ref={this.containerRef}
          onClick={this.handleInnerClick}
        >
          <button
            type="button"
            className="address-close"
            onClick={this.props.onClose}
            aria-label="Close"
          >
            <span className="visuallyhidden">
              {t(messages.contactForm.close)}
            </span>
          </button>
          <span className="address-modal-title">{t(messages.address)}</span>
          <p className="address-modal-direction">{getAddress(address)}</p>
          <FormButtonArray buttons={this.getButtons()} />
          {coordinates && (
            <>
              <div className="location-map-wrapper">
                <LocationMap
                  coordinatesBoat={coordinates}
                  leadId={Number(leadId)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

AddressModal.defaultProps = {
  modal: false,
  open: false
};

AddressModal.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  address: PropTypes.shape({
    city: PropTypes.string,
    coordinates: PropTypes.arrayOf(PropTypes.number),
    country: PropTypes.string,
    countrySubDivisionCode: PropTypes.string,
    phone: PropTypes.string,
    postalCode: PropTypes.string,
    street: PropTypes.string,
    website: PropTypes.string
  }),
  leadId: PropTypes.string,
  modal: PropTypes.bool,
  /** Called when Close button is clicked or click outside the modal */
  onClose: PropTypes.func.isRequired,
  /** Called when Call dealer button is clicked */
  onCallClick: PropTypes.func.isRequired,
  /** Called when Contact dealer button is clicked */
  onContactDealerOpen: PropTypes.func.isRequired,
  /** Called when Visit Website dealer button is clicked */
  onVisitWebsiteClick: PropTypes.func.isRequired,
  open: PropTypes.bool
};

AddressModal.contextType = PortalConfigContext;

export default injectIntl(AddressModal);
