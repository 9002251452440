import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import findKey from 'lodash/findKey';
import { Collapsible, CollapsibleContent, CollapsibleHeader } from '../../../../components/Collapsible';
import { ToolSetOptions, ToolSetOptionsItem } from '../../../../components/ToolSet';
import { getDefaultSort, getSortNameMap, sortTypeMap } from '../../../../utils/sortHelper';
import { getSortByParam } from '../../../../store/actions';

import {PortalConfigContext} from '../../../../config/portal';
import { yieldToMain } from '../../../../utils';
import {getCommonBoatParsers} from '../../../../utils/urlHelpers/boatsConstantsParsers';
import { getMessages } from '../../../../tppServices/translations/messages';

const SortOption = (id, value, languageKey) => ({
  id, value, languageKey
});

const SortOptions = [
  SortOption('recommended', 'recommended:desc', 'recommended'),
  SortOption('updated-newest-first', 'updated:desc', 'newestUpdated'),
  SortOption('updated-oldest-first', 'updated:asc', 'oldestUpdated'),
  SortOption('year-newest-first', 'year:desc', 'newestYear'),
  SortOption('year-oldest-first', 'year:asc', 'oldestYear'),
  SortOption('length-longest-first', 'length:desc', 'longest'),
  SortOption('length-shortest-first', 'length:asc', 'shortest'),
  SortOption('price-high-first', 'price:desc', 'pricey'),
  SortOption('price-low-first', 'price:asc', 'cheapest'),
  SortOption('make-firstMake-first', 'make:asc', 'firstMake'),
  SortOption('make-lastMake-first', 'make:desc', 'lastMake'),
  SortOption('distance-nearest-first', 'distance:asc', 'nearest'),
  SortOption('distance-farthest-first', 'distance:desc', 'farthest'),
];

const SortBy = ({ initialState: propsInitialState, match, params, closeOnOuterClick, closeOnInnerClick, getSortByParam, onDataChange, intl: { formatMessage: t } }) => {
  const context = useContext(PortalConfigContext);
  const initialState = propsInitialState || 'open';
  const [selectedValue, setSelectedValue] = useState(getDefaultSort(get(match, 'params.postalCode', null), get(context, 'supports.sortRecommendedOptionEnabled', false)));
  const dispatch = useDispatch();
  const messages = getMessages();

  useEffect(() => {
    calculateSelectedValue();
  }, [match, params]);

  const calculateSelectedValue = () => {
      let urlParams = get(match, 'params', {});
      const localParams = params ? params : {};
      let value;
      if (urlParams.sort) {
      const {parseSortParams} = getCommonBoatParsers(null, context);
        value = parseSortParams(urlParams.sort).sort;
        setSelectedValue(value);
      }
      else if (localParams.sort) {
        value = findKey(sortTypeMap, (key) => key === localParams.sort);
        setSelectedValue(value);
      }
      else {
        const sortRecommendedOptionEnabled = get(context, 'supports.sortRecommendedOptionEnabled', false);
        const postalCode = get(match, 'params.postalCode', null);
        value = getDefaultSort(postalCode, sortRecommendedOptionEnabled);
        setSelectedValue(value);
      }
  };

  const handleDataChange = async (value) => {
    setSelectedValue(value);
    await yieldToMain();
    dispatch({ type: 'GET_DATA_LOADING' });
    if (onDataChange){
      onDataChange(value);
    }
    getSortByParam(value);
  };
  const sortNameMap = getSortNameMap();
  let name = sortNameMap[selectedValue];

    const {
      sortRecommendedOptionEnabled = false
    } = (context?.supports || {});

    // Exclude the recommended sort if the feature flag is not enabled.
    const finalSortOptions = sortRecommendedOptionEnabled ?
      SortOptions : SortOptions.filter((option) => option.id !== 'recommended');

    return (
      <CollapsibleContent
        initialState={initialState}
        closeOnOuterClick={closeOnOuterClick}
        closeOnInnerClick={closeOnInnerClick}
      >
        <CollapsibleHeader type="sort-title" priority={0}>
          {t(messages.searchHeader.sort.sortBy)}<span> :</span>
          <span className="criteria bp3">
            <span className="text">
              <span className="ref-length-longest-first">{t(name)}</span>
            </span>
          </span>
        </CollapsibleHeader>
        <Collapsible>
          <ToolSetOptions>
            {
              finalSortOptions.map(({ id, value, languageKey }) =>
                <ToolSetOptionsItem key={id} id={id} name="sort-by" value={value} onClick={handleDataChange} selected={selectedValue === value}>
                  {t(messages.searchHeader.sort[languageKey])}
                </ToolSetOptionsItem>
              )
            }
          </ToolSetOptions>
        </Collapsible>
      </CollapsibleContent>
    );
};

SortBy.propTypes = {
  closeOnInnerClick: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.any
  ]),
  closeOnOuterClick: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.any
  ]),
  getSortByParam: PropTypes.func.isRequired,
  initialState: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  onDataChange: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.any
  ]),
  dispatch: PropTypes.func,
  match: PropTypes.object,
  params: PropTypes.object
};

export default connect(null, dispatch => bindActionCreators({ getSortByParam }, dispatch))(injectIntl(SortBy));
