import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { bindActionCreators } from 'redux';
import {
  addProLead,
  setGenericEvent,
  setConnectionClick
} from '../../store/actions/dataLayer';
import ContactForm from '../ContactForm';
import AddressModal from '../AddressModal';

import FormButtonArray from '../FormButtonArray';
import './styles.css';
import { getMessages } from '../../tppServices/translations/messages';

const DealerContact = ({
  address,
  addProLead,
  classOverride,
  intl,
  setGenericEvent,
  ownerId,
  setConnectionClick
}) => {
  const [contactOpen, setContactOpen] = useState(false);
  const [locationOpen, setLocationOpen] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const messages = getMessages();
  const getCallNowMessage = () => {
    const { formatMessage: t } = intl;
    return showPhone ? address.phone : t(messages.dealerContact.callNow);
  };

  const onAddressClose = () => {
    setLocationOpen(false);
  };

  const onAddressOpen = () => {
    setLocationOpen(true);
    addProLead({ id: 'pro get directions', partyId: ownerId });
  };

  const onContactDealerClose = () => {
    setContactOpen(false);
  };

  const onContactDealerOpen = () => {
    setContactOpen(true);
    setLocationOpen(false);
    addProLead({ id: 'pro contact dealer', partyId: ownerId });
  };

  const onCallClick = () => {
    setShowPhone(true);
    addProLead({ id: 'view pro phone', partyId: ownerId });
  };

  const onVisitWebsiteClick = () => {
    setConnectionClick(ownerId, 'website referral');
    setGenericEvent('exit links', 'website referral', ownerId);
  };

  const showAddress = (address) => {
    return address.city ||
      address.country ||
      address.countrySubDivisionCode ||
      address.postalCode ||
      address.street
      ? true
      : false;
  };

  const getButtons = () => {
    const { formatMessage: t } = intl;
    const showAddressButton = showAddress(address);
    let buttons = [];
    if (showAddressButton) {
      buttons.push({
        icon: 'place',
        message: t(messages.dealerContact.viewAddress),
        onClick: onAddressOpen
      });
    }
    buttons.push({
      icon: 'email',
      message: t(messages.dealerContact.contactDealer),
      onClick: onContactDealerOpen
    });
    if (address.phone) {
      buttons.push({
        icon: 'call',
        isAnchor: true,
        href: `tel:${address.phone}`,
        message: getCallNowMessage(),
        target: '_self',
        onClick: onCallClick
      });
    }
    return buttons;
  };

  const addressProps = {
    address,
    leadId: ownerId,
    modal: true,
    onClose: onAddressClose,
    onCallClick,
    onContactDealerOpen,
    onVisitWebsiteClick,
    open: true
  };

  const showAdressModal = showAddress(address);

  return (
    <div className={classnames('dealer-contact-area', classOverride)}>
      {locationOpen && showAdressModal && <AddressModal {...addressProps} />}
      {contactOpen && (
        <ContactForm
          open={true}
          itemId={Number(ownerId)}
          leadType={ContactForm.TYPES.party}
          onClose={onContactDealerClose}
          mode={ContactForm.MODES.modal}
          contactSubmitTrack
        />
      )}
      <FormButtonArray buttons={getButtons()} />
    </div>
  );
};

DealerContact.defaultProps = {
  address: {
    city: '',
    coordinates: [0, 0],
    country: '',
    countrySubDivisionCode: '',
    phone: '',
    postalCode: '',
    street: '',
    website: ''
  },
  classOverride: {}
};

DealerContact.propTypes = {
  address: PropTypes.shape({
    city: PropTypes.string,
    coordinates: PropTypes.arrayOf(PropTypes.number),
    country: PropTypes.string,
    countrySubDivisionCode: PropTypes.string,
    phone: PropTypes.string,
    postalCode: PropTypes.string,
    street: PropTypes.string,
    website: PropTypes.string.isRequired
  }).isRequired,
  addProLead: PropTypes.func.isRequired,
  classOverride: PropTypes.object,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  setGenericEvent: PropTypes.func.isRequired,
  ownerId: PropTypes.string.isRequired,
  setConnectionClick: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    address: get(state.app, 'data.partyDetails.address', {
      city: '',
      coordinates: [0, 0],
      country: '',
      countrySubDivisionCode: '',
      phone: '',
      postalCode: '',
      street: '',
      website: ''
    }),
    ownerId: get(state.app, 'params.ownerId', '')
  };
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    { addProLead, setGenericEvent, setConnectionClick },
    dispatch
  )
)(injectIntl(DealerContact));
