import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';
import { RenderHTML } from '@dmm/react-common-components';
import { getDefaultParams, getActiveParams } from '../../../../utils/urlHelpers/boats';
import {
  isSingleTypeOrClassFilter,
  isSingleMakeFilter,
  onlyOneFilterApplied,
  isGeoFilter,
} from '../../../../utils/seoContentHelper';
import './styles.css';

class SeoContent extends PureComponent {

  sanitizeContent(content) {
    // Remove line breaks at the beginning and end of the description
    const sanitizedContent = content.replace(/^(\n)+|(\n)+$/g, '');
    return sanitizedContent.replace(/(\n)+/g, '</br>');
  }

  showSeoContent() {
    const { params } = this.props;
    const defaultParams = getDefaultParams(params);
    const currentParams = getActiveParams(defaultParams);
    return isSingleTypeOrClassFilter(currentParams) ||
      isSingleMakeFilter(currentParams) ||
      isGeoFilter(currentParams) ||
      onlyOneFilterApplied(currentParams, 'hullMaterial') ||
      onlyOneFilterApplied(currentParams, 'fuelType');
  }

  render() {
    const showSeoContent = this.showSeoContent();
    return (
      showSeoContent && this.props.content ?
        <div className="seo-content">
          {this.props.content.imageUrl && (
            <img alt={this.props.content.altName} src={this.props.content.imageUrl} />
          )}
          <RenderHTML html={this.sanitizeContent(this.props.content.description)}/>
        </div> :
        null
    );
  }
}

SeoContent.propTypes = {
  content: PropTypes.shape({
    description: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    altName: PropTypes.string,
  }),
  params: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const searchResults = get(state.app, 'data', {});
  return {
    content: get(searchResults, 'interestingInformation', null)
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(SeoContent));
