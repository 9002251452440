import { formatPriceWithDecimal } from '@dmm/lib-common/lib/formatting';
import { getBoatLoanConstants } from '../../constants/BoatLoans';

const calculateMonthlyPayment = (rate, term, loanAmount) => {
  // https://www.oreilly.com/library/view/javascript-the-definitive/0596000480/ch01s08.html
  const parsedLoanAmount = parseFloat(loanAmount.replace(/[$, ]/g, ''));
  rate = parseFloat(rate.replace(/ /g, ''));
  term = parseInt(term.replace(/ /g, ''));

  const ratePerMonth = rate / 100 / 12;
  const x = Math.pow(1 + ratePerMonth, term);
  const monthlyPayment = ((x - 1) === 0)
    ? (parsedLoanAmount / term).toFixed(2)
    : (
      (parsedLoanAmount * ratePerMonth * x) /
      (x - 1)
    ).toFixed(2);
  const totalLoanAmount = (term * monthlyPayment).toFixed(2);

  return {
    monthlyPayment: formatPriceWithDecimal(monthlyPayment, 'USD', 'en-US', 2),
    totalLoanAmount: formatPriceWithDecimal(
      totalLoanAmount,
      'USD',
      'en-US',
      2
    ),
    totalPrincipal: formatPriceWithDecimal(
      parsedLoanAmount,
      'USD',
      'en-US',
      2
    ),
    totalInterest: formatPriceWithDecimal(
      totalLoanAmount - parsedLoanAmount,
      'USD',
      'en-US',
      2
    ),
  };
};

const calculateTotalLoanAmount = (rate, term, monthlyPayment) => {
  const parsedMonthlyPayment = parseFloat(
    monthlyPayment.replace(/[$, ]/g, '')
  );
  rate = parseFloat(rate.replace(/ /g, ''));
  term = parseInt(term.replace(/ /g, ''));

  const ratePerMonth = rate / 100 / 12;
  const x = Math.pow(1 + ratePerMonth, term);
  const totalPrincipal =  ((x * ratePerMonth) === 0)
    ? (parsedMonthlyPayment * term)
    : (parsedMonthlyPayment * (x - 1)) / (x * ratePerMonth);
  const totalLoanAmount = (term * parsedMonthlyPayment).toFixed(2);
  const totalInterest = (totalLoanAmount - totalPrincipal).toFixed(2);

  return {
    monthlyPayment: formatPriceWithDecimal(
      parsedMonthlyPayment,
      'USD',
      'en-US',
      2
    ),
    totalLoanAmount: formatPriceWithDecimal(
      totalLoanAmount,
      'USD',
      'en-US',
      2
    ),
    totalPrincipal: formatPriceWithDecimal(totalPrincipal, 'USD', 'en-US', 2),
    totalInterest: formatPriceWithDecimal(totalInterest, 'USD', 'en-US', 2),
  };
};

export const calculateLoan = (mode) => {
  const boatLoansConstants = getBoatLoanConstants();
  const { MONTHLY_PAYMENT_CALCULATOR, LOAN_AMOUNT_CALCULATOR } = boatLoansConstants;
  switch (mode) {
  case MONTHLY_PAYMENT_CALCULATOR:
    return calculateMonthlyPayment;
  case LOAN_AMOUNT_CALCULATOR:
    return calculateTotalLoanAmount;
  default:
    return calculateMonthlyPayment;
  }
};
