import React from 'react';
import { useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import { yieldToMain } from '../../../../../../../utils';
import PropTypes from 'prop-types';
import Dropdown from '../../../../../../../components/Dropdown';

import { getRadiusItemAll } from '../../../../../../../utils/uomHelper';
import { Option, OptionSet } from '../../../../../../../components/OptionSet';

import './styles.css';
import { getMessages } from '../../../../../../../tppServices/translations/messages';

const FilterRadius = (props) => {
  const {
    intl: { formatMessage: t },
    device
  } = props;
  const dispatch = useDispatch();
  const messages = getMessages();

  const  handleDataChange = async ({ value }) => {
    if (props.tracking) {
      props.tracking.facetAdded({ radius: value });
    }
    await yieldToMain();
    if (device !== 'mobile') {
      dispatch({ type: 'GET_DATA_LOADING' });
    }
    props.handleDataChange('radius', value);
  };

  return (
    <div className="radiusContainer" data-testid="radiusContainer">
      <div className="radiusContainerItem">
        <Dropdown
          setValue={(v) => handleDataChange(v[0])}
          label={getRadiusItemAll()}
          hideLabel={true}
          value={[props.selectedRadius]}
          options={props.radiuses}
          optionKey="label"
          render={(options, inheritProps) => (
            <OptionSet {...inheritProps}>
              {options.map((option, idx) => (
                <Option
                  key={'radius-' + idx}
                  option={option}
                  {...inheritProps}
                >
                  {option.label}
                </Option>
              ))}
            </OptionSet>
          )}
        />
      </div>
      <div className="radiusContainerItem center">
        <div className="label">
          <span>{t(messages.radiusFacet.label)}</span>
        </div>
      </div>
    </div>
  );
};

FilterRadius.propTypes = {
  selectedRadius: PropTypes.object.isRequired,
  device: PropTypes.string,
  radiuses: PropTypes.array.isRequired,
  handleDataChange: PropTypes.func.isRequired,
  tracking: PropTypes.object,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

export default injectIntl(FilterRadius);
