import React, { useState, useEffect } from 'react';
import Spinner from '../Spinner';
import PropTypes from 'prop-types';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';


import './styles.css';
import { getConfig } from '../../config/portal';
import { getMessages } from '../../tppServices/translations/messages';

const Modal = ({
  handleClose,
  handleInputChange,
  calculateAndDisplayRoute,
  sourceLocation,
  show,
  children,
  formatMessage
}) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';
  const messages = getMessages();
  return (
    <div className={showHideClassName} id="modal">
      <section className="modal-main">
        {children}
        <button className="close-map-modal" onClick={handleClose}>
          <span className="visuallyhidden">
            {formatMessage(messages.contactForm.close)}
          </span>
        </button>
        <div className="calculate-route">
          <input
            aria-label="source place"
            onChange={handleInputChange}
            value={sourceLocation}
          />
          <button
            className="calculate-route-button"
            onClick={calculateAndDisplayRoute}
          >
            {formatMessage(messages.locationMap.calculateRoute)}
          </button>
        </div>
      </section>
    </div>
  );
};

Modal.propTypes = {
  /** The object of the translatable languages */
  formatMessage: PropTypes.func.isRequired,
  children: PropTypes.element,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  handleInputChange: PropTypes.func,
  calculateAndDisplayRoute: PropTypes.func,
  sourceLocation: PropTypes.string
};

export const LocationMapSpinner = () => (
  <div className="location-map" id="spinner-location-map">
    <Spinner />
  </div>
);

export const onMapReady = (directionsRenderer, map, addLead, leadId) => {
  directionsRenderer.setMap(map);
  if (addLead) {
    addLead(leadId, 'map view');
  }
};

export const handleOnClickMarker = (
  marker,
  setShowInfoWindow,
  setActiveMarker
) => {
  setShowInfoWindow(true);
  setActiveMarker(marker);
};

const LocationMap = ({
  intl: { formatMessage },
  google,
  leadId,
  coordinatesBoat,
  addLead,
  address
}) => {
  const [showModalMap, setShowModalMap] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [sourceLocation, setSourceLocation] = useState('');
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [directionsService, setDirectionsService] = useState({});
  const [directionsRenderer, setDirectionsRenderer] = useState({});

  useEffect(() => {
    const newDirectionsService = new google.maps.DirectionsService();
    const newDirectionsRenderer = new google.maps.DirectionsRenderer();
    setDirectionsService(newDirectionsService);
    setDirectionsRenderer(newDirectionsRenderer);
  }, []);

  const coordinatesBoatFormatted = {
    lat: coordinatesBoat[1],
    lng: coordinatesBoat[0]
  };

  const handleInputChange = (event) => {
    setSourceLocation(event.target.value);
  };

  const calculateAndDisplayRoute = () => {
    directionsService.route(
      {
        origin: { query: sourceLocation },
        destination: { query: coordinatesBoat[1] + ',' + coordinatesBoat[0] },
        travelMode: 'DRIVING'
      },
      (response, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(response);
        } else {
          window.alert('Directions request failed due to ' + status);
        }
      }
    );
  };

  const showModal = () => {
    setShowModalMap(true);
    setShowInfoWindow(false);
  };

  const hideModal = () => {
    setShowInfoWindow(false);
    setShowModalMap(false);
  };

  return (
    <div>
      <div id="location-map" className="location-map" onClick={showModal}>
        <Map
          title="map showing the location of the boat"
          google={google}
          disableDefaultUI={true}
          zoom={14}
          initialCenter={coordinatesBoatFormatted}
          onReady={
            /* istanbul ignore next */
            (_, map) => {
              onMapReady(directionsRenderer, map, addLead, leadId);
            }
          }
        >
          <Marker
            name={'Current boat location'}
            onClick={
              /* istanbul ignore next */
              (_, marker) => {
                handleOnClickMarker(marker, setShowInfoWindow, setActiveMarker);
              }
            }
            icon={{
              url: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png',
              alt: 'Current boat location'
            }}
          />
          <InfoWindow
            id="location-map-info-window"
            marker={activeMarker}
            visible={showInfoWindow}
          >
            <div>
              <p>{address}</p>
            </div>
          </InfoWindow>
        </Map>
      </div>
      <div className="address">{address}</div>
      <Modal
        show={showModalMap}
        handleClose={hideModal}
        handleInputChange={handleInputChange}
        sourceLocation={sourceLocation}
        calculateAndDisplayRoute={calculateAndDisplayRoute}
        formatMessage={formatMessage}
      >
        <Map
          id="map"
          title="map showing the location of the boat"
          google={google}
          zoom={14}
          initialCenter={coordinatesBoatFormatted}
          onReady={
            /* istanbul ignore next */
            (_, map) => {
              onMapReady(directionsRenderer, map, addLead, leadId);
            }
          }
        >
          <Marker
            id="modal-marker"
            onClick={
              /* istanbul ignore next */
              (_, marker) => {
                handleOnClickMarker(marker, setShowInfoWindow, setActiveMarker);
              }
            }
            name={'Current boat location'}
            icon={{
              url: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png',
              alt: 'Current boat location'
            }}
          />
          <InfoWindow
            id="location-modal-map-info-window"
            marker={activeMarker}
            visible={showInfoWindow}
          >
            <div>
              <p>{address}</p>
            </div>
          </InfoWindow>
        </Map>
      </Modal>
    </div>
  );
};

LocationMap.MODES = {
  dealer: 'dealer',
  listing: 'listing'
};
LocationMap.defaultProps = {
  mode: LocationMap.MODES.listing
};

LocationMap.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  google: PropTypes.object,
  /** Id of the listing or the dealer */
  leadId: PropTypes.number.isRequired,
  mode: PropTypes.oneOf(Object.keys(LocationMap.MODES)),
  coordinatesBoat: PropTypes.arrayOf(PropTypes.number),
  addLead: PropTypes.func,
  address: PropTypes.string
};

export default injectIntl(
  GoogleApiWrapper({
    apiKey: get(getConfig(), 'keys.googleMaps'),
    LoadingContainer: LocationMapSpinner
  })(LocationMap)
);
