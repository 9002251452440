import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withCookies, Cookies } from 'react-cookie';

import FilterCity from './components/FilterCity';
import FilterRadius from './components/FilterRadius';
import FilterRegion from './components/FilterRegion';
import FilterCountry from './components/FilterCountry';
import FilterSubdivision from './components/FilterSubdivision';

import {
  ToolSetRadioGroup,
  ToolSetRadioItem
} from '../../../../../components/ToolSet';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleHeader
} from '../../../../../components/Collapsible';
import {
  portalHasCountryFilter,
  isRegionFilterEnabled,
  isSubdivisionFilterEnabled,
  isCityFilterEnabled
} from '../../../../../utils/locationHelper';

import './styles.css';
import { getMessages } from '../../../../../tppServices/translations/messages';

const shouldDisplayCountryFilter = (countries = []) =>
  Boolean(countries.length && portalHasCountryFilter());

const shouldDisplayRegionFilter = (regions = [], selectedCountry) => {
  const should = Boolean(
    regions.length && isRegionFilterEnabled(selectedCountry)
  );
  return should;
};

const shouldDisplaySubdivisionFilter = (
  subdivisions = [],
  selectedCountry,
  selectedRegion
) => {
  const should = Boolean(
    subdivisions.length &&
      isSubdivisionFilterEnabled(selectedCountry, selectedRegion)
  );
  return should;
};

const shouldDisplayCityFilter = (
  cities = [],
  selectedCountry,
  selectedSubdivision
) =>
  Boolean(
    cities.length && isCityFilterEnabled(selectedCountry, selectedSubdivision)
  );

export const getSubdivisionFilterHeader = (selectedCountry, t) => {
  const messages = getMessages();
  return t(
    messages.subdivision[selectedCountry]
      ? messages.subdivision[selectedCountry]
      : messages.subdivision
  );
};

const FilterLocation = ({
  handleDataChange,
  intl,
  params = {},
  filtersConfig = {},
  filtersData = {},
  radiusSearch = {},
  position
}) => {
  const t = intl.formatMessage;
  const messages = getMessages();
  const shouldCollapsibleBeOpen = get(
    filtersConfig,
    'shouldCollapsibleBeOpen',
    {}
  );
  const tracking = get(filtersConfig, 'tracking', {});
  const radiusesData = get(filtersData, 'radiusesData', {});
  const countriesData = get(filtersData, 'countriesData', {});
  const regionsData = get(filtersData, 'regionsData', {});
  const subdivisionsData = get(filtersData, 'subdivisionsData', {});
  const citiesData = get(filtersData, 'citiesData', {});
  const selectedRadius = radiusesData.selected;
  const selectedCountry = countriesData.selected;
  const countryName = selectedCountry
    ? t(messages.countries[selectedCountry])
    : '';
  const selectedRegion = regionsData.selected;
  const subdivisions = subdivisionsData.subdivisions;
  const regionDisplayable = shouldDisplayRegionFilter(
    regionsData.regions,
    selectedCountry
  );
  const displayOnRegion = regionDisplayable || selectedRegion;
  const showSubdivisionFilter = shouldDisplaySubdivisionFilter(
    subdivisions,
    selectedCountry,
    displayOnRegion
  );
  const showCityFilter = shouldDisplayCityFilter(
    citiesData.cities,
    selectedCountry,
    subdivisionsData.selected
  );
  const showRegionFilter = regionDisplayable && !subdivisionsData.selected;
  const [radiusTab, setRadiusTab] = useState(
    selectedCountry ? 'country' : 'radius'
  );

  useEffect(() => {
    // Sets the correct Location tab based on the params (reads from URL)
    // when using the Radius dropdown. See TPP-3695.
    if (params.country) {
      setRadiusTab('country');
    } else {
      setRadiusTab('radius');
    }
  }, [params.radius, params.country]);

  const radiusSearchEnabled = radiusSearch?.enabled;
  const brandingV2 = radiusSearchEnabled && radiusSearch?.brandingV2;
  const showLocationFilters =
    !radiusSearchEnabled || (radiusSearchEnabled && radiusTab === 'country');
  const countryFilterClass = showLocationFilters
    ? 'locationComponent-show'
    : 'locationComponent-hide';
  const radiusFilterClass = showLocationFilters
    ? 'locationComponent-hide'
    : 'locationComponent-show';
  return (
    <div className={brandingV2 ? 'brandingV2' : null}>
      {radiusSearchEnabled && (
        <div className="radiusSearch">
          <CollapsibleContent initialState={shouldCollapsibleBeOpen.radius}>
            <CollapsibleHeader priority={0}>
              {t(messages.location)}
            </CollapsibleHeader>
            <Collapsible>
              <div className="search-filter">
                <div className="search-filter-group">
                  <ToolSetRadioGroup>
                    <ToolSetRadioItem
                      id="radiusTabRadius"
                      name="radiusTabRadius"
                      value="radius"
                      checked={radiusTab === 'radius'}
                      onChange={
                        /* istanbul ignore next */
                        () => setRadiusTab('radius')
                      }
                    >
                      <span className="radiusTabLabel">
                        {t(messages.byRadius)}
                      </span>
                    </ToolSetRadioItem>
                    <ToolSetRadioItem
                      id="radiusTabCountry"
                      name="radiusTabCountry"
                      value="country"
                      checked={radiusTab === 'country'}
                      onChange={
                        /* istanbul ignore next */
                        () => setRadiusTab('country')
                      }
                    >
                      <span className="radiusTabLabel">
                        {t(messages.byCountry)}
                      </span>
                    </ToolSetRadioItem>
                  </ToolSetRadioGroup>
                </div>
              </div>
              <div className={countryFilterClass}>
                <FilterCountry
                  device={position}
                  selectedCountry={selectedCountry}
                  countries={countriesData.countries}
                  position={filtersConfig.position}
                  handleDataChange={handleDataChange}
                  params={params}
                  tracking={tracking}
                />
              </div>
              <div className={radiusFilterClass}>
                <FilterRadius
                  device={position}
                  selectedRadius={selectedRadius}
                  radiuses={radiusesData.radiuses}
                  handleDataChange={handleDataChange}
                  tracking={tracking}
                />
              </div>
            </Collapsible>
          </CollapsibleContent>
        </div>
      )}
      {!radiusSearchEnabled &&
        shouldDisplayCountryFilter(countriesData.countries) && (
          <CollapsibleContent initialState={shouldCollapsibleBeOpen.country}>
            <CollapsibleHeader priority={0}>
              {t(messages.country)}
            </CollapsibleHeader>
            <Collapsible>
              <div>
                <FilterCountry
                  device={position}
                  selectedCountry={selectedCountry}
                  countries={countriesData.countries}
                  position={filtersConfig.position}
                  handleDataChange={handleDataChange}
                  params={params}
                  tracking={tracking}
                />
              </div>
            </Collapsible>
          </CollapsibleContent>
        )}
      {showLocationFilters && showRegionFilter && (
        <CollapsibleContent initialState={shouldCollapsibleBeOpen.region}>
          <CollapsibleHeader priority={0}>
            {t(messages.region)}
          </CollapsibleHeader>
          <Collapsible>
            <FilterRegion
              device={position}
              region={selectedRegion}
              regions={regionsData.regions}
              position={filtersConfig.position}
              handleDataChange={handleDataChange}
              params={params}
              tracking={tracking}
            />
          </Collapsible>
        </CollapsibleContent>
      )}
      {showLocationFilters && showSubdivisionFilter && (
        <CollapsibleContent initialState={shouldCollapsibleBeOpen.subdivision}>
          <CollapsibleHeader priority={0}>
            {getSubdivisionFilterHeader(selectedCountry, t)}
          </CollapsibleHeader>
          <Collapsible>
            <FilterSubdivision
              device={position}
              chosenCountry={selectedCountry}
              chosenRegion={selectedRegion}
              subdivision={subdivisionsData.selected}
              subdivisions={subdivisions}
              position={filtersConfig.position}
              handleDataChange={handleDataChange}
              params={params}
              tracking={tracking}
            />
          </Collapsible>
        </CollapsibleContent>
      )}
      {showLocationFilters && showCityFilter && (
        <CollapsibleContent initialState={shouldCollapsibleBeOpen.city}>
          <CollapsibleHeader priority={0}>
            {t(messages.citiesInCountry, { country: countryName })}
          </CollapsibleHeader>
          <Collapsible>
            <div>
              <FilterCity
                device={position}
                selectedCountry={selectedCountry}
                selectedCities={citiesData.selected}
                cities={citiesData.cities}
                position={filtersConfig.position}
                handleDataChange={handleDataChange}
                params={params}
                tracking={tracking}
              />
            </div>
          </Collapsible>
        </CollapsibleContent>
      )}
    </div>
  );
};

FilterLocation.propTypes = {
  params: PropTypes.object,
  position: PropTypes.string,
  radiusSearch: PropTypes.object,
  filtersConfig: PropTypes.object,
  filtersData: PropTypes.object,
  handleDataChange: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  abTestContext: PropTypes.shape({
    featureFlags: PropTypes.array
  }),
  cookies: PropTypes.instanceOf(Cookies).isRequired
};

export default withCookies(injectIntl(FilterLocation));
