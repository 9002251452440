import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import DataTable from '../../../../components/DataTable';
import {
  getPropulsionData,
  getMeasurementsData,
  getDetailsData
} from '../../../../utils/listingHelper';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';
import omit from 'lodash/omit';
import capitalize from 'lodash/capitalize';

import './styles.css';
import { Collapsible, CollapsibleContent, CollapsibleHeader } from '../../../../components/Collapsible';

import { listingPropTypes } from '../../../../utils/commonPropTypes';
import { getDefaultParams, getActiveParams } from '../../../../utils/urlHelpers/boats';
import { unhyphenateUrlComponents } from '../../../../utils/commonHelper';
import { getMessages } from '../../../../tppServices/translations/messages';


const OEMDetails = ({ details, isLoading, facets, params, intl: { formatMessage: t }}) => {
  const messages = getMessages();
  const propulsionData = getPropulsionData(details);
  const measurementsData = getMeasurementsData(details);
  const detailsData = getDetailsData(details);
  let makeModel = get(facets, 'makeModel', []);
  const allModels = head(makeModel);
  const title = omit(allModels, ['count', 'model']);
  const make =  get(title, 'value', '');
  const defaultParams = getDefaultParams(params);
  const currentParams = getActiveParams(defaultParams);
  makeModel = get(currentParams, 'makeModel', {});
  const makeModelKeys = Object.keys(makeModel);
  const models = makeModelKeys.length === 1 &&  makeModel[makeModelKeys[0]];
  const normalizedModel = models[0];
  const model = normalizedModel && capitalize(unhyphenateUrlComponents(normalizedModel));

  return (
    <div className="oem-technical-specifications">
      <h2>{t(messages.technicalSpecificationsOEMs.title, {make, model})}</h2>
      { !isEmpty(detailsData) &&
        <CollapsibleContent initialState="open" classOverride="details">
          <CollapsibleHeader id="boat-details">
            {make} {model}: {t(messages.details)}
          </CollapsibleHeader>
          <Collapsible type="no-pad">
            <DataTable dataName="details" data={getDetailsData(details)} isLoading={isLoading} />
          </Collapsible>
        </CollapsibleContent> }
      { !isEmpty(measurementsData) &&
        <CollapsibleContent initialState="open" classOverride="details">
          <CollapsibleHeader id="boat-details">
            {make} {model}: {t(messages.measurements)}
          </CollapsibleHeader>
          <Collapsible type="no-pad">
            <DataTable dataName="measurements" data={measurementsData} isLoading={isLoading} />
          </Collapsible>
        </CollapsibleContent>
      }
      { !isEmpty(propulsionData) &&
        <CollapsibleContent initialState="open" classOverride="details">
          <CollapsibleHeader id="boat-details">
            {make} {model}: {t(messages.propulsion)}
          </CollapsibleHeader>
          <Collapsible type="no-pad">
            <DataTable dataName="propulsion" data={propulsionData} isLoading={isLoading} />
          </Collapsible>
        </CollapsibleContent>
      }
    </div>
  );
};

OEMDetails.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
  details: listingPropTypes.isRequired,
  params: PropTypes.object,
  facets: PropTypes.object,
};

export default injectIntl(OEMDetails);
