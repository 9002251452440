import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {injectIntl} from 'react-intl';
import {ToolSetOptions} from '../../../../../../../components/ToolSet';
import {CollapsibleContent} from '../../../../../../../components/Collapsible';
import {FilterTypeAhead} from '../../..';
import {generateSearchPath} from '../../../../../../../utils/urlHelpers/boats';

import {regionsWithName} from '../../../../../utils/locations';
import { yieldToMain } from '../../../../../../../utils';

import Region from './Region';

import {useTPPServices} from '../../../../../../../tppServices/tppDIHooks';
import { getMessages } from '../../../../../../../tppServices/translations/messages';

const optionsRenderer = (intl, params, onItemClick, selectedRegion, translations) => {
  const renderer = (items) => {
    const regionItems = items.map((region, i) => {
      return (
        <Region region={region}
          selectedRegion={selectedRegion}
          translations={translations}
          params={params}
          intl={intl}
          onItemClick={onItemClick}
          key={i}
        />
      );}
    );
    return (
      <div className="search-filter">
        <div className="regions">
          <ToolSetOptions>
            {regionItems}
          </ToolSetOptions>
        </div>
      </div>
    );
  };
  return renderer;
};

const FilterRegion = ({
  region, regions, intl, position, tracking, handleDataChange, device,
  translations = getMessages(), params = {},
  maxRegionsCount/* = bts().MAX_SREGIONS_COUNT*/,
  urlGenerator = generateSearchPath,
  sortRegions = regionsWithName
}) => {
  const [selectedRegion, setSelectedRegion] = useState(region);
  const {boatsConstants} = useTPPServices();
  maxRegionsCount = maxRegionsCount ?? boatsConstants.MAX_SREGIONS_COUNT;
  const dispatch = useDispatch();
  useEffect(() => {
    setSelectedRegion(region || 'all');
  }, [region]);

  const onItemClick = async (value) => {
    if (tracking) {
      tracking.facetAdded({'region': value});
    }
    await yieldToMain();
    if (device !== 'mobile') {
      dispatch({type: 'GET_DATA_LOADING'});
    }
    handleDataChange('region', value);
  };

  const t = intl.formatMessage;
  const sortedRegions = sortRegions(regions, translations, intl);
  const showAll = get(params, 'modal', []).includes('region');
  const rendererFunction =
    optionsRenderer(intl, params, onItemClick, selectedRegion, translations);
  return (
    <div className={'search-filter regions'} data-testid={'search-filter-regions'}>
      <CollapsibleContent initialState="open">
        <FilterTypeAhead
          items={sortedRegions}
          selectedItems={[selectedRegion.value]}
          id={`Regions-${position}`}
          moreMessage="moreRegions"
          placeholder={`${t(translations.region)}`}
          max={maxRegionsCount}
          render={rendererFunction}
          showAll={showAll}
          url={urlGenerator(params) + 'modal-region/'}/>
      </CollapsibleContent>
    </div>
  );
};

FilterRegion.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  region: PropTypes.string,
  device: PropTypes.string,
  regions: PropTypes.array,
  maxRegionsCount: PropTypes.number,
  params: PropTypes.object,
  position: PropTypes.string,
  handleDataChange: PropTypes.func,
  urlGenerator: PropTypes.func,
  tracking: PropTypes.shape({
    facetAdded: PropTypes.func
  }),
  translations: PropTypes.object,
  sortRegions: PropTypes.func
};

export default injectIntl(FilterRegion);

