import React from 'react';
import {ToolSetOptionsItem} from '../../../../../../../components/ToolSet';
import Link from '../../../../../../../components/SEO/Link';
import PropTypes from 'prop-types';

const SubdivisionToolSetItem = ({subdivision, onItemClick, linkHref, linkName, isSelected}) => {
  return (
    <ToolSetOptionsItem
      key={`Subdivision-${subdivision.value}`}
      id={`Subdivision-${subdivision.value}`}
      name={`Subdivision-${subdivision.value}`}
      value={subdivision.value}
      selected={isSelected}
      onClick={onItemClick}
    >
      <Link
        className="subdivision-type-link"
        href={linkHref}
        onClick={(e) => e.preventDefault()}
      >
        {linkName}
      </Link>
    </ToolSetOptionsItem>
  );
};

SubdivisionToolSetItem.propTypes = {
  subdivision: PropTypes.object,
  onItemClick: PropTypes.func,
  linkHref: PropTypes.string,
  linkName: PropTypes.string,
  isSelected: PropTypes.bool
};

export default SubdivisionToolSetItem;
